<template>
  <div class="ShowOff-page">
    <div class="video-warp">
      <img src="/static/images/showoff/campus-voice/1.png" alt="" />
      <div class="video-box">
        <!-- <video class="video" src="/static/images/showoff/campus-voice/video.mp4" controls="controls">
            您的浏览器不支持 video 标签。
        </video> -->
      <div class="video-player-warp">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player>
      </div>
      </div>
    </div>
    <img src="/static/images/showoff/campus-voice/2.png" alt="" />
    <img src="/static/images/showoff/campus-voice/3.png" alt="" />
  </div>
</template>

<script>
import "video.js/dist/video-js.css"
import { videoPlayer } from "vue-video-player"
export default {
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "https://jap-online.oss-cn-shenzhen.aliyuncs.com/70fbd91-1760291549c-0006-1b7c-38f-3e14e.mp4", //url地址
          },
        ],
        poster: "https://jap-online.oss-cn-shenzhen.aliyuncs.com/70fbd91-1760291549c-0006-1b7c-38f-3e14e.mp4?x-oss-process=video/snapshot,t_05000,m_fast", //你的封面地址
        width: 930, //document.documentElement.clientWidth, //播放器宽度
        height:520,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
          // width: "930px",
          // height: "520px",
        },
      },
    };
  },
  components: {
    videoPlayer,
  },
};
</script>

<style lang="less" scoped>
.ShowOff-page {
  img {
    width: 100%;
  }

  .video-warp {
    position: relative;
    .video-box {
      z-index: 9;
      position: absolute;
      bottom: 6.7%;
      left: 0;
      width: 100%;

      .video-player-warp {
        margin: 0 auto;
        width: 48.43%;
        height: 100%;
         /deep/ .video-js video {
          background: #ffffff;
      }
      
      /deep/ .video-js .vjs-big-play-button {
        background: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
        width: 60px;
        height: 60px;
        line-height: 55px;
        border-radius: 30px;
        border: 2px solid #ffffff;
      }
      }

     
    }
  }
}
</style>

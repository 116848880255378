<template>
  <div class="page">
    <div class="detail-head">
      <img :src="info.cover_url" alt="">
      <div class="detail-head-main">
        <div class="flex-c">
          <div class="detail-head-title">{{info.goods_name}}</div>
          <div class="detail-head-btn" @click="handleApply">立即报名</div>
        </div>
        <div class="detail-head-phone">
          <img :src="$img('IconPhoneTwo')" alt="">
          <span>{{school_info.phone}}</span>
        </div>
        <div class="detail-head-address">
          <img :src="$img('IconAddressTwo')" alt="">
          <span>{{school_info.address}}</span>
        </div>
        <div class="detail-map" id="container"></div>
      </div>
    </div>
    <div class="detail-desc" v-html="info.content"></div>
  </div>
</template>

<script>
  import maps from 'qqmap'
  export default {
    data () {
      return {
        info: {},
        school_info: {},
        map: null
      }
    },
    activated () {
      this.offlineGoodsDetails()
    },
    methods: {
      async offlineGoodsDetails () {
        let { status, data } = await this.$Get('Common.offlineGoodsDetails', {
          id: this.$route.query.id
        })
        if (status) {
          this.info = data
          this.school_info = data.school_info
          maps.init(this.$Config.mapkey, () => {
            console.log(maps)
            this.map = new maps.Map(document.getElementById('container'), {
              center: new maps.LatLng(this.school_info.longitude, this.school_info.latitude),
              zoom: 13,
              draggable: false,
              scrollwheel: false,
              disableDoubleClickZoom: false,
              disableDefaultUI: true
            })
          })
        }
      },
      handleApply () {
        this.$Bus.$emit('OfflinePay', { id: this.$route.query.id, school_info: this.school_info })
      }
    },
    watch: {
      applyshow (to) {
        if (!to) {
          clearInterval(this.payTime)
        }
      },
      value () {
        this.confirmOrder()
      }
    }
  }
</script>

<style lang="less" scoped>
  .page {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    margin: 42px auto 90px;
    .detail-head {
      margin-bottom: 42px;
      .flex-c();
      img {
        width: 400px;
        height: 300px;
        border-radius: 10px;
        margin-right: 40px;
      }
      .detail-head-main {
        .flex-1();
        height: 100%;
        .detail-head-title {
          .flex-1();
          font-size: 28px;
          font-weight: bold;
          line-height: 50px;
          color: #333333;
          .line-clamp();
        }
        .detail-head-phone {
          font-size: 22px;
          font-family: WeChatNumber-151125;
          font-weight: 500;
          line-height: 32px;
          color: #236FFB;
          margin: 28px 0 20px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
        .detail-head-address {
          font-size: 18px;
          line-height: 32px;
          color: #B2B2B2;
          margin-bottom: 20px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
        .detail-head-btn {
          background: #CA0000;
          color: #fff;
          width: 180px;
          height: 50px;
          font-size: 18px;
          border-radius: 25px;
          cursor: pointer;
          margin-left: 10px;
          .flex-cc();
        }
        .detail-map {
          width: 460px;
          height: 120px;
          border-radius: 10px;
        }
      }
    }
    .detail-desc {
      font-size: 16px;
      line-height: 32px;
      color: #888888;
      margin-bottom: 24px;
    }
    .bigimg {
      width: 100%;
      height: auto;
    }
  }
</style>

<template>
  <div class="page">
    <TiliteNav />
    <CoureList :showIsLastBtn="true" :list="list" :myPurchase="goods.my_purchase * 1" />
    <div class="flex-cc block">
      <Paging v-model="page" :total="total_pages" :items="total_items" :limit="8" />
    </div>
    <template v-if="check.length">
      <TiliteNav>习题</TiliteNav>
      <div class="mb40">
        <CoureList :list="check" :myPurchase="goods.my_purchase * 1" />
      </div>
    </template>
    <div class="recommend" v-if="goods.generalize_id * 1 !== 0" @click="$nav('Campus.SeriesCourses', {id: goods.generalize_id})">
      <span>{{goods.generalize}}</span>
      <img :src="$img('IconRightThree')" alt="">
    </div>
    <div class="detail">
      <TiliteNav>
        <div class="title">课程详情</div>
      </TiliteNav>
      <div class="look-detail-content" v-html="goods.content"></div>
    </div>
    <TiliteNav v-if="recommend.length">
      <div class="title">推荐搭配</div>
    </TiliteNav>
    <CoureListTwo :list="recommend" :myPurchase="1" />
    <div class="pay-btn" v-if="goods.my_purchase * 1 === 0" @click="handlePay">购买本系列课程</div>



    <van-popup v-model="payshow" position="left" overlay-class="black-bg">
      <div class="popup-box">
          <div class="list">
              <div class="item" v-if="goods_series.length">
                  <div class="item-title">购买本系列</div>
                  <div class="flex-c mb30" v-for="(item, index) in goods_series" :key="index" @click="Gobuy(item)">
                      <div class="item-img">
                          <img :src="item.thumb" />
                      </div>
                      <div class="item-detail flex-cb">
                          <div class="detail-title">
                              <p class="line-clamp">{{item.title}}</p>
                              <span>包含本课共{{item.num}}课</span>
                          </div>
                          <div class="detail-price">
                              <span>￥</span>
                              {{item.original_price}}
                          </div>
                      </div>
                  </div>
              </div>
              <div class="item" v-if="goods_all.id">
                  <div class="item-title">购买全套课程</div>
                  <div class="flex-c" @click="Gobuy(goods_all)">
                      <div class="item-img">
                          <img :src="goods_all.thumb" />
                      </div>
                      <div class="item-detail flex-cb">
                          <div class="detail-title">
                              <p class="line-clamp">{{goods_all.title}}</p>
                              <span>包含本课共{{goods_all.num}}课</span>
                          </div>
                          <div class="detail-price">
                              <span>￥</span>
                              {{goods_all.original_price}}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </van-popup>



  </div>
</template>

<script>
  import CoureList from '../../components/CourseList/series-item'
  import CoureListTwo from '../../components/CourseList'
  export default {
    data () {
      return {
        page: 1,
        total_pages: 0,
        total_items: 0,
        list: [],
        goods: {},
        isNeedShipping: null,
        recommend: [],
        package: [],
        check: [],
        payshow:false,
        goods_series:[],
        goods_all:{}
      }
    },
    mounted () {
      this.goodsDetails()
    },
    watch: {
      page (to) {
        console.log('tag', to)
        this.list = this.package.slice((to - 1) * 8, (to - 1) * 8 + 8)
      },
      '$route.query.id' (to) {
        if (to) {
          this.goodsDetails()
        }
      }
    },
    components: {
      CoureList,
      CoureListTwo
    },
    methods: {
      async goodsDetails () {
        let { status, data } = await this.$Get('Common.goodsDetails', {
          id: this.$route.query.id
        })
        if (status) {
          let packagelist = []
          let check = []
          data.goods.package_list.forEach(item => {
            if (item.goods_type === 'alone_paper_type') {
              check.push(item)
            } else {
              packagelist.push(item)
            }
          })
          this.page = 1
          this.goods = data.goods
          this.isNeedShipping = data.isNeedShipping
          this.recommend = data.recommend
          this.package = packagelist
          this.check = check
          this.total_pages = Math.ceil(packagelist.length / 8)
          this.total_items = packagelist.length
          this.list = this.package.slice((this.page - 1) * 8, 8)
          this.goods_series = data.goods.goods_series || []
          this.goods_all = data.goods.goods_all || {}
        }
      },
      handlePay () {
        this.payshow = true
        // this.$Bus.$emit('Pay', { id: this.$route.query.id })
      },
        Gobuy(item) {
                if (item.goods_type === 'video_type') {
                    this.$Bus.$emit('Pay', {
                        id: item.id
                    })
                }
                if (item.goods_type === 'book_type') {
                    this.$Bus.$emit('showBookPay', {
                        id: item.id,
                        amount: item.original_price
                    })
                }
                if (item.goods_type === 'package_type') {
                    this.$Bus.$emit('Pay', {
                        id: item.id
                    })
                }
                this.payshow = false
            }
    }
  }
</script>

<style lang="less" scoped>
  .page {
    margin: auto;
    margin-bottom: 90px;
    position: relative;
    .mb40 {
      margin-bottom: 40px;
    }
    .recommend {
      width: 1200px;
      height: 84px;
      background: #FFF3F3;
      border-radius: 10px;
      margin-bottom: 40px;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 32px;
      color: #CA0000;
      padding: 0 40px;
      .flex-bc();
    }
    .pay-btn {
      position: absolute;
      top: 23px;
      right: 0;
      background: #CA0000;
      color: #fff;
      box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
      width: 180px;
      height: 50px;
      font-size: 18px;
      border-radius: 25px;
      position: absolute;
      cursor: pointer;
      .flex-cc();
    }
    .block {
      margin: 60px auto;
    }
    .detail {
      width: 1200px;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      padding: 0 40px 40px;
      /deep/ .title-nav {
        &::before {
          height: 28px;
        }
      }
      .title {
        height: 32px;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: #333333;
      }
      .look-detail-content {
        font-size: 16px;
        line-height: 32px;
        color: #888888;
        margin-bottom: 20px;
        margin-top: -20px;
        img {
          width: 100%;
        }
      }
    }

    .popup-box {
            width: 550px;
            padding: 30px;
            height: 100vh;
            overflow: hidden;
            border-radius: 0;
            
        .list {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            overflow: auto;
            padding-bottom: 20px;
            background-color: #fff;

            &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 1px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 10px;
            }

            &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                background: transparent;
            }

            &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                width: 12px;
                height: 216px;
                background-color: transparent;
            }
        }

        .item {
            .pb(20px);
            .mb(20px);
            border-bottom: 1px solid #e5e5e5;
            cursor: pointer;

            .item-title {
                .c(#666);
                .fz(18px);
                .mb(20px);
            }

            .mb30 {
                .mb(20px);

                &:last-child {
                    .mb(0);
                }
            }

            .item-img {
                .w(221px);
                .h(166px);
                .br(10px);
                .mr(18px);
                text-align: center;

                img {
                    .h();
                }
            }

            .item-detail {
                .h(160px);

                .detail-title {
                    p {
                        .fz(18px);
                        .c(#333);
                        .mb(0.2rem);
                    }

                    span {
                        .fz(16px);
                        .c(#999);
                    }
                }

                .detail-price {
                    .c(#CA0000);
                    .fz(24px);
                    // font-weight: 600;

                    span {
                        .fz(18px);
                    }
                }
            }

            &:last-child {
                .pb(0);
                .mb(0);
                border-bottom: 1px solid transparent;
            }
        }
      }

  }
</style>

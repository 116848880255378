<template>
  <div class="ShowOff-page">
    <img src="/static/images/showoff/campus-style/1.png" alt="">
    <img src="/static/images/showoff/campus-style/2.png" alt="">
    <img src="/static/images/showoff/campus-style/3.png" alt="">
    <img src="/static/images/showoff/campus-style/4.png" alt="">
    <img src="/static/images/showoff/campus-style/5.png" alt="">
  </div>
</template>

<script>
  export default {
  }
</script>

<style lang="less" scoped>
.ShowOff-page {
  img {
    width: 100%;
  }
}
</style>

<template>
  <div class="academy-page">
    <div class="banner">
      <!-- <img src="/static/images/entrepot/Information.png" /> -->
      <div class="banner-box flex-ccc">
        <div class="banner-title">学院<span>介绍</span></div>
        <div class="w100">
          <div class="banner-note">{{about}}</div>
        </div>
      </div>
    </div>
    <div class="academy-block flex-ccc">
      <div class="home-list type2">
        <AcademyList :cutomStyle="cutomStyle" :list="list" />
      </div>
    </div>
  </div>
</template>

<script>
  import AcademyList from '../../components/AcademyList'
  export default {
    data () {
      return {
        cutomStyle: {
          background: '#fff'
        },
        about: '',
        list: []
      }
    },
    created () {
      this.getArticleList()
    },
    methods: {
      async getArticleList () {
        let { data, status } = await this.$Get('Common.getArticleListV2', {
          page: 1,
          limit: 200,
          type: 'school_campus'
          // is_hot: 1
        })
        if (status) {
          this.list = data.items
          this.about = data.about
        }
      }
    },
    mounted () {},
    components: {
      AcademyList
    }
  }
</script>

<style lang="less" scoped>
.academy-page {
  /deep/ .academy-item {
    background-color: #fff;
  }
  background-color: #f9f9f9;
  .banner {
    position: relative;
    min-width: 1200px;
    width: 100%;
    height: 380px;
    background-size: cover;
    background-image: url(/static/images/entrepot/Information.png);
    .banner-box {
      width: 1200px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      .banner-title {
        width: 100%;
        font-size: 56px;
        font-weight: 800;
        line-height: 95px;
        color: #fff;
        span {
          color: #CA0000;
        }
      }
      .w100 {
        width: 100%;
      }
      .banner-note {
        width: 480px;
        font-size: 24px;
        line-height: 36px;
        color: #E5E5E570;
      }
    }
    img {
      width: 100vw;
      min-width: 1200px;
      height: auto;
    }
  }
  .academy-block {
    padding: 90px 0 110px;
    min-width: 1200px;
    .block {
      padding: 60px 0 0;
    }
  }
}
</style>

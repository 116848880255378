<template>
  <div class="flex-c teachers-items">
    <div class="teachers-item" v-for="(item, index) in list" :key="index" @click="goDetail(item)">      
      <!-- <img style="width: 270px; height: 350px;" :src="item.cover_url+'?x-oss-process=image/resize,w_270,h_350,m_lfit'"> -->
      <el-image style="width: 270px; height: 350px" :lazy="false" :src="item.cover_url+'?x-oss-process=image/resize,h_350,m_lfit'" fit="cover"></el-image>
      <div class="teachers-main flex-ccc">
        <div class="teachers-box">
          <div class="teachers-title">{{ item.title }}</div>
          <!-- <div class="teachers-content">{{ item.note }}</div> -->
          <div class="teachers-text">{{ item.about }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'teachers',
    props: {
      list: [Array]
    },
    methods: {
      goDetail (item) {
        this.$nav('Common.IntroduceDetail', { id: item.id })
      }
    }
  }
</script>

<style lang="less" scoped>
  .teachers-items {
    flex-wrap: wrap;
    width: 1200px;
    .teachers-item {
      width: 270px;
      height: 464px;
      background: #fff;
      border-radius: 10px;
      margin-right: 40px;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 48px;
      }
      .teachers-main {
        .teachers-box {
          width: 100%;
          padding: 24px 22px 27px 24px;
          border-radius: 10px 10px 0 0;
          background-color: #fff;
          transition: all 0.3s;
          box-sizing: border-box;
        }
        .teachers-title {
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          margin-bottom: 12px;
          text-align: center;
          .line-clamp-multi(1);
        }
        .teachers-content {
          font-size: 14px;
          line-height: 24px;
          color: #B2B2B2;
          .line-clamp-multi(1);
          text-align: center;
          margin-bottom: 42px;
          span {
            color: #888;
          }
        }
        .teachers-text {
          font-size: 14px;
          line-height: 24px;
          color: #888888;
          height: 500px;
          .line-clamp-multi(2);
          white-space: pre-wrap;
        }
      }
      img {
        width: 270px;
        height: 350px;
      }
      &:hover {
        background: #fff;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
        .teachers-box {
          transform: translateY(-286px);
        }
        .teachers-text {
          .line-clamp-multi(13);
          padding: 0 4px;
        }
      }
    }
  }
</style>

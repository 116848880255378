<template>
  <div class="contact-page">
    <div class="banner">
      <img src="/static/images/entrepot/ContactUs.png" />
      <!-- <img src="/static/images/entrepot/training.png" /> -->
      <!-- <div class="banner-box flex-ccc">
        <div class="banner-title"><span>联系</span>我们</div>
        <div class="w100">
          <div class="banner-note">坚持实施“一位学员、一种计划、一种方法”的针对性教学模式</div>
        </div>
      </div> -->
    </div>
    <div class="contact-block">
      <div class="flex mb40">
        <div class="contact-label"><span>*</span>留言内容</div>
        <textarea v-model="content" class="flex-1" placeholder="请输入留言内容"></textarea>
      </div>
      <div class="flex mb100">
        <div class="contact-label">相关图片</div>
        <div class="flex-1">
          <el-upload
            :action="$Config.hostUrl + '/Upload/UploadPublicApi/uploadFile'"
            list-type="picture-card"
            :on-change="handleChange"
            :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="contact-toast">*只支持.JPG、JPEG、.PNG格式</div>
        </div>
      </div>
      <div class="contact-btn" @click="submit">提交</div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        content: '',
        dialogImageUrl: '',
        dialogVisible: false,
        uploadImgs: []
      }
    },
    computed: {},
    methods: {
      handleChange (file, fileList) {
        console.log(file, fileList)
        let arr = []
        fileList.forEach(item => {
          if (item.response) {
            arr.push(item.response.data.url)
          }
        })
        this.uploadImgs = arr
      },
      handlePictureCardPreview (file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      async submit () {
        if (!this.content) {
          this.$ShowToast('反馈内容不能为空')
          return
        }
        let postData = {
          content: this.content,
          thumbs: JSON.stringify(this.uploadImgs)
        }
        let { status } = await this.$Post('Common.releaseOpinion', postData)
        if (status) {
          this.$ShowToast('提交成功', { icon: 'success' })
          this.content = ''
          this.fileList = []
          this.uploadImgs = []
        } else {
          this.$SetStorage('process', {
            key: 'releaseOpinion',
            data: postData
          })
        }
      }
    },
    mounted () {},
    components: {
    }
  }
</script>

<style lang="less" scoped>
.contact-page {
  .banner {
    position: relative;
    .banner-box {
      width: 1200px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      .banner-title {
        width: 100%;
        font-size: 56px;
        font-weight: 800;
        line-height: 95px;
        color: #fff;
        span {
          color: #CA0000;
        }
      }
      .w100 {
        width: 100%;
      }
      .banner-note {
        width: 480px;
        font-size: 24px;
        line-height: 36px;
        color: #E5E5E570;
      }
    }
    img {
      width: 100vw;
      min-width: 1200px;
      height: auto;
    }
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .mb100 {
    margin-bottom: 100px;
  }
  .contact-block {
    width: 1200px;
    margin: 60px auto 90px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 60px 125px 60px 100px;
    .contact-label {
      width: 135px;
      font-size: 16px;
      margin-top: 10px;
      span {
        color: red;
      }
    }
    textarea {
      height: 170px;
      background: #F9F9F9;
      border: 1px solid #E5E5E5;
      opacity: 1;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 14px 12px;
    }
    .contact-toast {
      font-size: 12px;
      line-height: 17px;
      color: #C4C4C4;
      margin-top: 8px;
    }
    /deep/ .el-upload--picture-card {
      width: 110px;
      height: 110px;
      position: relative;
    }
    /deep/ .el-icon-plus:before {
      content: '';
      width: 28px;
      height: 28px;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('/static/images/icon/upload.png');
    }
    /deep/ .el-icon-plus:after {
      content: '上传图片';
      position: absolute;
      width: 100px;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      line-height: 20px;
      color: #C4C4C4;
    }
    /deep/ .el-upload-list li {
      width: 110px;
      height: 110px;
      position: relative;
    }
    .contact-btn {
      width: 230px;
      height: 50px;
      background: #CA0000;
      box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
      margin: auto;
      border-radius: 25px;
      font-size: 18px;
      color: #FFFFFF;
      cursor: pointer;
      .flex-cc();
    }
  }
}
</style>

export default {
  data () {
    return {
      // 是否可以开始倒计时
      CountDownStatus: true,
      // 倒计时默认标题
      CountDownDefaultTitle: '',
      // 倒计时标题
      CountDownTitle: '',
      // 定时器id
      CountDownTime: null,
      // 倒计时
      CountDown: 0,
      // 区间（秒）
      Section: [0, 60],
      // 格式
      formatString: 'DD天HH小时mm分ss秒',
      // 模式
      mode: '',
      // 格式对象
      formatTime: {}
    }
  },
  methods: {
    // 倒计时循环
    CountDownCycle () {
      // 状态修改
      this.CountDownStatus = false
      this.CountDownTime = setInterval(() => {
        // 剩余秒数递减
        this.CountDown--
        // 分流
        this.Shunt()
        // 剩余秒数小等于区间时清除循环
        if (this.CountDown <= this.Section[0]) {
          this.FinishCountDown()
        }
      }, 1000)
    },
    // 开始
    StartCountDown () {
      if (this.CountDownStatus) {
        // 清除CountDownTime
        clearInterval(this.CountDownTime)
        // 默认值存储
        this.CountDownDefaultTitle = this.CountDownDefaultTitle || this.CountDownTitle
        // 剩余秒数赋值
        this.CountDown = this.Section[1]
        // 倒计时循环
        this.CountDownCycle()
        // 附加方法
        this.addition && this.addition()
      }
    },
    // 结束
    FinishCountDown () {
      clearInterval(this.CountDownTime)
      // 剩余秒数赋值
      this.CountDown = this.Section[1]
      this.CountDownTitle = this.CountDownDefaultTitle
      this.CountDownStatus = true
    },
    // 暂停/继续
    PauseCountDown () {
      if (this.CountDownStatus) {
        // 倒计时循环
        this.CountDownCycle()
      } else {
        clearInterval(this.CountDownTime)
        this.CountDownStatus = true
      }
    },
    // 分流
    Shunt () {
      switch (this.mode) {
        case 'date':
          this.MatchTo()
          break
        default:
          this.CountDownTitle = `${this.CountDown}s后重新获取`
          break
      }
    },
    // 格式写入
    MatchTo () {
      let formatTime = this.formatTimeText(this.CountDown)
      this.formatTime = formatTime
      let formatString = this.formatString
      for (let key in formatTime) {
        var subStr = new RegExp(key, 'ig')
        formatString = formatString.replace(subStr, formatTime[key])
      }
      this.CountDownTitle = formatString
    },
    // 获取剩下时间
    formatTimeText (leftTime) {
      if (leftTime > 0) {
        let dd = Math.floor(leftTime / 60 / 60 / 24)
        dd = dd > 9 ? dd : '0' + dd
        let hh = Math.floor((leftTime / 60 / 60) % 24)
        hh = hh > 9 ? hh : '0' + hh
        let mm = Math.floor((leftTime / 60) % 60)
        mm = mm > 9 ? mm : '0' + mm
        let ss = Math.floor(leftTime % 60)
        ss = ss > 9 ? ss : '0' + ss
        return {
          dd,
          hh,
          mm,
          ss
        }
      }
      return {
        dd: 0,
        hh: 0,
        mm: 0,
        ss: 0
      }
    }
  }
}

<template>
  <div class="read-item">
    <div class="content" v-html="question_content"></div>
  </div>
</template>

<script>
  export default {
    props: {
      question_content: null
    }
  }
</script>

<style lang="less" scoped>
.read-item {
  .fz(0.28rem);
  .c(#333);
  .lh(1.2);
  .mb(0.5rem);
  /deep/ .content {
    line-height: 1.6;
    strong {
      font-weight: bold;
    }
  }
}
</style>

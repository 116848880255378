<template>
  <div class="flex-c course" ref="course">
    <div class="course-item" @click="goNav(item)" v-for="(item, index) in list" :key="index" :class="{animation: animation && isanimation}">
      <img :src="item.thumb" />
      <div class="course-main">
        <div class="course-title">{{item.title}}</div>
        <div class="course-content" v-if="item&&item.about">
          <span>课程介绍：</span>{{item.about}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'course',
    props: {
      animation: [Boolean],
      scrollToTop: {
        type: Function,
        default: () => {}
      },
      list: [Array]
    },
    watch: {
      animation (to) {
        if (to) {
          setTimeout(() => {
            this.isanimation = false
          }, 2100)
        }
      }
    },
    methods: {
      goNav  (item) {
        let URL = ''
        let query = { id: item.id }
        switch (item.goods_type) {
        case 'video_type':
          URL = 'Campus.CheckCourseDetails'
          break
        case 'package_type':
          if (this.type === 'my') {
            URL = 'Course.SeriesCourses'
          } else {
            URL = 'Campus.SeriesCourses'
          }
          break
        case 'alone_paper_type':
          if (this.type === 'my') {
            URL = 'Course.OnlineProblemSets'
          } else {
            URL = 'Campus.OnlineProblemSets'
          }
          break
        default:
          query = { id: item.goods_id || item.id }
          URL = 'Campus.SeriesCoursesDetails'
          break
        }
        this.$nav(URL, query)
      }
    },
    data () {
      return {
        isanimation: true
      }
    },
    mounted () {
      window.addEventListener('scroll', () => { this.scrollToTop(this, 'course', 'courseAnimation') }, true)
      if (this.animation) {
        setTimeout(() => {
          this.isanimation = false
        }, 2100)
      }
    }
  }
</script>

<style lang="less" scoped>
  .course {
    flex-wrap: wrap;
    .course-item {
      width: 270px;
      // height: 302px;
      background: #F9F9F9;
      border-radius: 10px;
      margin-right: 40px;
      overflow: hidden;
      transition: all .2s;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 30px;
      }
      .course-main {
        padding: 16px 16px 15px;
        .course-title {
          height: 26px;
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          .line-clamp-multi(1);
        }
        .course-content {
          margin-top: 16px;
          font-size: 14px;
          line-height: 24px;
          color: #B2B2B2;
          .line-clamp-multi(3);
          span {
            color: #888;
          }
        }
      }
      img {
        width: 270px;
        height: 152px;
      }
      &:hover {
        transform: translateY(-20px);
        background: #fff;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      }
    }
  }
</style>

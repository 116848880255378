<template>
<div class="HomeCenterBg-container">
 <div class="item">
   <img class="img" src="/static/images/home/HomeCenterBg/1.png" alt="">
 </div>
  <div class="item">
   <img class="img" src="/static/images/home/HomeCenterBg/2.png" alt="">
 </div>
  <div class="item">
   <img class="img" src="/static/images/home/HomeCenterBg/3.png" alt="">
 </div>
  <div class="item">
   <img class="img" src="/static/images/home/HomeCenterBg/4.png" alt="">
 </div>
  <!-- <div class="item">
   <img class="img" src="/static/images/home/HomeCenterBg/5.png" alt="">
 </div> -->
</div>
</template>

<script>


  export default {
    name: 'HomeCenterBg',
    data () {
      return {
      }
    },

    methods: {

    },
  }
</script>

<style lang="less" scoped>
 .HomeCenterBg-container {
   z-index: 1;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
  .item {
    width: 100%;
    .img {
      display: block;
      width: 100%;
    }
  }
 }
</style>

<template>
  <div class="enterprise-page">
    <div class="banner">
      <!-- <img src="/static/images/entrepot/SchoolEnterprise.png" /> -->
      <div class="banner-box flex-ccc">
        <div class="banner-title">校企<span>合作</span></div>
        <div class="w100">
          <div class="banner-note">{{about}}</div>
        </div>
      </div>
    </div>
    <div class="enterprise-block flex-ccc">
      <div class="home-list type2">
        <SchoolEnterpriseList :list="list" />
      </div>
      <div class="flex-cc block">
        <Paging v-model="page" :total="total_pages" :items="total_items" :limit="20" />
      </div>
    </div>
  </div>
</template>

<script>
  import SchoolEnterpriseList from '../../components/SchoolEnterpriseList'
  import { ListPage } from '@/utils/Mixin'
  export default {
    mixins: [ListPage],
    data () {
      return {
        about: ''
      }
    },
    methods: {
      async GetList () {
        let { data, status } = await this.$Get('Common.getArticleListV2', {
          page: this.page,
          limit: 20,
          type: 'school_enterprise'
          // is_hot: 1
        })
        if (status) {
          this.HandleRes(data)
          this.about = data.about
        }
      }
    },
    components: {
      SchoolEnterpriseList
    }
  }
</script>

<style lang="less" scoped>
.enterprise-page {
  background-color: #f9f9f9;
  .banner {
    position: relative;
    min-width: 1200px;
    width: 100%;
    height: 380px;
    background-size: cover;
    background-image: url(/static/images/entrepot/SchoolEnterprise.png);
    .banner-box {
      width: 1200px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      .banner-title {
        width: 100%;
        font-size: 56px;
        font-weight: 800;
        line-height: 95px;
        color: #fff;
        span {
          color: #CA0000;
        }
      }
      .w100 {
        width: 100%;
      }
      .banner-note {
        width: 480px;
        font-size: 24px;
        line-height: 36px;
        color: #E5E5E570;
      }
    }
    img {
      width: 100vw;
      min-width: 1200px;
      height: auto;
    }
  }
  .enterprise-block {
    padding: 90px 0 110px;
    min-width: 1200px;
    .block {
      padding: 60px 0 0;
    }
  }
  /deep/ .school-enterprise-item {
    /deep/ .school-enterprise-main .look {
      color: #888;
    }
    /deep/ .school-enterprise-main .look .img {
      background: url('/static/images/icon/right-four.png');
    }
    &:hover {
      transform: translateY(0) !important;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      .school-enterprise-main .look {
        color: #CA0000;
      }
      .school-enterprise-main .look .img {
        background: url('/static/images/icon/right-three.png');
      }
    }
  }
}
</style>

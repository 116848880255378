<template>
  <div class="recent">
    <div class="page box">
      <TiliteNav />
      <div class="mb40">
        <Tab v-model="tab" :list="tab_list" />
      </div>
      <div class="block">
        <CoureList :list="list" type="order" />
      </div>
      <div class="side-box">
        <div class="side-sticky">
          <SideNav />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SideNav from '../../components/SideNav'
  import CoureList from '../../components/CourseList'
  export default {
    data () {
      return {
        tab: 0,
        tab_list: [
          {
            name: '课程',
            type: 'video_type'
          },
          {
            name: '习题',
            type: 'alone_paper_type'
          },
          {
            name: '教材',
            type: 'book_type'
          }
        ],
        list: []
      }
    },
    watch: {
      tab () {
        this.GetList()
      }
    },
    activated () {
      this.GetList()
    },
    methods: {
      async GetList () {
        let { status, data } = await this.$Get('Course.orderList2', {
          page: 1,
          limit: 200,
          goods_type: this.tab_list[this.tab]['type']
        })
        if (status) {
          this.list = data.items
        }
      }
    },
    components: {
      SideNav,
      CoureList
    }
  }
</script>

<style lang="less" scoped>
  .recent {
    min-width: 1200px;
    padding: 0 0 90px 0;
    .flex-ccc();
    .mb40 {
      margin-bottom: 40px;
    }
    .block {
      min-height: 658px;
      padding-bottom: 40px;
    }
    .box {
      padding-left: 170px;
      transform: translateX(-85px);
      position: relative;
    }
    .side-box {
      position: absolute;
      width: 140px;
      height: 100%;
      top: 0;
      left: 0;
      .side-sticky {
        position: sticky;
        top: 164px;
      }
    }
  }
</style>

<template>
  <div class="completion-item">
    <div class="title">
      <span>{{view_question_score}}</span>
      <span class="score">{{question_score}}分</span>
    </div>

    <div class="input-item" v-for="(item, index) in selectTopicAnswer" :key="index+item.question_id">
      <div class="number">{{index + 1}}</div>
      <input class="input-label" placeholder="请输入内容" :value=" backList[index].content"  @input="InputValue($event, index,item)" />
    </div>

  </div>
</template>

<script>
  export default {
    methods: {
      InputValue (e, index, item) {
        this.$emit('onAnswer', {
          index,
          id: this.selectTopicAnswer[index].question_id,
          topic_id: this.selectTopicAnswer[index].topic_id,
          content: e.target.value
        })
      }
    },
    props: {
      value: null,
      index: {
        type: null,
        default: ''
      },
      view_question_score: null,
      question_score: null,
      selectTopicAnswer: null,
      backList: null
    }
  }
</script>

<style lang="less" scoped>
  .completion-item {
    overflow: hidden;
    .mb(.4rem);
    .title {
      .fz(.34rem);
      .c(#333);
      .mb(.6rem);
      .lh(1.6);
      .score {
        .fz(.28rem);
        .c(#CA0000);
      }
    }
    .input-item {
      .h(.8rem);
      position: relative;
      border: 1px solid #e5e5e5;
      .mb(.2rem);
      .br(.1rem);
      overflow: hidden;
      .number {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .w(.4rem);
        .h(.4rem);
        left: .2rem;
        box-sizing: border-box;
        border: 1px solid #999;
        .c(#999);
        .br(.2rem);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .input-label {
        display: block;
        .w();
        .h();
        box-sizing: border-box;
        .pl(.8rem);
      }
    }
  }
</style>

<template>
  <div class="Seminar-page">
    <img :src="i" alt="" v-for="(i,k) in list" :key="k">
  </div>
</template>

<script>
  export default {
      data(){
        return {
            list:[]
        }
      },
      mounted() {
        this.getResearchPicture()
      },
      methods:{
          getResearchPicture(){
              this.$Get('Common.getResearchPicture',{
                  model_type:'pc_web'
              }).then(res=>{
                  if(typeof(res.data.cover)==='string') {
                  	this.list = [res.data.cover]
                  }else{
                  	this.list = res.data.cover
                  }
              })
          }
      }
  }
</script>

<style lang="less" scoped>
.Seminar-page {
  img {
      display: block;
    width: 100%;
  }
}
</style>

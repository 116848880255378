<template>
  <div class="flex-c information" ref="information">
    <div class="information-item" v-for="(item, index) in list" :key="index" :class="{animation: animation && isanimation}" @click="goDetail(item)">
      <img :src="item.cover_url" />
      <div class="information-main">
        <div class="information-title">{{ item.title }}</div>
        <div class="information-content">
          <span>资讯介绍：</span>{{ item.about }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'information',
    data () {
      return {
        isanimation: true
      }
    },
    props: {
      list: [Array],
      animation: [Boolean],
      scrollToTop: {
        type: Function,
        default: () => {}
      }
    },
    methods: {
      goDetail (item) {
        this.$nav('Common.IntroduceDetail', { id: item.id })
      }
    },
    watch: {
      animation (to) {
        if (to) {
          setTimeout(() => {
            this.isanimation = false
          }, 2100)
        }
      }
    },
    mounted () {
      window.addEventListener('scroll', () => { this.scrollToTop(this, 'information', 'informationAnimation') }, true)
      if (this.animation) {
        setTimeout(() => {
          this.isanimation = false
        }, 2100)
      }
    }
  }
</script>

<style lang="less" scoped>
  .information {
    flex-wrap: wrap;
    width: 1200px;
    .information-item {
      width: 270px;
      height: 330px;
      background: #F9F9F9;
      border-radius: 10px;
      margin-right: 40px;
      overflow: hidden;
      position: relative;
      transition: all .2s;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 48px;
      }
      .information-main {
        padding: 20px 18px 18px;
        .information-title {
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          margin-bottom: 12px;
          .line-clamp-multi(2);
        }
        .information-content {
          font-size: 14px;
          line-height: 24px;
          color: #B2B2B2;
          .line-clamp-multi(3);
          margin-bottom: 42px;
          span {
            color: #888;
          }
        }
      }
      .look {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 19px;
        color: #CA0000;
        font-size: 14px;
        img {
          width: 14px;
          height: 14px;
          margin-left: 5px;
        }
      }
      img {
        width: 270px;
        height: 160px;
      }
      &:hover {
        transform: translateY(-20px);
        background: #fff;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      }
    }
  }
</style>

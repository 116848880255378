<template>
  <div class="flex-c course">
    <div class="course-item" v-for="(item, index) in list" :key="index" :class="{animation: animation}" @click="goNav(item)">
      <img :src="item.thumb" />
      <div class="label" v-if="item.is_last_look * 1 === 1">
        <img :src="$img('IconLabel')" />
        <span>上次观看</span>
      </div>
      <div class="course-main">
        <div class="course-title">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'course',
    props: {
      animation: [Boolean],
      list: [Array]
    },
    methods: {
      goNav (item) {
          console.log('尼玛')
        this.$nav('Campus.SeriesCoursesDetails', { id: item.goods_id, sid: this.$route.query.id,goods_package_id:this.$route.query.goods_package_id })
      }
    }
  }
</script>

<style lang="less" scoped>
  .course {
    width: 1200px;
    margin-bottom: 40px;
    .flex-wrap();
    .course-item {
      width: 270px;
      height: 226px;
      background: #fff;
      border-radius: 10px;
      margin-right: 40px;
      position: relative;
      cursor: pointer;
      .label {
        position: absolute;
        top: 10px;
        left: -5px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        img {
          width: 66px;
          height: auto;
          border-radius: 0;
        }
        span {
          position: absolute;
          top: 41%;
          left: 50%;
          display: block;
          line-height: 32px;
          transform: translate(-50%, -50%);
          white-space: nowrap;
        }
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 40px;
      }
      .course-main {
        padding: 16px 16px 25px;
        .course-title {
          height: 26px;
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          .line-clamp-multi(1);
        }
      }
      img {
        width: 270px;
        height: 152px;
        border-radius: 10px 10px 0 0;
      }
      &:hover {
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      }
    }
  }
</style>

<template>
	<div class="RichText">
		<div v-html="content"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				content: ""
			}
		},
		mounted() {
			this.getBannerDetails()
		},
		methods: {
			getBannerDetails() {
				this.$Get('Common.getBannerDetails', {
					banner_id: this.$route.query.banner_id
				}).then(res => {
					this.content = res.data.content
					window.title = ''
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.introduce {
		background: #f9f9f9;
		padding: 1px 0;

		.introduce-box {
			width: 1200px;
			margin: 40px auto 98px;
			background-color: #fff;
			border-radius: 10px;
			box-sizing: border-box;
			padding: 40px 206px 81px 210px;
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);

			.introduce-title {
				font-size: 24px;
				font-weight: bold;
				line-height: 31px;
				color: #333333;
				margin-bottom: 26px;
				text-align: center;
			}

			.introduce-text {
				font-size: 16px;
				line-height: 26px;
				color: #888888;
				margin-bottom: 30px;
			}

			img {
				max-width: 100%;
			}
		}
	}
</style>

<template>
  <div class="discounts">
    <div class="page box">
      <TiliteNav />
      <div class="discounts-box">
        <span>输入优惠码：</span>
        <input type="text" v-model="code" />
        <div class="discounts-btn" @click="getDrawCoupon">兑换</div>
      </div>
      <div class="block">
        <DiscountsList :list="list" @getCoupon="getOrdinaryCoupon" />
      </div>
      <div class="side-box">
        <div class="side-sticky">
          <SideNav />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DiscountsList from '../../components/DiscountsList'
  import SideNav from '../../components/SideNav'
  export default {
    components: {
      SideNav,
      DiscountsList
    },
    data () {
      return {
        list: [],
        code: ''
      }
    },
    activated () {
      this.GetList()
    },
    methods: {
      // 可领优惠卷列表
      async GetList () {
        let { status, data } = await this.$Get('Course.couponList', {
          page: 1,
          limit: 200
        })
        if (status) {
          this.list = data.items
        }
      },
      // 领取普通优惠卷
      async getOrdinaryCoupon (id) {
        let { status, msg } = await this.$Post('Course.getOrdinaryCoupon', {
          coupon_id: id
        })
        if (status) {
          this.$ShowToast(msg, { icon: 'success' })
          this.GetList()
        }
      },
      // 通过优惠码领取优惠卷
      async getDrawCoupon (id) {
        let { status, msg } = await this.$Post('Course.getDrawCoupon', {
          code: this.code
        })
        if (status) {
          this.$ShowToast(msg, { icon: 'success' })
          this.code = ''
          this.GetList()
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .discounts {
    min-width: 1200px;
    padding: 0 0 90px 0;
    .flex-ccc();
    .mb40 {
      margin-bottom: 40px;
    }
    .block {
      min-height: 658px;
      padding-bottom: 40px;
    }
    .box {
      padding-left: 170px;
      transform: translateX(-85px);
      position: relative;
    }
    .discounts-box {
      width: 1200px;
      .flex-cc();
      width: 1200px;
      height: 136px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      margin-bottom: 60px;
      .flex-cc();
      span {
        height: 24px;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
      }
      input {
        width: 360px;
        height: 40px;
        background: #F9F9F9;
        border: 1px solid #E5E5E5;
        opacity: 1;
        border-radius: 20px;
        margin: 0 16px 0 8px;
        box-sizing: border-box;
        padding: 0 10px;
      }
      .discounts-btn {
        .flex-cc();
        width: 88px;
        color: #fff;
        height: 40px;
        background: #CA0000;
        box-shadow: 0px 6px 14px rgba(202, 0, 0, 0.2);
        border-radius: 20px;
        font-size: 18px;
      }
    }
    .side-box {
      position: absolute;
      width: 140px;
      height: 100%;
      top: 0;
      left: 0;
      .side-sticky {
        position: sticky;
        top: 164px;
      }
    }
  }
</style>

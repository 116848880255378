<template>
    <div class="Faculty-container">
        <div class="swiper-warp">
            <div class="swiper-container Faculty-swiper-1">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(i,k) in list" :key="k">
                        <div class="box">
                            <div class="cover-warp">
                                <!-- <img class="cover" :src="i.cover_url+'?x-oss-process=image/resize,w_320,h_410,m_lfit'" alt=""> -->
                                <el-image style="width: 320px; height:410px" :src="i.cover_url" fit="cover"></el-image>
                            </div>
                            <div class="text-warp">
                                <div class="name">{{i.title||''}}</div>
                                <div class="star-row">
                                    <img src="/static/images/home/Faculty/7.png" alt="" v-for="(s,sk) in i.score" :key="sk">
                                </div>
                                <div class="desc">
                                    {{i.about||''}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- @click="prve" -->
            <div class="swiper-button swiper-button-prev" :class="{'swiper-button-disabled':prveDisabled}" @click.stop="prve"></div>
            <div class="swiper-button swiper-button-next" :class="{'swiper-button-disabled':nextDisabled}" @click.stop="next"></div>
        </div>

        <div class="swiper-container Faculty-swiper-2">
            <div class="swiper-wrapper">
                <div class="swiper-slide" :class="{'active':activeIndex==k}" v-for="(i,k) in list" :key="k" @click="handleItem(k)">
                    <div class="cover-warp">
                        <!-- <img class="cover" :src="i.cover_url+'?x-oss-process=image/resize,w_105,h_136,m_lfit'" alt=""> -->
                        <el-image style="width: 105px; height:136px" :src="i.cover_url" fit="cover"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';

    var facultySwiper1 = null
    var facultySwiper2 = null

    export default {
        name: 'Faculty',
        data() {
            return {
                activeIndex: 0,
                list: []
            }
        },
        computed: {
            prveDisabled() {
                return this.activeIndex == 0 || this.list.length < 1
            },
            nextDisabled() {
                return this.activeIndex >= this.list.length - 1 || this.list.length < 1
            }
        },
        async mounted() {
            await this.GetList()

            this.$nextTick(() => {
                facultySwiper2 = new Swiper('.Faculty-swiper-2', {
                    observer: true,
                    observeParents: true,
                    spaceBetween: 10,
                    slidesPerView: 'auto',
                    touchRatio: 0.2,
                    slideToClickedSlide: true,
                    onSlideChangeEnd: swiper => {}
                });

                facultySwiper1 = new Swiper('.Faculty-swiper-1', {
                    observer: true,
                    observeParents: true,
                    thumbs: {
                        swiper: facultySwiper2,
                    },
                    onSlideChangeEnd: swiper => {
                        this.activeIndex = swiper.activeIndex
                    }
                });
                facultySwiper1.params.control = facultySwiper2;
                facultySwiper2.params.control = facultySwiper1;
            })
        },
        methods: {
            async GetList() {
                let {
                    data,
                    status
                } = await this.$Get('Common.getArticleListV2', {
                    page: this.page,
                    limit: 20,
                    type: 'school_teachers',
                    is_hot: 1
                })
                if (status) {
                    this.list = data.items.map(i=>{
                      return {
                        ...i,
                        score:Number(i.score)||0
                      }
                    })


                }
            },
            handleItem(index) {
                this.activeIndex = Number(index)
                facultySwiper1.slideTo(Number(index), 600, false);
            },
            prve() {
                if (!this.prveDisabled) {
                    this.handleItem(this.activeIndex - 1)
                }
            },
            next() {
                if (!this.nextDisabled) {
                    this.handleItem(this.activeIndex + 1)
                }
            },
        },
    }
</script>

<style lang="less" scoped>
    .Faculty-container {
        .swiper-warp {
            position: relative;

            .Faculty-swiper-1 {
                position: relative;
                width: 1260px;
                height: 502px;

                .swiper-wrapper {
                    box-sizing: border-box;
                    padding: 30px;
                    width: 1260px;
                    height: 502px;

                    .swiper-slide {
                        box-sizing: border-box;
                        padding-top: 52px;
                        width: 100%;
                        height: 100%;

                        .box {
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            width: 1200px;
                            height: 390px;
                            background-image: url("/static/images/home/Faculty/1.png");
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            box-shadow: 0px 2px 24px rgba(202, 0, 0, 0.06);
                            border-radius: 10px 10px 60px 10px;
                            background-color: #ffffff;

                            .cover-warp {
                                position: relative;
                                top: -52px;
                                margin: 0 80px;
                                width: 320px;
                                height: 412px;
                                background-image: url("/static/images/home/Faculty/2.png");
                                background-repeat: no-repeat;
                                background-size: 100% 100%;

                                .cover {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .text-warp {
                                width: 580px;

                                .name {
                                    margin-bottom: 10px;
                                    height: 33px;
                                    line-height: 33px;
                                    font-size: 25px;
                                    font-weight: bold;
                                    color: #333333;
                                }

                                .star-row {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 20px;

                                    img {
                                        display: block;
                                        margin-right: 5px;
                                        width: 16px;
                                        height: 16px;
                                    }
                                }

                                .desc {
                                    display: flexbox;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    word-break: break-all;
                                    line-height: 24px;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #888888;
                                }
                            }
                        }
                    }
                }

                // .swiper-button {
                //   position: absolute;
                //   bottom: 60px !important;;
                //   width: 40px;
                //   height: 40px;
                // }
            }

            & /deep/ .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                top: initial !important;
                left: initial !important;
                bottom: 120px;
                width: 40px !important;
                height: 40px !important;
                margin-top: initial !important;
                z-index: 10;
                cursor: pointer !important;
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 1 !important;
            }

            & /deep/ .swiper-button-prev {
                right: 30px;
                background-image: url('/static/images/home/Faculty/5.png');

                &.swiper-button-disabled {
                    background-image: url('/static/images/home/Faculty/3.png');
                }
            }

            & /deep/ .swiper-button-next {
                right: -10px;
                background-image: url('/static/images/home/Faculty/4.png');

                &.swiper-button-disabled {
                    background-image: url('/static/images/home/Faculty/8.png');
                }
            }
        }

        .Faculty-swiper-2 {
            margin-bottom: 60px;
            width: 1200px;

            .swiper-wrapper {
                width: 1200px;
                height: 136px;

                .swiper-slide {
                    width: 180px;
                    box-sizing: border-box;

                    &.active {
                        .cover-warp {
                            background: #FDE2E2;
                        }
                    }

                    .cover-warp {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        width: 180px;
                        height: 136px;
                        background: #F6F6F6;

                        .cover {
                            display: block;
                            width: 105px;
                            height: 136px;
                        }
                    }
                }
            }
        }
    }
</style>

<template>
  <div class="choice-item">
    <div class="title">
      <!-- {{qInidex+1}}、 -->
      <span>{{question_name}}</span>
      <span class="score">{{question_score}}分</span>
    </div>
    <!-- 选项 -->
    <div
      class="option-item"
      :class="{ active: f(item.options) }"
      v-for="(item,index) in topicAnswer"
      :key="item.options"
      @click="Choose(item,index)"
    >
      <!-- <div class="option-number"></div> -->
      <div class="option-content">{{item.options}}.{{item.content}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        valueArr: this.velueSplit()
      }
    },
    methods: {
      velueSplit () {
        if (this.value !== '' && this.value.indexOf(',') > -1) {
          // 多选返回多个选项
          return this.value.split(',')
        }
        return this.value.split('')
      },
      Choose (item, index) {
        let options = item.options
        // 多选
        if (this.multiple_options === true) {
          let i = this.valueArr.indexOf(item.options)
          if (i < 0) {
            // 选中
            this.valueArr.push(item.options)
          } else {
            // 删除已选中
            this.valueArr.splice(i, 1)
          }
          options = this.valueArr.join(',')
        }
        // console.log('options', options)
        this.$emit('input', options)
        this.$emit('onAnswer', {
          id: this.id,
          options: options,
          index: index
        })
        this.$emit('update:options', options)
      }
    },
    computed: {
      f (item) {
        return function (item) {
          if (this.multiple_options === true) {
            // 多选
            return this.options.indexOf(item) > -1
          }
          // 单选
          return this.options === item
        }
      }
    },
    watch: {},
    props: {
      id: null,
      title: {
        type: null,
        default: ''
      },
      options: {
        type: null
      },
      qInidex: {
        type: null
      },
      score: {
        type: null
      },
      question_name: {
        type: null
      },
      question_score: {
        type: null
      },
      topicAnswer: {
        type: null
      },
      multiple_options: null, // 多选
      value: null
    }
  }
</script>

<style lang="less" scoped>
.choice-item {
  overflow: hidden;
  .mb(0.4rem);
  .title {
    .fz(0.34rem);
    .c(#333);
    .mb(0.6rem);
    .lh(1.6);
    .score {
      .fz(0.28rem);
      .c(#CA0000);
    }
  }
  .option-item {
    display: flex;
    .mb(0.35rem);
    cursor: pointer;
    &.active {
      .option-number {
        background-image: linear-gradient(-90deg, #CA0000 0%, #CA0000 100%),
          linear-gradient(#d4d4d4, #d4d4d4);
        background-blend-mode: normal, normal;
        box-shadow: 0rem 0.06rem 0.14rem 0rem rgba(0, 156, 237, 0.24);
        .c(#fff);
      }
      .option-content {
        font-weight: bold;
        .c(#CA0000);
      }
    }
    .option-number {
      .w(0.6rem);
      .h(0.6rem);
      .br(0.3rem);
      .bg(#fff);
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .mr(0.4rem);
    }
    .option-content {
      .fz(0.34rem);
      .c(#333);
      flex: 1;
      .pt(0.15rem);
    }
  }
}
</style>

<template>
  <div class="card-panel">
    <div class="panel-title">答题卡</div>
    <div class="list-wrap">
      <ul class="list">
        <li class="item" v-for="(item,index) in list" :key="index">
          <span class="type">{{types[item.topic_type-1]}}</span>
          <span
            class="number"
            :class="{'active':item.bool}"
            @click="ToIndex(item.index)"
          >{{item.serialNumber}}</span>
        </li>
      </ul>
    </div>

    <div class="btn" @click="$emit('onSubmit')">提交答题</div>
    <span class="close-btn" @click="$emit('update:card_show', false)">
      <img src="/static/images/icon/close.png" alt />
    </span>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        types: ['选', '填', '阅', '作', '听', '选']
      }
    },
    methods: {
      ToIndex (index) {
        this.$emit('update:now_index', index)
        this.$emit('update:card_show', false)
      },
      feachList () {
        if (!this.paperList) return []
        let arr = []
        let completed = 0 // 已完成
        let count = 0 // 总题数
        this.paperList.forEach((element, index) => {
          // topic_type  1 选择题 2填空题 3阅读题 4作文 5听力
          let n = Number.parseInt(element.topic_type)
          let backList = element.topicDetails.backList
          if (n === 1) {
            count += 1
            completed = backList !== '' ? completed + 1 : completed
            arr.push({
              index,
              topic_type: n,
              serialNumber: count,
              bool: backList !== ''
            })
          }
          if (n === 2) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.content !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.content !== '',
                topic_type: n
              })
            })
          }
          if (n === 3) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.options !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.options !== '',
                topic_type: n
              })
            })
          }
          if (n === 4) {
            count += 1
            completed =
              backList.topicDetails !== '' || backList.topicDetailsImg.length
                ? completed + 1
                : completed
            arr.push({
              index,
              serialNumber: count,
              bool:
                backList.topicDetails !== '' || backList.topicDetailsImg.length,
              topic_type: n
            })
          }
          if (n === 5) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.options !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.options !== '',
                topic_type: n
              })
            })
          }
          if (n === 6) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.options !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.options !== '',
                topic_type: n
              })
            })
          }
        })
        // 返回答题进度
        this.$emit('onGetProgress', {
          completed,
          count
        })
        // 返回答题卡列表
        return arr
      }
    },
    props: {
      now_index: null,
      card_show: null,
      paperList: null
    },
    computed: {
      list () {
        return this.feachList()
      }
    }
  }
</script>

<style lang="less" scoped>
.card-panel {
  position: relative;
  .p(0.46rem 0 0.26rem);
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  .panel-title {
    .fz(0.34rem);
    .c(#333);
    font-weight: bold;
    text-align: center;
    .mb(0.64rem);
  }
  .btn {
    width: 180px;
    height: 50px;
    background: #CA0000;
    box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
    opacity: 1;
    border-radius: 25px;
    font-size: 18px;
    color: #fff;
    margin: auto;
    cursor: pointer;
    .flex-cc();
  }
}

.list-wrap {
  min-height: 6rem;
  max-height: 6rem;
  overflow-y: auto;
  .pb(0.4rem);
  box-sizing: border-box;

  .list {
    .p(0 0.4rem);
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .item {
      .w(20%);
      text-align: center;
      margin-bottom: (0.4rem);
      text-align: center;
      border-radius: 0.74rem;

      .type {
        .h(0.4rem);
        .h(0.4rem);
        .c(#101010);
        font-size: 14px;
        display: block;
        text-align: center;
      }

      .number {
        .w(1rem);
        .h(1rem);
        .lh(1rem);
        .c(#ffffff);
        .bg(#d5d5d5);
        display: inline-block;
        text-align: center;
        font-size: 14rpx;
        border-radius: 100%;
        box-sizing: border-box;
        border: 1px solid #bbbbbb;
      }

      .active {
        position: relative;
        .bg(#CA0000);
      }
    }
  }
}

.close-btn {
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  width: 0.48rem;
  height: 0.48rem;

  img {
    display: block;
    width: 0.48rem;
    height: 0.48rem;
  }
}
</style>

<template>
	<div class="teacherIntroduction-wrap">
		
		<img :src="cover" alt="">
	<!-- 	<div class="faculty-wrap">
			<img class="title-icon" src="/static/images/AboutUs/teacherIntroduction/2.png" alt="">
			<div class="text-wrap">
				<div class="text">
					学校现有教职工100余人，专职日语教师86人。我们拥有一支独立的高考研发小组，由高考日语特级教师带队，14名具有6年多高考经验的解题专家，定期进行大数据预测，将高考知识点出现的概率进行量化统计，考题覆盖率高达90%，使考生的复习更有针对性，考什么就复习什么，在最短的时间内达到最好的提分效果。
				</div>
			</div>
		</div>
		<img class="title-icon" src="/static/images/AboutUs/teacherIntroduction/3.png" alt=""> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				cover: ''
			}
		},
		mounted() {
			this.getTeachersBackground()
		},
		methods: {
			getTeachersBackground() {
				this.$Get('Common.getTeachersBackground', {
					model_type: 'pc_web'
				}).then(res => {
					this.cover = res.data.cover
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.teacherIntroduction-wrap {
		img {
			width: 100%;
		}

		.title-icon {
			display: block;
			margin: 0 auto 38px;
			width: 337px;
			height: 134px;
		}

		.faculty-wrap {
			margin-bottom: 80px;

			.text-wrap {
				box-sizing: border-box;
				margin: 0 auto;
				width: 1200px;
				padding: 24px;
				background: linear-gradient(135deg, #ffedbc 3%, #ffc96b 99%);
				border-radius: 10px;

				.text {
					padding: 20px 16px;
					line-height: 48px;
					background: #fffae4;
					border-radius: 10px;
					font-size: 20px;
					color: #ca0000;
				}
			}
		}
	}
</style>

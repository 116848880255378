<template>
    <div class="ExcellentCourses-container">
        <div class="swiper-warp">
            <div class="swiper-container ExcellentCourses-swiper-1">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(i,k) in list" :key="k">
                        <div class="box">
                            <div class="left-warp">
                                <div class="top">
                                    <img class="icon" src="/static/images/home/ExcellentCourses/2.png" alt="">
                                </div>
                                <div class="cover-warp">
                                    <!-- <img class="cover" :src="i.thumb"> -->
                                    <el-image style="width: 560px; height:410px" :src="i.thumb" fit="cover"></el-image>
                                </div>
                            </div>
                            <div class="right-warp">
                                <div class="text-box">
                                    <div class="name">
                                        {{i.title||''}}
                                    </div>
                                    <div class="desc-warp" v-if="i.about">
                                        <div class="sub-title">
                                            课程简介
                                        </div>
                                        <div class="text-row-warp">
                                            <div class="row">{{i.about||''}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-btn" @click.stop="goNav(i)">
                                    查看详情
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-button swiper-button-prev" :class="{'swiper-button-disabled':prveDisabled}" @click.stop="prve"></div>
            <div class="swiper-button swiper-button-next" :class="{'swiper-button-disabled':nextDisabled}" @click.stop="next"></div>
        </div>

        <div class="swiper-container ExcellentCourses-swiper-2">
            <div class="swiper-wrapper">
                <div class="swiper-slide" :class="{'active':activeIndex==k}" v-for="(i,k) in list" :key="k" @click="handleItem(k)">
                    <div class="cover-warp">
                        <!-- <img class="cover" :src="i.thumb" alt=""> -->
                        <el-image style="width: 270px; height:200px" :src="i.thumb" fit="cover"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';

    var ExcellentCoursesSwiper1 = null
    var ExcellentCoursesSwiper2 = null

    export default {
        name: 'ExcellentCourses',
        data() {
            return {
                activeIndex: 0,
                list: []
            }
        },
        computed: {
            prveDisabled() {
                return this.activeIndex == 0 || this.list.length < 1
            },
            nextDisabled() {
                return this.activeIndex >= this.list.length - 1 || this.list.length < 1
            }
        },
        async mounted() {
            await this.GetList()
            this.$nextTick(() => {
                ExcellentCoursesSwiper2 = new Swiper('.ExcellentCourses-swiper-2', {
                    observer: true,
                    observeParents: true,
                    spaceBetween: 40,
                    slidesPerView: 'auto',
                    touchRatio: 0.2,
                    slideToClickedSlide: true,
                    onSlideChangeEnd: swiper => {}
                });
                ExcellentCoursesSwiper1 = new Swiper('.ExcellentCourses-swiper-1', {
                    observer: true,
                    observeParents: true,
                    thumbs: {
                        swiper: ExcellentCoursesSwiper2,
                    },
                    onSlideChangeEnd: swiper => {
                        this.activeIndex = swiper.activeIndex
                    }
                });
                ExcellentCoursesSwiper1.params.control = ExcellentCoursesSwiper2;
                ExcellentCoursesSwiper2.params.control = ExcellentCoursesSwiper1;
            })
        },
        methods: {
            async GetList() {
                let {
                    data,
                    status
                } = await this.$Get('Common.purchaseListV2', {
                    page: 1,
                    limit: 20,
                })
                if (status) {
                    this.list = data.purchase || []
                }
            },
            handleItem(index) {
                this.activeIndex = index
                ExcellentCoursesSwiper1.slideTo(index, 600, false);
            },
            prve() {
                if (!this.prveDisabled) {
                    this.handleItem(this.activeIndex - 1)
                }
            },
            next() {
                if (!this.nextDisabled) {
                    this.handleItem(this.activeIndex + 1)
                }
            },

            goNav(item) {
                console.log('0000', item)
                // return
                let URL = ''
                let query = {
                    id: item.goods_id || item.id
                }
                switch (item.goods_type) {
                    case 'video_type':
                        URL = 'Campus.CheckCourseDetails'
                        break
                    case 'package_type':
                        if (this.type === 'my') {
                            URL = 'Course.SeriesCourses'
                        } else {
                            URL = 'Campus.SeriesCourses'
                        }
                        break
                    case 'alone_paper_type':
                        if (this.type === 'my') {
                            URL = 'Course.OnlineProblemSets'
                        } else {
                            URL = 'Campus.OnlineProblemSets'
                        }
                        break
                    case 'subtitles_type':
                        query = {
                            id: item.goods_id || item.id
                        }
                        URL = 'Campus.SubtitlesDetail'
                        break
                    default:
                        query = {
                            id: item.goods_id || item.id
                        }
                        URL = 'Campus.SeriesCoursesDetails'
                        break
                }
                this.$nav(URL, query)
            },


        },
    }
</script>

<style lang="less" scoped>
    .ExcellentCourses-container {

        .swiper-warp {
            position: relative;

            .ExcellentCourses-swiper-1 {
                position: relative;
                margin-bottom: 10px;
                width: 1260px;

                .swiper-wrapper {
                    box-sizing: border-box;
                    width: 100%;
                    height: 520px;

                    .swiper-slide {
                        box-sizing: border-box;
                        padding: 30px;
                        width: 100%;
                        height: 100%;

                        .box {
                            display: flex;
                            align-items: flex-start;

                            .left-warp {
                                margin-right: 60px;
                                width: 580px;

                                .top {
                                    height: 29px;

                                    .icon {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 10px 10px 0px 0px;
                                    }
                                }

                                .cover-warp {
                                    box-sizing: border-box;
                                    padding: 9px;
                                    width: 100%;
                                    height: 430px;
                                    background: #FFFFFF;
                                    box-shadow: 0px 6px 20px rgba(202, 0, 0, 0.1);
                                    border-radius: 0px 0px 10px 10px;

                                    .cover {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 10px;
                                    }
                                }
                            }

                            .right-warp {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                box-sizing: border-box;
                                height: 460px;

                                .text-box {
                                    min-height: 300px;
                                    margin-bottom: 20px;

                                    .name {
                                        position: relative;
                                        padding-right: 58px;
                                        box-sizing: border-box;
                                        margin-bottom: 40px;
                                        height: 30px;
                                        line-height: 30px;
                                        font-size: 24px;
                                        font-weight: bold;
                                        color: #333333;
                                        display: flexbox;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        word-break: break-all;

                                        &::after {
                                            position: absolute;
                                            top: -10px;
                                            right: 0;
                                            content: " ";
                                            width: 48px;
                                            height: 48px;
                                            background-image: url('/static/images/home/ExcellentCourses/3.png');
                                            background-repeat: no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    .desc-warp {
                                        .sub-title {
                                            position: relative;
                                            margin-bottom: 15px;
                                            height: 26px;
                                            line-height: 26px;
                                            font-size: 20px;
                                            font-weight: 400;
                                            color: #333333;

                                            &::before {
                                                position: absolute;
                                                bottom: 4px;
                                                left: 0;
                                                content: ' ';
                                                width: 80px;
                                                height: 6px;
                                                background: linear-gradient(90deg, #CA0000 0%, rgba(202, 0, 0, 0) 100%);
                                                opacity: 0.6;
                                                border-radius: 6px;
                                            }
                                        }
                                    }

                                    .text-row-warp {
                                        .row {
                                            position: relative;
                                            box-sizing: border-box;
                                            padding-left: 20px;
                                            line-height: 34px;
                                            font-size: 18px;
                                            font-weight: 400;
                                            color: #B2B2B2;

                                            &::before {
                                                position: absolute;
                                                top: 12px;
                                                left: 0;
                                                content: " ";
                                                width: 0;
                                                height: 0;
                                                border-top: 7px solid transparent;
                                                border-left: 10px solid #FFD036;
                                                border-bottom: 7px solid transparent;
                                            }
                                        }
                                    }
                                }

                                .bottom-btn {
                                    box-sizing: border-box;
                                    width: 134px;
                                    height: 40px;
                                    line-height: 40px;
                                    background: #FFFFFF;
                                    border: 1px solid #E5E5E5;
                                    border-radius: 4px;
                                    text-align: center;
                                    color: #333333;
                                    font-size: 16px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            & /deep/ .swiper-button-prev,
            .swiper-button-next {
                position: absolute;
                top: initial !important;
                left: initial !important;
                bottom: 30px;
                width: 40px !important;
                height: 40px !important;
                margin-top: initial !important;
                z-index: 10;
                cursor: pointer;
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 1 !important;
            }

            & /deep/ .swiper-button-prev {
                right: 70px;
                background-image: url('/static/images/home/ExcellentCourses/7.png');

                &.swiper-button-disabled {
                    background-image: url('/static/images/home/ExcellentCourses/6.png');
                }
            }

            & /deep/ .swiper-button-next {
                right: 30px;
                background-image: url('/static/images/home/ExcellentCourses/4.png');

                &.swiper-button-disabled {
                    background-image: url('/static/images/home/ExcellentCourses/5.png');
                }
            }
        }

        .ExcellentCourses-swiper-2 {
            margin-bottom: 60px;
            width: 1200px;

            .swiper-wrapper {
                width: 1200px;
                height: 200px;

                .swiper-slide {
                    width: 270px;
                    box-sizing: border-box;

                    &.active {
                        .cover-warp {
                            background: #FDE2E2;
                        }
                    }

                    .cover-warp {
                        width: 270px;
                        height: 200px;
                        background: #F6F6F6;
                        border-radius: 10px;
                        overflow: hidden;

                        .cover {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
</style>

<template>
	<div class="home-page">
		<div class="home-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item, index) in banner" :key="index">
					<img :src="item.cover_url" @click="clickBanner(item)" />
				</div>
			</div>
			<div class="swiper-pagination"></div>
		</div>
		<div class="home-center-warp">
			<div class="home-center">
				<div class="home-block flex-ccc">
					<DiyTitle title="高考选日语，轻松拿高分" :animation="true" />
					<Relaxed />
					<div class="look-more type3" @click="$nav('Common.ShowOffCexJapanese')">查看更多</div>
				</div>

				<div class="home-block flex-ccc">
					<DiyTitle title="二十年教学沉淀，圆梦数万考生" :animation="true" />
					<Precipitate :teaching_background="teaching_background" />
					<div class="look-more type3" @click="$nav('Common.ShowOffSchoolPerformance')">查看更多</div>
				</div>

				<div class="home-block flex-ccc">
					<DiyTitle title="优秀的师资力量" :animation="true" />
					<Faculty />
					<div class="look-more type3" @click="$nav('Common.Teachers')">查看更多</div>
				</div>

				<div class="home-block flex-ccc">
					<DiyTitle title="优秀学员的心声" :animation="true" />
					<VoiceofTheHeart />
					<div class="look-more type3" @click="$nav('Common.ShowOffCampusStyleCampusVoice')">查看更多</div>
				</div>

				<div class="home-block ExcellentCourses-block flex-ccc">
					<DiyTitle title="精品课程" :animation="true" />
					<ExcellentCourses />
					<div class="look-more type3" @click="$nav('Campus.OnlineCourses')">查看更多</div>
				</div>

				<!-- <div class="home-block block4 flex-ccc">
          <DiyTitle title="校企合作" :animation="true" v-if="campusNewsActive==1" />
          <DiyTitle title="校园资讯" :animation="true" v-if="campusNewsActive==2" />
          <CampusNews v-model="campusNewsActive" />
          <div class="look-more type3" @click="$nav('Common.SchoolEnterprise')" v-if="campusNewsActive==1">查看更多</div>
          <div class="look-more type3" @click="$nav('Common.Information')" v-if="campusNewsActive==2">查看更多</div>
        </div> -->
			</div>
			<HomeCenterBg />
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	import Swiper from 'swiper'
	import CourseList from '../../components/CourseList'
	import AcademyList from '../../components/AcademyList'
	import SchoolEnterpriseList from '../../components/SchoolEnterpriseList'
	import InformationList from '../../components/InformationList'
	import TeacherSwiper from '../../components/TeacherSwiper'
	import ActivityItem from '../../components/ActivityList/item'
	import Relaxed from '../../components/Relaxed'
	import Precipitate from '../../components/Precipitate'
	import Faculty from '../../components/Faculty'
	import VoiceofTheHeart from '../../components/VoiceofTheHeart'
	import ExcellentCourses from '../../components/ExcellentCourses'
	import CampusNews from '../../components/CampusNews'
	import HomeCenterBg from '../../components/HomeCenterBg'
	import {
		IEVersion
	} from '@/utils/Tool'
	import {
		mapState
	} from 'vuex'
	export default {
		inject: ['app'],
		data() {
			return {
				banner: [],
				purchase: {},
				school: {},
				school_campus: {},
				school_enterprise: {},
				school_news: {},
				school_teachers: {},
				activeIndex: 0,
				courseAnimation: false,
				academyAnimation: false,
				schoolAnimation: false,
				informationAnimation: false,
				offlineAnimation: false,
				academyTitAnimation: false,
				schoolTitAnimation: false,
				informationTitAnimation: false,
				teacherTitAnimation: false,
				offlineTitAnimation: false,
				activityAnimation: false,
				teacherSwiperAnimation: false,
				campusNewsAnimation: false,
				homeSwiper: null,
				campusNewsActive: 1,
				swiperOptions: {
					loop: true,
					speed: 2000,
					pagination: '.swiper-pagination',
					paginationClickable: true,
					// 如果需要自动切换海报
					autoplay: 5000,
					effect: IEVersion() === -1 ? 'cube' : 'silde'
				},
				teaching_background: ''
			}
		},
		computed: {
			customswiper() {
				return this.$refs.mySwiper.$swiper
			},
			...mapState('Common', ['sign'])
		},
		methods: {
			async getIndex() {
				let {
					data,
					status
				} = await this.$Get('Common.getIndex', {
					type: 'pc'
				})
				if (status) {
					this.banner = data.banner
					this.purchase = data.purchase
					this.school = data.school
					this.school_campus = data.school_campus
					this.school_enterprise = data.school_enterprise
					this.school_news = data.school_news
					this.school_teachers = data.school_teachers
					this.purchase.list = data.purchase.list && data.purchase.list.slice(0, 4)
					this.school.list = data.school.list && data.school.list.slice(0, 4)
					this.school_campus.list = data.school_campus.list && data.school_campus.list.slice(0, 4)
					this.school_enterprise.list = data.school_enterprise.list && data.school_enterprise.list.slice(0,
						4)
					this.school_news.list = data.school_news.list && data.school_news.list.slice(0, 4)
					this.teaching_background = data.teaching_background
					if (!this.homeSwiper) {
						this.$nextTick(() => {
							this.homeSwiper = new Swiper('.home-container', this.swiperOptions)
						})
					}
				}
			},
			// 点击轮播图
			clickBanner({
				link,link_type,id
			}) {
				if (link && link_type*1===1) {
					window.open(link)
				}
				
				if(link_type*1===2) {
					this.$nav('Common.RichText',{
						banner_id:id
					})
				}
			},
			// 回到顶部
			goScrollTop() {
				$(document).scrollTop(0)
			},
			scrollToTop(that, el, key) {
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
				var scrllHeight = window.innerHeight
				const elTop = that.$refs[el] && that.$refs[el].offsetTop
				if ((scrollTop + scrllHeight - 80) >= elTop) {
					this[key] = true
				}
			},
			// 锚点
			anchor(flag = true) {
				let top = 0
				switch (this.sign) {
					case 'Academy':
						top = this.$refs.academybox.offsetTop - 30
						break
					case 'Teachers':
						top = this.$refs.teachersbox.offsetTop - 30
						break
					case 'SchoolEnterprise':
						top = this.$refs.schoolbox.offsetTop - 30
						break
					case 'Information':
						top = this.$refs.informationbox.offsetTop - 30
						break
					case 'Online':
						top = this.$refs.onlinebox.offsetTop - 30
						break
					case 'Offline':
						top = this.$refs.offlinebox.offsetTop - 30
						break
				}
				flag && $('body,html').animate({
					scrollTop: top
				}, 1000)
			}
		},
		components: {
			CourseList,
			AcademyList,
			SchoolEnterpriseList,
			InformationList,
			TeacherSwiper,
			ActivityItem,
			Relaxed,
			Precipitate,
			Faculty,
			VoiceofTheHeart,
			ExcellentCourses,
			CampusNews,
			HomeCenterBg
		},
		mounted() {
			this.getIndex()
			window.addEventListener('scroll', () => {
				this.scrollToTop(this, 'activity', 'activityAnimation')
			}, true)
		},
		watch: {
			'sign'(to) {
				if (to) {
					this.anchor()
				}
			}
		},
		activated() {
			this.homeSwiper && this.homeSwiper.startAutoplay()
			setTimeout(() => {
				this.anchor(false)
			}, 500)
			this.$nextTick(() => {
				this.anchor(false)
			})
		},
		deactivated() {
			this.homeSwiper && this.homeSwiper.stopAutoplay()
		}
	}
</script>

<style lang="less" scoped>
	.opacity {
		opacity: 0;
	}

	.wrapper {
		position: relative;
	}

	.home-page {
		.swiper-wrapper {
			position: relative;
		}

		.swiper-slide {
			width: 100%;

			img {
				max-width: 100vw;
				width: 100vw;
				min-width: 1200px;
				height: auto;
			}
		}

		/deep/ .swiper-cube-shadow {
			display: none !important;
		}

		/deep/ .swiper-pagination {
			.flex-cc();
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 20px;
			z-index: 9999;

			.swiper-pagination-bullet {
				width: 38px;
				height: 6px;
				background: #fdfeff;
				margin: 0 4px;
				opacity: 0.5;
				border-radius: 0;
				cursor: pointer;

				&.swiper-pagination-bullet-active {
					opacity: 1;
				}
			}
		}

		.home-block {
			z-index: 10;
			position: relative;
			// padding: 90px 50px 60px;
			padding: 80px 50px 60px;
			// background-color: #fff;
			min-width: 1200px;
			transition: all 2s;
			margin: auto;
			overflow: hidden;

			&.block2 {
				background: #f9f9f9;
			}

			&.block3 {
				padding-bottom: 110px;
			}

			&.block4 {
				background-color: #fbfbfb;
			}

			&.ExcellentCourses-block {
				// background-color: #CA0000;
				background-image: url('/static/images/home/HomeCenterBg/5.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}

			.look-more {
				width: 240px;
				height: 50px;
				background: #ca0000;
				box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
				cursor: pointer;
				border-radius: 25px;
				color: #ffffff;
				font-size: 18px;
				line-height: normal;
				.flex-cc();

				&.type2 {
					background: #ececec;
					color: #888888;
					box-shadow: none;
				}

				&.type3 {
					background: #ffffff;
					border: 1px solid #CA0000;
					color: #CA0000;
					box-shadow: none;

					&:hover {
						background: #ca0000;
						box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
						color: #ffffff;
					}
				}
			}

			.home-list {
				&.type1 {
					padding: 70px 0 80px;
				}

				&.type2 {
					padding: 50px 0 60px;
				}

				&.type3 {
					padding: 50px 0 80px;
				}

				&.type4 {
					padding: 50px 0 80px;
				}

				&.type5 {
					padding: 40px 0;
				}

				&.home-couse {
					min-height: 302px;
					width: 1200px;
					overflow: hidden;
				}
			}
		}

	}

	.home-center-warp {
		background: #ffffff;
		position: relative;
	}
</style>

<template>
    <div class="course-item" :class="{animation: animation}" @click="goNav(item)">
        <img :src="item.good_thumb || item.goods_thumb || item.thumb" />
        <div class="course-main">
            <div class="course-title">{{item.goods_name || item.goods_title}}</div>
            <div class="course-content">{{item.about}}</div>
            <div class="course-btn" v-if="type !== 'order'" :class="{type1: item.goods_type === 'video_type'}">{{getBtnText(item)}}</div>
            <div class="course-hot">{{item.num}}</div>
        </div>
        <img :src="$img('IconPlay')" class="play-img" v-if="item.goods_type === 'video_type'">
    </div>
</template>

<script>
    export default {
        props: {
            item: [Object],
            animation: [Boolean],
            type: [String]
        },
        methods: {
            goNav(item) {
                let URL = ''
                let query = {
                    id: item.goods_id || item.id
                }
                switch (item.goods_type) {
                    case 'video_type':
                        URL = 'Campus.CheckCourseDetails'
                        break
                    case 'package_type':
                        if (this.type === 'my') {
                            // URL = 'Course.SeriesCourses'
                            // ywc
                            URL = 'Campus.SeriesCourses'
                            
                            query = {
                                order_sn: item.order_sn,
                                goods_package_id: item.goods_id,
                                 id:item.goods_id
                            }
                        } else {
                            URL = 'Campus.SeriesCourses'
                        }
                        break
                    case 'alone_paper_type':
                        if (this.type === 'my') {
                            URL = 'Course.OnlineProblemSets'
                        } else {
                            URL = 'Campus.OnlineProblemSets'
                        }
                        break
                    case 'book_type':
                        if (this.type === 'order') {
                            query.sn = item.order_sn
                            URL = 'Course.BookOrderDetails'
                        } else {
                            URL = 'Campus.SeriesCoursesDetails'
                        }
                        break
                    default:
                        URL = 'Campus.SeriesCoursesDetails'
                        break
                }
                this.$nav(URL, query)
            },
            getBtnText(item) {
                let text = ''
                switch (item.goods_type) {
                    case 'video_type':
                        text = '观看'
                        break
                    case 'alone_paper_type':
                        text = '练习'
                        break
                    default:
                        text = '学习'
                        break
                }
                return text
            }
        }
    }
</script>

<style lang="less" scoped>
    .course-item {
        width: 270px;
        height: 302px;
        background: #F9F9F9;
        border-radius: 10px;
        margin-right: 40px;
        overflow: hidden;
        position: relative;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);

        &:nth-child(4n) {
            margin-right: 0;
        }

        &:nth-child(n+5) {
            margin-top: 40px;
        }

        .play-img {
            position: absolute;
            width: 40px;
            height: 40px;
            left: 16px;
            top: 16px;
        }

        .course-hot {
            position: absolute;
            left: 16px;
            bottom: 30px;
            font-size: 14px;
            line-height: 24px;
            color: #CA0000;
        }

        .course-btn {
            width: 88px;
            position: absolute;
            bottom: 20px;
            right: 20px;
            height: 40px;
            background: #FFDEDE;
            border-radius: 22px;
            font-size: 16px;
            color: #CA0000;
            cursor: pointer;
            .flex-cc();
            line-height: normal;

            &.type1,
            &.type1:hover {
                color: #333;
                background: #F0F0F0;
            }

            &:hover {
                background: #CA0000;
                color: #fff;
            }
        }

        .course-main {
            padding: 16px 16px 25px;

            .course-title {
                height: 26px;
                font-size: 20px;
                line-height: 25px;
                color: #333333;
                margin-bottom: 16px;
                .line-clamp-multi(1);
            }

            .course-content {
                font-size: 14px;
                line-height: 24px;
                color: #B2B2B2;
                .line-clamp-multi(3);

                span {
                    color: #888;
                }
            }
        }

        img {
            width: 270px;
            height: 152px;
        }
    }
</style>

<template>
  <div class="page">
    <TiliteNav>
      <template v-if="$route.meta.parent">
        <span v-for="(item, index) in $route.meta.parent" :key="index" @click="$nav(item.url)">{{item.title}} >> </span>
      </template>
      {{goods_detail_name}}
    </TiliteNav>
    <div class="look-detail">
      <div class="look-detail-head">
        <div class="look-detail-head-left">
          <video
            id="myVideo"
            v-show="startVideo && videoUrl"
            controls="controls"
            class="source"
            @play="startVideoViewLog"
            @pause="endVideoViewLog"
            :src="videoUrl"
          ></video>
          <template v-if="!startVideo">
            <img :src="thumb" class="bigimg">
            <img :src="$img('IconPlay')" class="play-img" v-if="videos.length" @click="startVideoEvent">
          </template>
        </div>
        <div class="look-detail-head-right" v-if="goods.goods_type !== 'book_type' && videos.length">
          <div class="look-page" :class="{disabled: carousel === 0}" @click="carouselChage('pev')">
            <img :src="carousel === 0 ? $img('IconTopOne') : $img('IconTopTwo')" class="">
          </div>
          <div class="look-video">
            <div class="look-video-item" v-for="(item, index) in videos" :key="index" @click="switchover(index)">
              <img :src="item.thumb">
              <img :src="$img('IconPlay')" class="play-img">
              <div class="look-video-title">{{item.title}}</div>
            </div>
          </div>
          <div class="look-page" :class="{disabled: carousel === videos.length - 1}" @click="carouselChage('next')">
            <img :src="carousel === videos.length - 1 ? $img('IconTopOne') : $img('IconTopTwo')" class="reverse">
          </div>
        </div>
      </div>
      <div class="look-detail-head-two" :class="{book: goods.goods_type === 'book_type'}">
        <div class="flex-1">
          <div class="look-detail-head-two-title">
            {{goods.title}}
          </div>
          <!-- <div class="look-detail-head-two-desc">高考日语第一册</div> -->
        </div>
        <div class="look-detail-head-two-btn" v-if="goods.my_purchase * 1 === 0 && $route.query.sid" @click="handlePay">购买本系列课程</div>
        <div class="look-detail-head-two-btn" v-else-if="goods.my_purchase * 1 === 0 && goods.goods_type === 'book_type'" @click="handlePay">购买教材</div>
      </div>
      <TiliteNav v-if="recommend.length">
        <div class="title">推荐搭配</div>
      </TiliteNav>
      <CoureList :list="recommend" />
      <TiliteNav>
        <div class="title">{{goods_detail_name}}</div>
      </TiliteNav>
      <div class="look-detail-content" v-html="goods.content"></div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CoureList from '../../components/CourseList'
  export default {
    data () {
      return {
        goods: {},
        isNeedShipping: null,
        recommend: [],
        videos: [],
        playVideoIndex: 0,
        startVideo: false,
        myVideo: null,
        carousel: 0,
        log_id: ''
      }
    },
    computed: {
      thumb () {
        if (this.goods.goods_type === 'book_type') {
          return this.goods.banner[0] || this.goods.thumb
        }
        if (this.goods.my_purchase * 1 === 0) {
          return this.goods.thumb
        }
        return this.videos[this.playVideoIndex] ? this.videos[this.playVideoIndex]['thumb'] : this.goods.thumb
      },
      videoUrl () {
        if (this.goods.my_purchase * 1 === 0 && this.goods.banner && this.goods.banner[0]) {
          return this.goods.banner[0] || ''
        }
        if (this.videos[this.playVideoIndex]) {
          return this.videos[this.playVideoIndex]['url']
        }
        return ''
      },
      goods_detail_name () {
        let text = ''
        switch (this.goods.goods_type) {
        case 'book_type':
          text = '教材详情'
          break
        case 'alone_paper_type':
          text = '习题详情'
          break
        default:
          text = '课程详情'
          break
        }
        document.title = text || this.$route.meta.title
        return text
      },
      ...mapState('Common', ['isLogin'])
    },
    watch: {
      '$route.query.id' (to) {
        if (to) {
          this.goodsDetails()
        }
      }
    },
    activated () {
      this.goodsDetails()
      this.myVideo = document.getElementById('myVideo')
    },
    deactivated () {
      this.endVideoViewLog()
    },
    methods: {
      async goodsDetails () {
        let { status, data } = await this.$Get('Common.goodsDetails', {
          id: this.$route.query.id,
          goods_package_id: this.$route.query.sid || this.$route.query.goods_package_id || ''
        })
        if (status) {
          this.goods = data.goods
          this.isNeedShipping = data.isNeedShipping
          this.recommend = data.recommend
          this.videos = data.goods.video_list || []
        }
      },
      handlePay () {
        if (this.goods.goods_type !== 'book_type') {
          this.$Bus.$emit('Pay', { id: this.$route.query.sid })
        } else {
          // this.$Bus.$emit('bookPay', { id: this.$route.query.id })
          this.$Bus.$emit('showBookPay', { id: this.$route.query.id, amount: this.goods.original_price })
        }
      },
      startVideoEvent () {
        if (this.videoUrl) {
          this.startVideo = true
          this.myVideo = document.getElementById('myVideo')
          this.myVideo && this.myVideo.play()
          this.playVideoLog()
          // this.startVideoViewLog()
        }
      },
      // 记录
      playVideoLog () {
        if (this.isLogin) {
          this.$Post('Common.playVideoLog', {
            id: this.$route.query.id
          }, { loading: false })
        }
      },
      // 开始记录
      async startVideoViewLog () {
        if (this.isLogin) {
          let { status, data } = await this.$Post('Common.startVideoViewLog', {
            goods_id: this.$route.query.id
          }, { loading: false })
          if (status) {
            this.log_id = data.log_id
          }
        }
      },
      // 观看结束
      async endVideoViewLog () {
        if (this.isLogin && this.log_id) {
          let { status } = await this.$Post('Common.endVideoViewLog', {
            log_id: this.log_id
          }, { loading: false })
          if (status) {
            this.log_id = ''
          }
        }
      },
      switchover (i) {
        this.startVideo = false
        this.myVideo && this.myVideo.pause()
        if (this.goods.my_purchase * 1 === 0) {
          return this.$ShowConfirm('您还未购买课程，请购买后观看视频内容', {
            showCancel: false
          })
        }
        this.playVideoIndex = i
      },
      carouselChage (type) {
        switch (type) {
        case 'next':
          if (this.carousel < this.videos.length - 1) {
            this.carousel++
          }
          break
        default:
          if (this.carousel > 0) {
            this.carousel--
          }
          break
        }
        document.querySelector('.look-video').scrollTop = 192 * this.carousel
      }
    },
    components: {
      CoureList
    }
  }
</script>

<style lang="less" scoped>
  .page {
    margin: auto;
    #myVideo {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
    .look-detail {
      width: 1200px;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      margin-bottom: 90px;
      box-sizing: border-box;
      padding: 40px;
      .look-detail-head {
        .flex-c();
        margin-bottom: 30px;
        .look-detail-head-left {
          // width: 820px;
          flex: 1;
          height: 462px;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          .bigimg {
            width: 100%;
            height: 100%;
          }
          .play-img {
            width: 72px;
            height: 72px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
        .look-detail-head-right {
          width: 260px;
          height: 462px;
          margin-left: 40px;
          .flex-ccc();
          .look-page {
            width: 260px;
            height: 46px;
            background: #FFE1E1;
            border-radius: 10px;
            cursor: pointer;
            .flex-cc();
            &.disabled {
              background: #F0F0F0;
            }
          }
          .look-video {
            .flex-1();
            margin: 30px 0;
            // overflow: auto;
            overflow: hidden;
            .look-video-item {
              padding-bottom: 10px;
              position: relative;
              overflow: hidden;
              width: 260px;
              cursor: pointer;
              img {
                width: 260px;
                height: 148px;
                border-radius: 10px;
                margin-bottom: 10px;
              }
              .play-img {
                position: absolute;
                width: 40px;
                height: 40px;
                left: 50%;
                top: 54px;
                transform: translateX(-50%);
              }
              .look-video-title {
                font-size: 18px;
                line-height: 24px;
                color: #333333;
                width: 100%;
                .line-clamp()
              }
            }
            &::-webkit-scrollbar {
            /*滚动条整体样式*/
              width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
              height: 10px;
            }
            &::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              background: transparent;
            }
            &::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              width: 12px;
              height: 216px;
              background-color: transparent;
            }
          }
        }
      }
      .look-detail-head-two {
        width: 820px;
        margin-bottom: 10px;
        &.book {
          width: 100%;
        }
        .flex-c();
        .look-detail-head-two-title {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          color: #333333;
          // margin-bottom: 12px;
          .line-clamp();
        }
        .look-detail-head-two-desc {
          font-size: 18px;
          line-height: 32px;
          color: #888888;
          margin-top: 12px;
        }
        .look-detail-head-two-btn {
          width: 180px;
          height: 50px;
          background: #CA0000;
          box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
          border-radius: 25px;
          font-size: 18px;
          color: #fff;
          cursor: pointer;
          .flex-cc();
          margin-left: 30px;
        }
      }
      /deep/ .title-nav {
        &::before {
          height: 28px;
        }
      }
      /deep/ .course {
        width: 100%;
        .course-item {
          width: 250px;
          height: 280px;
          img {
            width: 250px;
            height: 140px;
          }
          .course-btn {
            bottom: 16px;
            right: 18px;
          }
          .course-title {
            margin-bottom: 10px;
          }
        }
      }
      .title {
        height: 32px;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: #333333;
      }
      .look-detail-content {
        font-size: 16px;
        line-height: 32px;
        color: #888888;
        margin-bottom: 20px;
        margin-top: -20px;
      }
    }
  }
</style>

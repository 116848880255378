<template>
  <div class="recent">
    <div class="page box">
      <TiliteNav />
      <div class="block">
        <div class="detail-head">
          <img :src="info.good_thumb" alt="">
          <div class="detail-head-main">
            <div class="flex-c">
              <div class="detail-head-title">{{info.goods_name}}</div>
              <div class="detail-head-status">{{order_status}}</div>
            </div>
            <div class="detail-head-price">
              ￥<span>{{info.order_amount}}</span>
            </div>
          </div>
        </div>
        <div class="detail-line">
          <div class="label">收货人</div>
          <div class="content">{{info.consignee}}/{{info.user_phone}}</div>
        </div>
        <div class="detail-line">
          <div class="label">学号</div>
          <div class="content">{{info.user_id}}</div>
        </div>
        <div class="detail-line" :class="{mb20: info.order_status * 1 !== 3}">
          <div class="label">收货地址</div>
          <div class="content">{{info.province_name}}{{info.city_name}}{{info.district_name}}{{info.address}}</div>
        </div>
        <template v-if="info.order_status * 1 === 3">
          <div class="detail-line">
            <div class="label">快递公司</div>
            <div class="content">{{info.shipping_name}}</div>
          </div>
          <div class="detail-line mb20">
            <div class="label">快递单号</div>
            <div class="content">{{info.shipping_sn}}</div>
          </div>
        </template>

        <div class="detail-line">
          <div class="label">订单编号</div>
          <div class="content">{{info.order_sn}}</div>
        </div>
        <div class="detail-line">
          <div class="label">下单时间</div>
          <div class="content">{{$Tool.FormatTime(info.add_time * 1000)}}</div>
        </div>
        <div class="detail-line">
          <div class="label">支付时间</div>
          <div class="content">{{$Tool.FormatTime(info.pay_time * 1000)}}</div>
        </div>
        <div class="detail-line">
          <div class="label">优惠金额</div>
          <div class="content">{{info.coupon_amount}}</div>
        </div>
        <div class="detail-line">
          <div class="label">实付金额</div>
          <div class="content">{{info.order_amount}}</div>
        </div>
      </div>
      <div class="side-box">
        <div class="side-sticky">
          <SideNav />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SideNav from '../../components/SideNav'
  export default {
    data () {
      return {
        info: {}
      }
    },
    activated () {
      this.orderDetails()
    },
    methods: {
      async orderDetails () {
        let { status, data } = await this.$Get('Common.orderDetails', {
          order_sn: this.$route.query.sn
        })
        if (status) {
          this.info = data
        }
      }
    },
    computed: {
      order_status () {
        let status = this.info.order_status * 1 || 1
        let text = ''
        switch (status) {
        case 1:
          text = '待支付'
          break
        case 2:
          text = '已购买'
          break
        case 3:
          text = '已发货'
          break
        }
        return text
      }
    },
    components: {
      SideNav
    }
  }
</script>

<style lang="less" scoped>
  .recent {
    min-width: 1200px;
    padding: 0 0 90px 0;
    .flex-ccc();
    .mb20 {
      margin-bottom: 20px;
    }
    .mb40 {
      margin-bottom: 40px;
    }
    .block {
      width: 1200px;
      min-height: 658px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      box-sizing: border-box;
      padding: 30px;
      .detail-head {
        margin-bottom: 42px;
        .flex();
        position: relative;
        img {
          width: 400px;
          height: 300px;
          border-radius: 10px;
          margin-right: 40px;
        }
        .detail-head-main {
          .flex-1();
          height: 100%;
          .detail-head-title {
            .flex-1();
            font-size: 30px;
            font-weight: bold;
            line-height: 50px;
            color: #333333;
            .line-clamp();
          }
          .detail-head-status {
            font-size: 30px;
            font-weight: bold;
            line-height: 50px;
            color: #368DFF;
          }
          .detail-head-price {
            font-size: 24px;
            line-height: 40px;
            color: #CA0000;
            position: absolute;
            bottom: 0;
            span {
              font-size: 30px;
            }
          }
        }
      }
      .detail-line {
        .flex-c();
        .label {
          font-size: 24px;
          line-height: 50px;
          color: #888;
          margin-right: 10px;
          width: 110px;
        }
        .content {
          font-size: 24px;
          line-height: 50px;
          color: #333;
        }
      }
    }
    .box {
      padding-left: 170px;
      transform: translateX(-85px);
      position: relative;
    }
    .side-box {
      position: absolute;
      width: 140px;
      height: 100%;
      top: 0;
      left: 0;
      .side-sticky {
        position: sticky;
        top: 164px;
      }
    }
  }
</style>

<template>
  <div class="page">
    <TiliteNav />
    <div class="box">
      <div class="box-title">{{topic_name}}</div>
      <TopToolCopy
        :total="paperList.length"
        :now_index="now_index"
        :progress="progress"
        v-bind="paperList[now_index]"
        :card_show.sync="card_show"
        :m="m"
        :s="s"
        :topic_type="active_item.topic_type"
      />

      <div>
        <!-- topic_type  1 选择题 2填空题 3阅读题 4作文 5听力 -->

        <!-- 1 选择题 弃用 -->
        <template v-if="active_item.topic_type == 1">
          <MultipleChoice
            v-bind="active_item"
            v-model="paperList[now_index].topicDetails.backList"
            :options="paperList[now_index].topicDetails.backList"
            :qInidex="now_index"
            :key="`multipleChoice${active_item.id}`"
            @onAnswer="onAnswerMultipleChoice($event, now_index, active_item.id)"
          />
        </template>

        <!-- 2 填空题 -->
        <template v-if="active_item.topic_type == 2">
          <CompletionCopy
            v-bind="active_item"
            :backList="paperList[now_index].topicDetails.backList"
            @onAnswer="onAnswerFillBlank($event, now_index, active_item.id)"
          />
        </template>

        <!-- 3 阅读题 -->
        <template v-if="active_item.topic_type == 3">
          <ReadCopy v-bind="active_item" :key="`read${active_item.topic_id}`" />
          <ChoiceCopy
            v-for="(q_item, q_index) in active_item.readList"
            v-model="paperList[now_index].topicDetails.backList[q_index].options"
            v-bind="q_item"
            :options="paperList[now_index].topicDetails.backList[q_index].options"
            :qInidex="q_index"
            :key="`choice${q_item.id + q_index}`"
            @onAnswer="
              onAnswerReadingQuestions($event, now_index, active_item.id)
            "
          />
        </template>

        <!-- 4 写作题 -->
        <template v-if="active_item.topic_type == 4">
          <WritingCopy
            v-bind="active_item"
            :text="paperList[now_index].topicDetails.backList.topicDetails"
            :imgArr="paperList[now_index].topicDetails.backList.topicDetailsImg"
            :key="`writing${active_item.id}`"
            @onAnswer="onAnswerComposition($event, now_index, active_item.id)"
          />
        </template>

        <!-- 5 听力题 -->
        <template v-if="active_item.topic_type == 5">
          <HearingCopy
            :url="active_item.question_content"
            :key="`hearing${active_item.id}`"
          />
          <ChoiceCopy
            v-for="(q_item, q_index) in active_item.readList"
            v-model="paperList[now_index].topicDetails.backList[q_index].options"
            v-bind="q_item"
            :qInidex="q_index"
            :key="`choice${q_item.id + q_index}`"
            :options="paperList[now_index].topicDetails.backList[q_index].options"
            @onAnswer="onAnswerHearing($event, now_index, active_item.id)"
          />
        </template>

        <!-- 选择题（多题） -->
        <template v-if="active_item.topic_type == 6">
          <!-- <HearingCopy :url="active_item.question_content" :key="`choice${active_item.id}`" /> -->
          <ChoiceCopy
            v-for="(q_item, q_index) in active_item.readList"
            v-model="paperList[now_index].topicDetails.backList[q_index].options"
            v-bind="q_item"
            :qInidex="q_index"
            :key="`choice${q_item.id + q_index}`"
            :options="paperList[now_index].topicDetails.backList[q_index].options"
            @onAnswer="onAnswerChoice($event, now_index, active_item.id)"
          />
        </template>
      </div>

      <!-- 答题卡 -->
      <van-popup
        v-model="card_show"
        style="border-radius: .16rem .16rem 0 0"
      >
        <CardCopy
          :paperList="paperList"
          :now_index.sync="now_index"
          :card_show.sync="card_show"
          @onSubmit="handleSubmit"
        />
      </van-popup>

      <div class="box-btn" @click="Next">{{ btn_value }}</div>
    </div>
  </div>
</template>

<script>
  import TopToolCopy from '@/components/answer/top-tool-copy'
  import HearingCopy from '@/components/answer/hearing-copy'
  import ChoiceCopy from '@/components/answer/choice-copy'
  import MultipleChoice from '@/components/answer/multiple-choice'
  import CompletionCopy from '@/components/answer/completion-copy'
  import ReadCopy from '@/components/answer/read-copy'
  import WritingCopy from '@/components/answer/writing-copy'
  import CardCopy from '@/components/answer/card-copy'
  export default {
    data () {
      return {
        // 当前题目下标
        now_index: 0,
        // 答题卡
        card_show: false,
        goods_id: '',
        paperList: [],
        activeIndex: 0,
        dataList: [],
        answer: [],
        orderPaperId: '',
        timer: null,
        m: 0,
        s: 0
      }
    },
    methods: {
      getInt (str) {
        return Number.parseInt(str)
      },
      Next () {
        if (this.now_index < this.paperList.length - 1) {
          this.now_index++
          scrollTo(0, 0)
        } else {
          this.handleSubmit()
        }
      },
      // 阅读
      onAnswerReadingQuestions (e, index, topicId) {
        this.paperList[index].topicDetails = Object.assign(
          {},
          this.paperList[index].topicDetails
        )
      },
      // 听力
      onAnswerHearing (e, index, topicId) {
        this.paperList[index].topicDetails = Object.assign(
          {},
          this.paperList[index].topicDetails
        )
      },
      // 作文
      onAnswerComposition (e, index, topicId) {
        this.paperList[index].topicDetails.backList.topicDetails = e.topicDetails
        this.paperList[index].topicDetails.backList.topicDetailsImg =
          e.topicDetailsImg
        this.paperList[index].topicDetails = Object.assign(
          {},
          this.paperList[index].topicDetails
        )
      },
      // 选择题（弃用）
      onAnswerMultipleChoice (e, index, topicId) {
        this.paperList[index].topicDetails = Object.assign(
          {},
          this.paperList[index].topicDetails
        )
      },
      // 选择题（多选）
      onAnswerChoice (e, index, topicId) {
        this.paperList[index].topicDetails = Object.assign(
          {},
          this.paperList[index].topicDetails
        )
      },
      // 填空
      onAnswerFillBlank (e, index, topicId) {
        this.paperList[index].topicDetails.backList[e.index].content = e.content
        this.paperList[index].topicDetails = Object.assign(
          {},
          this.paperList[index].topicDetails
        )
      },
      // 提交
      handleSubmit () {
        // topic_type  1 选择题（弃用） 2填空题 3阅读题 4作文 5听力 6 选择题（多题）
        // 答案结果
        this.answer = this.paperList.map((item, index) => {
          let topicType = this.getInt(item.topic_type)
          let arr = {
            topic_id: item.topic_id,
            topic_type: item.topic_type
          }
          if (topicType === 1) {
            arr.options = item.topicDetails.backList
          } else if (topicType === 2) {
            arr.selectTopicAnswer = item.topicDetails.backList
          } else if (topicType === 3) {
            arr.readList = item.topicDetails.backList
          } else if (topicType === 4) {
            arr.topicDetailsImg = item.topicDetails.backList.topicDetailsImg
            arr.topicDetails = item.topicDetails.backList.topicDetails
          } else if (topicType === 5) {
            arr.readList = item.topicDetails.backList
          } else if (topicType === 6) {
            arr.readList = item.topicDetails.backList
          }
          return arr
        })
        this.card_show = false
        // 创建试卷
        this.submitPaper()
      },
      // 创建试卷
      createPaper () {
        this.$Post('Common.createPaper', { goods_id: this.goods_id })
          .then(res => {
            if (res.status) {
              this.orderPaperId = res.data.order_paper_id
              // 获取题目
              this.getPaperDetails()
            }
          })
          .catch(err => {
            this.$ShowToast(err.msg)
          })
      },
      // 获取习题
      getPaperDetails () {
        this.$Get('Common.getPaperDetails', { goods_id: this.goods_id }).then(
          res => {
            if (res.status) {
              this.paperList = JSON.parse(JSON.stringify(res.data))
              // this.backList()
              this.paperList.forEach((item, index) => {
                let topicType = this.getInt(item.topic_type)
                let topicDetails = this.paperList[index].topicDetails
                // topic_type  1 选择题（单题-已弃用） 2填空题 3阅读题 4作文 5听力 6 选择题（多题）
                if (topicType === 1) {
                  // 选择题
                  this.paperList[index].topicDetails.backList = ''
                } else if (topicType === 2) {
                  // 填空题
                  topicDetails.backList = topicDetails.selectTopicAnswer.map(
                    (c, cindex) => {
                      return {
                        options: c.options,
                        content: ''
                      }
                    }
                  )
                } else if (topicType === 3) {
                  // 阅读题
                  topicDetails.backList = topicDetails.readList.map(
                    (c, cindex) => {
                      return {
                        id: c.id,
                        options: ''
                      }
                    }
                  )
                } else if (topicType === 4) {
                  // 写作
                  this.paperList[index].topicDetails.backList = {
                    topicDetails: '',
                    topicDetailsImg: []
                  }
                } else if (topicType === 5) {
                  // 听力
                  topicDetails.backList = topicDetails.readList.map(
                    (c, cindex) => {
                      return {
                        id: c.id,
                        options: ''
                      }
                    }
                  )
                } else if (topicType === 6) {
                  // 选择题（多题）
                  topicDetails.backList = topicDetails.readList.map(
                    (c, cindex) => {
                      return {
                        id: c.id,
                        options: ''
                      }
                    }
                  )
                }
              })
              // 开始计时
              this.Timing()
            }
          }
        )
      },
      // 计时
      Timing () {
        this.timer && clearInterval(this.timer)
        this.m = 0
        this.s = 0
        this.timer = setInterval(() => {
          this.s++
          // console.log('s', this.s)
          if (this.s >= 60) {
            this.s = 0
            this.m += 1
          }
        }, 1000)
      },
      // 提交试卷
      submitPaper () {
        this.$Post('Common.submitPaper', {
          order_paper_id: this.orderPaperId,
          answer: JSON.stringify(this.answer)
        }).then(res => {
          if (res.status) {
            clearInterval(this.timer)
            this.$ShowToast('提交成功')
            this.$nav('Campus.ResultDetails', { order_paper_id: this.orderPaperId, goods_id: this.goods_id })
          }
        }).catch(err => {
          this.$ShowToast(err.msg)
        })
      },
      // 获取做题进度
      feachList (type) {
        if (!this.paperList) return []
        let arr = []
        let completed = 0 // 已完成
        let count = 0 // 总题数
        this.paperList.forEach((element, index) => {
          // topic_type  1 选择题 2填空题 3阅读题 4作文 5听力 6选择题（多题）
          let n = Number.parseInt(element.topic_type)
          let backList = element.topicDetails.backList
          if (n === 1) {
            count += 1
            completed = backList !== '' ? completed + 1 : completed
            arr.push({
              index,
              topic_type: n,
              serialNumber: count,
              bool: backList !== ''
            })
          }
          if (n === 2) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.content !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.content !== '',
                topic_type: n
              })
            })
          }
          if (n === 3) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.options !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.options !== '',
                topic_type: n
              })
            })
          }
          if (n === 4) {
            count += 1
            completed =
              backList.topicDetails !== '' || backList.topicDetailsImg.length
                ? completed + 1
                : completed
            arr.push({
              index,
              serialNumber: count,
              bool:
                backList.topicDetails !== '' || backList.topicDetailsImg.length,
              topic_type: n
            })
          }
          if (n === 5) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.options !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.options !== '',
                topic_type: n
              })
            })
          }
          if (n === 6) {
            backList.forEach((child, childIndex) => {
              count += 1
              completed = child.options !== '' ? completed + 1 : completed
              arr.push({
                index,
                serialNumber: count,
                bool: child.options !== '',
                topic_type: n
              })
            })
          }
        })
        if (type === 1) {
          return {
            completed,
            count
          }
        } else {
          // 返回答题卡列表
          return arr
        }
      }
    },
    computed: {
      active_item () {
        if (this.paperList.length) {
          return this.paperList[this.now_index].topicDetails
        }
        return []
      },
      topic_name () {
        if (this.paperList.length) {
          return this.paperList[this.now_index].topic_name
        }
        return ''
      },
      btn_value () {
        return this.now_index === this.paperList.length - 1
          ? '提交答案'
          : '下一题'
      },
      // 答题进度
      progress () {
        return this.feachList(1)
      }
    },
    components: {
      TopToolCopy,
      HearingCopy,
      ChoiceCopy,
      MultipleChoice,
      CompletionCopy,
      ReadCopy,
      CardCopy,
      WritingCopy
    },
    activated () {
      this.now_index = 0
      this.card_show = false
      this.goods_id = ''
      this.paperList = []
      this.activeIndex = 0
      this.dataList = []
      this.answer = []
      this.orderPaperId = ''
      this.timer && clearInterval(this.timer)
      this.m = 0
      this.s = 0
      this.goods_id = this.$route.query.id
      this.createPaper()
    }
  }
</script>

<style lang="less" scoped>
.page {
  margin: auto;
  margin-bottom: 90px;
  #myaudio {
    position: absolute;
    display: none;
  }
  .popup-box {
    height: 339px;
    .subject-box {
      margin-right: 36px;
      .subject {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
      }
      .minute {
        .flex-cc();
        width: 48px;
        height: 48px;
        background: #FFF3F3;
        border: 1px solid #CA0000;
        border-radius: 50%;
        color: #CA0000;
        font-size: 18px;
      }
    }
  }
  .box {
    width: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px 100px;
    .play-label {
      font-size: 16px;
      width: 100px;
      line-height: 21px;
      color: #333333;
    }
    .m40 {
      margin: 40px 0;
    }
    .play-box {
      width: 600px;
      height: 8px;
      background: #F0F0F0;
      border-radius: 5px;
      position: relative;
      margin-right: 30px;
      .play-active-box {
        position: absolute;
        left: 0;
        top: 0;
        height: 8px;
        z-index: 9;
        border-radius: 5px;
        background: #CA0000;
      }
      img {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        z-index: 10;
      }
    }
    /deep/ .el-upload--picture-card {
      width: 110px;
      height: 110px;
      position: relative;
    }
    /deep/ .el-icon-plus:before {
      content: '';
      width: 28px;
      height: 28px;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('/static/images/icon/upload.png');
    }
    /deep/ .el-icon-plus:after {
      content: '上传图片';
      position: absolute;
      width: 100px;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      line-height: 20px;
      color: #C4C4C4;
    }
    /deep/ .el-textarea, /deep/ .el-textarea textarea {
      width: 100%;
      height: 170px;
      background: #F9F9F9;
      opacity: 1;
      border-radius: 10px;
      margin-bottom: 20px;
    }
    /deep/ .el-upload-list li {
      width: 110px;
      height: 110px;
      position: relative;
    }
    .contact-toast {
      font-size: 12px;
      line-height: 17px;
      color: #C4C4C4;
      margin-top: 8px;
    }
    .box-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: #333333;
      margin-bottom: 40px;
      text-align: center;
    }
    .box-small-title {
      margin-bottom: 16px;
      height: 24px;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
    }
    .box-topic {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      margin-bottom: 12px;
      span {
        color: #CA0000;
      }
    }
    .box-topic-title {
      text-align: center;
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      margin-bottom: 10px;
      &.title2 {
        font-weight: bold;
        font-size: 16px;
        color: #333;
        text-align: left;
      }
    }
    .box-topic-txt {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      margin-bottom: 40px;
      white-space: pre-wrap;
    }
    .box-topic-line {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-bottom: 12px;
      cursor: pointer;
      padding-left: 24px;
      &:hover, &.active {
        color: #CA0000;
      }
    }
    .box-btn {
      width: 180px;
      height: 50px;
      background: #CA0000;
      box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
      border-radius: 25px;
      margin: 60px auto 0;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
      .flex-cc();
    }
    .box-head {
      .flex-bc();
      margin-bottom: 48px;
      .box-head-item {
        .flex-1();
        font-size: 18px;
        font-weight: bold;
        line-height: 32px;
        color: #333333;
        box-sizing: border-box;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          color: #CA0000;
        }
      }
    }
  }
  .btn {
    .w(6.2rem);
    .h(0.8rem);
    .br(0.4rem);
    background-image: linear-gradient(-90deg, #CA0000 0%, #CA0000 100%),
      linear-gradient(#c4c4c4, #c4c4c4);
    background-blend-mode: normal, normal;
    box-shadow: 0rem 0.06rem 0.14rem 0rem rgba(0, 156, 237, 0.24);
    .c(#fff);
    .fz(0.32rem);
    display: flex;
    justify-content: center;
    align-items: center;
    .m(0 auto);
  }
}
</style>

<template>
  <div class="cutomnav">
    <div v-for="(item, index) in list" :key="index" :class="{active: value === index}" class="nav-item" @click="clickNav(index)">{{item.name}}</div>
  </div>
</template>

<script>
  export default {
    props: {
      value: null,
      list: [Array]
    },
    methods: {
      clickNav (i) {
        this.$emit('input', i)
      }
    }
  }
</script>

<style lang="less" scoped>
  .cutomnav {
    .flex-ccc();
    border-radius: 10px;
    overflow: hidden;
    .nav-item {
      .flex-cc();
      width: 140px;
      height: 94px;
      background: #FFFFFF;
      cursor: pointer;
      white-space: nowrap;
      font-size: 16px;
      position: relative;
      color: #888;
      &.active {
        color: #CA0000;
        font-weight: bold;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 4px;
          height: 24px;
          background: linear-gradient(180deg, #CA0000 0%, rgba(202, 0, 0, 0) 100%);
          border-radius: 2px;
        }
      }
    }
  }
</style>

<template>
    <div class="mycourse">
        <div class="page box">
            <TiliteNav />
            <div class="mycourse-box">
                <div class="mycourse-date-box">
                    <div class="mycourse-date-item">
                        <div class="mycourse-date-title">今日学习</div>
                        <div class="flex-c">
                            <div class="mycourse-date-block">
                                <span>{{user_order.today_time}}</span>分钟课程
                            </div>
                            <div class="mycourse-date-block">
                                <span>{{user_order.paper_today_num}}</span>套练习
                            </div>
                        </div>
                    </div>
                    <div class="mycourse-date-item">
                        <div class="mycourse-date-title">累计学习</div>
                        <div class="flex-c">
                            <div class="mycourse-date-block">
                                <span>{{user_order.total_time}}</span>分钟课程
                            </div>
                            <div class="mycourse-date-block">
                                <span>{{user_order.paper_total_num}}</span>套练习
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb40">
                <Tab v-model="navIndex" :list="navList" />
            </div>
            <div class="mb40">
                <Tab v-model="tab" :list="tab_list" />
            </div>
            <div class="block">
                <CoureList :list="list" type="my" />
            </div>
            <div class="flex-cc">
                <Paging v-model="page" :total="total_pages" :items="total_items" :limit="8" />
            </div>
            <div class="side-box">
                <div class="side-sticky">
                    <SideNav />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CoureList from '../../components/CourseList'
    import SideNav from '../../components/SideNav'
    import {
        mapState
    } from 'vuex'
    import {
        ListPage
    } from '@/utils/Mixin'
    export default {
        mixins: [ListPage],
        data() {
            return {
                navIndex: 0,
                navList: [{
                        name: '默认',
                        type: 'package_type',
                        url: 'Course.orderRecently'
                    },
                    {
                        name: '后台分配套餐',
                        type: 'alone_paper_type',
                        url: 'Course.adminDistributionList'
                    }
                ],
                tab: 0,
                tab_list: [{
                        name: '课程',
                        type: 'package_type'
                    },
                    {
                        name: '习题',
                        type: 'alone_paper_type'
                    }
                ]
            }
        },
        methods: {
            async GetList() {
                let url = this.navList[this.navIndex].url
                let {
                    data,
                    status
                } = await this.$Get(url, {
                    goods_type: this.tab_list[this.tab]['type'],
                    page: this.page,
                    limit: 8
                })
                if (status) {
                    this.HandleRes(data)
                }
            }
        },
        watch: {
            navIndex() {
                this.RefreshList()
            },
            tab() {
                this.RefreshList()
            }
        },
        components: {
            SideNav,
            CoureList
        },
        computed: {
            ...mapState('Common', ['user_order'])
        }
    }
</script>

<style lang="less" scoped>
    .mycourse {
        min-width: 1200px;
        padding: 0 0 90px 0;
        .flex-ccc();

        .mb40 {
            margin-bottom: 40px;
        }

        .block {
            min-height: 658px;
            padding-bottom: 40px;
        }

        .box {
            padding-left: 170px;
            transform: translateX(-85px);
            position: relative;
        }

        .mycourse-box {
            width: 1200px;
            .flex-cc();

            .mycourse-date-box {
                width: 1200px;
                height: 146px;
                background: #FFFFFF;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
                border-radius: 10px;
                margin-bottom: 60px;
                .flex-c();

                .mycourse-date-item {
                    .flex-1();

                    .mycourse-date-title {
                        height: 31px;
                        font-size: 24px;
                        font-weight: bold;
                        line-height: 31px;
                        color: #333333;
                        text-align: center;
                        margin-bottom: 15px;
                    }

                    .mycourse-date-block {
                        .flex-1();
                        .flex-cc();
                        height: 37px;
                        font-size: 20px;
                        line-height: 26px;
                        color: #333333;

                        span {
                            font-size: 28px;
                            color: #CA0000;
                        }
                    }
                }
            }
        }

        .side-box {
            position: absolute;
            width: 140px;
            height: 100%;
            top: 0;
            left: 0;

            .side-sticky {
                position: sticky;
                top: 164px;
            }
        }
    }
</style>

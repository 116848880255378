<template>
  <div class="flex-c school-enterprise" ref="enterprise">
    <div class="school-enterprise-item" @click="goDetail(item)" v-for="(item, index) in list" :key="index" :class="{animation: animation && isanimation}">
      <img :src="item.cover_url" />
      <div class="school-enterprise-main">
        <div class="school-enterprise-title">{{ item.title }}</div>
        <div class="school-enterprise-content">{{ item.about }}</div>
        <div class="flex-cc look">
          <span>查看详情</span>
          <div class="img"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'school-enterprise',
    props: {
      animation: [Boolean],
      scrollToTop: {
        type: Function,
        default: () => {}
      },
      list: [Array]
    },
    watch: {
      animation (to) {
        if (to) {
          setTimeout(() => {
            this.isanimation = false
          }, 2100)
        }
      }
    },
    methods: {
      goDetail (item) {
        this.$nav('Common.IntroduceDetail', { id: item.id })
      }
    },
    data () {
      return {
        isanimation: true
      }
    },
    mounted () {
      window.addEventListener('scroll', () => { this.scrollToTop(this, 'enterprise', 'schoolAnimation') }, true)
      if (this.animation) {
        setTimeout(() => {
          this.isanimation = false
        }, 2100)
      }
    }
  }
</script>

<style lang="less" scoped>
  .school-enterprise {
    width: 1200px;
    flex-wrap: wrap;
    .school-enterprise-item {
      width: 270px;
      height: 361px;
      background: #fff;
      border-radius: 10px;
      margin-right: 40px;
      overflow: hidden;
      position: relative;
      transition: all .2s;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 48px;
      }
      .school-enterprise-main {
        padding: 20px 18px 18px;
        .school-enterprise-title {
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          margin-bottom: 12px;
          .line-clamp-multi(2);
        }
        .school-enterprise-content {
          font-size: 14px;
          line-height: 24px;
          color: #B2B2B2;
          .line-clamp-multi(2);
          margin-bottom: 42px;
          span {
            color: #888;
          }
        }
      }
      .look {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 19px;
        color: #CA0000;
        font-size: 14px;
        .img {
          width: 14px;
          height: 14px;
          margin-left: 5px;
          background: url('/static/images/icon/right-three.png');
        }
      }
      img {
        width: 270px;
        height: 160px;
      }
      &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      }
    }
  }
</style>

<template>
  <div class="Relaxed-container">
    <div class="item">
      <div class="img-box">
        <img class="cover" src="/static/images/home/Relaxed/1.png" alt="">
      </div>
      <div class="text-box">
        <div class="icon-warp">
          <img class="icon" src="/static/images/home/Relaxed/3.png" alt="">
        </div>
        <div class="name">为什么选择高考日语？</div>
        <div class="text-row-warp">
          <div class="row"><div class="row-line">词汇量少，语法简单，更<span class="active">容易掌握</span></div></div>
          <div class="row"><div class="row-line">高考日语的考生较少，更<span class="active">容易拔尖</span></div></div>
          <div class="row"><div class="row-line">日语成绩替代英语成绩，更<span class="active">容易提分</span></div></div>
        </div>
        <div class="light-text">Easy Learning, Easy Enhancing</div>
      </div>
    </div>

     <div class="item">
      <div class="text-box">
        <div class="icon-warp">
          <img class="icon" src="/static/images/home/Relaxed/4.png" alt="">
        </div>
        <div class="name">我们有什么优势？</div>
        <div class="text-row-warp">
          <div class="row"><div class="row-line">我们旗下拥有<span class="active">20余所</span>分校，线下超过<span class="active">1.6万</span>名考生，教学口碑优质</div></div>
          <div class="row"><div class="row-line">我们深耕日语教育<span class="active">18年</span>，教师从业<span class="active">5年</span>以上，教学经验扎实</div></div>
          <div class="row"><div class="row-line">我们过往考生平均提升<span class="active">50-100分</span>，教学效果显著</div></div>
          <div class="row"><div class="row-line">我们作为<span class="active">广东省高考研究会常务理事</span>，身肩责任与使命，与全国</br> <span class="line">一流教学水平对齐</span></div></div>
        </div>
        <div class="light-text">High Quality, High Standard</div>
      </div>
          <div class="img-box">
        <img class="cover" src="/static/images/home/Relaxed/2.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Relaxed',
    props: {

    },
    watch: {

    },
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }
</script>

<style lang="less" scoped>
.Relaxed-container {
  position: relative;
  box-sizing: border-box;
  padding: 0 50px;
  width: 1300px;
  overflow: hidden;
  // background-color: red;
  // background-image:url('/static/images/home/Relaxed/5.png');
  // background-size: contain;
  // background-repeat: no-repeat;

  .item {
    position: relative;
    display: flex;

    .img-box {
      width: 668px;
      height: 546px;
      .cover {
        display: block;
          width: 100%;
          height: 100%;
      }
    }
    .text-box {
      flex: 1;
      padding-top: 105px;
      .icon-warp {
        margin-bottom: 36px;
        .icon {
          width: 48px;
          height: 48px;
        }
      }

      .name {
        margin-bottom: 25px;
        line-height: 30px;
        font-size: 32px;
        font-weight: bold;
        color: #333333;
      }

      .text-row-warp {
        margin-bottom: 62px;
        .row {
          position: relative;
          box-sizing: border-box;
          margin-bottom: 8px;
          padding-left: 20px;
          min-height: 40px;
          line-height: 39px;
          font-size: 22px;
          font-weight: 400;
          color: #333333;

          &::before {
            position: absolute;
            top: 12px;
            left: 0;
            content: " ";
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-left: 10px solid #ca0000;
            border-bottom: 7px solid transparent;
          }

          .line {
              position: relative;
              display: inline-block;
              &::after {
                position: absolute;
                bottom: 5px;
                left: 0;
                content: " ";
                width: 100%;
                height: 9px;
                background: #CA0000;
                opacity: 0.1;
              }
          }

          .row-line {
            // width: 900px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 20px;
            white-space: nowrap;
            &::after {
              position: absolute;
              bottom: 5px;
              left: 0;
              content: " ";
              width: 100%;
              height: 9px;
              background: #CA0000;
              opacity: 0.1;
            }

            .active {
              color: #cb0707;
              font-weight: bold;
            }
          }
        }
      }

      .light-text {
        line-height: 45px;
        font-size: 28px;
        font-family: Arial;
        font-weight: 400;
        color: #E5E5E5;
      }
    }
  }
}
</style>

<template>
    <div class="flex-c course">
        <div class="course-item" v-for="(item, index) in list" :key="index" :class="{animation: animation}" @click="goNav(item)">
            <img :src="item.goods_thumb" />
            <div class="course-main">
                <div class="course-title">{{item.title}}</div>
            </div>
             <div class="is-last-goods-warp" v-if="showIsLastBtn">
                <div class="is-last-goods" :class="{'active':item.is_last_goods!=undifine&&item.is_last_goods==1}">学习</div>
             </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'course',
        props: {
            animation: [Boolean],
            list: [Array],
            myPurchase: {
                default: 0
            },
            showIsLastBtn:{
              default:false
            }
        },
        methods: {
            goNav(item) {
                console.log('myPurchase', this.myPurchase)

                let URL = ''
                let query = {
                    id: item.goods_id,
                    sid: this.$route.query.id
                }
                switch (item.goods_type) {
                    case 'video_type':
                        URL = 'Campus.CheckCourseDetails'
                        break
                    case 'package_type':
                        URL = 'Campus.SeriesCourses'
                        break
                    case 'alone_paper_type':
                        URL = 'Campus.OnlineProblemSets'
                        break
                    case 'subtitles_type':
                        if(this.myPurchase*1==1) {
                            URL = 'Campus.SubtitlesDetail'
                        }else {
                            URL = 'Campus.SeriesCoursesDetails'
                        }
                        break
                    default:
                        URL = 'Campus.SeriesCoursesDetails'
                        break
                }
                this.$nav(URL, query)
                // this.$nav('Campus.SeriesCoursesDetails', { id: item.goods_id, sid: this.$route.query.id })
            }
        }
    }
</script>

<style lang="less" scoped>
    .course {
        width: 1200px;
        .flex-wrap();

        .course-item {
            width: 270px;
            // height: 226px;
            background: #fff;
            border-radius: 10px;
            margin-right: 40px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            &:nth-child(4n) {
                margin-right: 0;
            }

            &:nth-child(n+5) {
                margin-top: 40px;
            }

            .course-main {
                padding: 16px 16px 25px;

                .course-title {
                    height: 26px;
                    font-size: 20px;
                    line-height: 25px;
                    color: #333333;
                    .line-clamp-multi(1);
                }
            }

            img {
                width: 270px;
                height: 152px;
            }

            &:hover {
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
            }
          .is-last-goods-warp {
            display: flex;
            justify-content: flex-end;
            padding: 20px;
            .is-last-goods {
              width: 88px;
              height: 40px;
              line-height: 40px;
              background: #FFDEDE;
              opacity: 1;
              border-radius: 22px;
              font-size: 16px;
              font-weight: 400;
              color: #CA0000;
              text-align: center;
              &.active {
                background: #CA0000;
                box-shadow: 0px 6px 14px rgba(202, 0, 0, 0.2);
                color: #ffffff;
              }
            }
          }

        }
    }
</style>

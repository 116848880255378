<template>
  <div class="pages">
    <div class="page box">
      <TiliteNav />
      <CoureList :list="list" />
      <div class="detail">
        <TiliteNav>
          <div class="title">课程详情</div>
        </TiliteNav>
        <div class="look-detail-content" v-html="goods.content"></div>
      </div>
      <div class="pay-btn" v-if="goods.my_purchase * 1 === 0" @click="handlePay">购买本系列课程</div>
      <div class="side-box">
        <div class="side-sticky">
          <SideNav />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SideNav from '../../components/SideNav'
  import CoureList from './components/CourseList/series-item'
  export default {
    data () {
      return {
        page: 1,
        total_pages: 0,
        total_items: 0,
        list: [],
        goods: {},
        isNeedShipping: null,
        recommend: [],
        package: []
      }
    },
    activated () {
      this.goodsDetails()
    },
    watch: {
      '$route.query.id' (to) {
        if (to) {
          this.goodsDetails()
        }
      }
    },
    components: {
      CoureList,
      SideNav
    },
    methods: {
      async goodsDetails () {
        let { status, data } = await this.$Get('Course.packageDetails', {
          order_sn: this.$route.query.order_sn,
          goods_package_id:this.$route.query.goods_package_id||''
        })
        if (status) {
          this.page = 1
          this.goods = data
          this.list = data.items
        }
      },
      handlePay () {
        this.$Bus.$emit('Pay', { id: this.$route.query.id })
      }
    }
  }
</script>

<style lang="less" scoped>
.pages {
  min-width: 1200px;
  padding: 0 0 90px 0;
  .flex-ccc();
  .box {
    min-height: 638px;
    padding-left: 170px;
    transform: translateX(-85px);
    position: relative;
  }
  .side-box {
    position: absolute;
    width: 140px;
    height: 100%;
    top: 0;
    left: 0;
    .side-sticky {
      position: sticky;
      top: 164px;
    }
  }
}
  .page {
    margin: auto;
    margin-bottom: 90px;
    position: relative;
    .recommend {
      width: 1200px;
      height: 84px;
      background: #FFF3F3;
      border-radius: 10px;
      margin-bottom: 40px;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 32px;
      color: #CA0000;
      padding: 0 40px;
      .flex-bc();
    }
    .pay-btn {
      position: absolute;
      top: 23px;
      right: 0;
      background: #CA0000;
      color: #fff;
      box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
      width: 180px;
      height: 50px;
      font-size: 18px;
      border-radius: 25px;
      position: absolute;
      cursor: pointer;
      .flex-cc();
    }
    .block {
      margin: 60px auto;
    }
    .detail {
      width: 1200px;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      padding: 0 40px 40px;
      /deep/ .title-nav {
        &::before {
          height: 28px;
        }
      }
      .title {
        height: 32px;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: #333333;
      }
      .look-detail-content {
        font-size: 16px;
        line-height: 32px;
        color: #888888;
        margin-bottom: 20px;
        margin-top: -20px;
        img {
          width: 100%;
        }
      }
    }
  }
</style>

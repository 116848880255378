<template>
  <div class="page">
    <TiliteNav />
    <div class="box">
      <div class="report-title">{{goods_name}}
        <!-- <div class="score">({{total_score}}分)</div> -->
      </div>

      <div class="flex-bc mb30">
        <div class="time-score">
          <span class="label">用时</span>
          <div class="time">
            <template v-if="day"><span class="score">{{day|formatNumber}}</span>天</template>
            <template v-if="hour"><span class="score">{{hour|formatNumber}}</span>时</template>
            <span class="score">{{min|formatNumber}}</span>分<span class="score">{{sec|formatNumber}}</span>秒
          </div>
        </div>

        <div class="score-right score">满分：{{total_score}}分</div>

        <div class="score-wrap flex">
          <div class="score-left">
            <span class="label">得分：</span>
            <span class="score"><i>{{user_score}}</i>/{{total_score}}分</span>
          </div>
        </div>
      </div>

      <!-- 细分到小题 -->
      <!--  topic_type  1 选择题（弃用） 2填空题 3阅读题 4作文 5听力 6选择题（多题） -->
      <div>
        <div class="answer-item" v-for="(item, index) in items" :key="`items-${index}`">
          <!-- 题目 -->
          <!-- <div class="label-title">{{index+1+'、'}}{{item.topic_type_name}}</div> -->
          <template v-if="item.topic_type==1">
            <!-- 选择题 -->
            <div class="answer-box">
              <div class="sub-title">{{item.question_name}}</div>
              <div class="answer-child">
                <span
                  class="answer-options"
                  :class="{'answer-status-1':item.answer_status=='1','answer-status-2':item.answer_status=='2'}"
                >
                  <span>{{item.user_options}}</span>

                  <!-- 把用户答题的内容输出 -->
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="anserItem.options==item.user_options">.{{anserItem.content}}</span>
                    </span>
                  </span>
                </span>
                <p class="correct-options" :class="{'answer-status-0':item.user_options===''}">
                  (正确答案：{{item.correct_options}}
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="anserItem.options==item.correct_options">.{{anserItem.content}}</span>
                    </span>
                  </span>
                  )
                </p>
              </div>
            </div>
          </template>
          <template v-if="item.topic_type==2">
            <!-- 填空题 -->
            <div class="answer-box">
              <div class="sub-title">{{item.correct_options_name.view_question_score}}</div>
              <div
                class="answer-child"
                v-for="(son,sonIndex) in item.user_options"
                :key="index+sonIndex"
              >
                <span
                  class="answer-options"
                  :class="{'answer-status-1':son.content===item.paper_details_topic_answer[sonIndex].content,'answer-status-2':son.content!==item.paper_details_topic_answer[sonIndex].content}"
                >{{son.content}}</span>
                <p
                  :class="{'answer-status-0':son.content===''||son.content!==item.paper_details_topic_answer[sonIndex].content}"
                >(正确答案：{{item.correct_options[sonIndex].content}})</p>
              </div>
            </div>
          </template>
          <template v-if="item.topic_type==3">
            <!-- 阅读题 -->
            <div class="answer-box">
              <div class="sub-title">{{item.question_name}}</div>
              <div class="answer-child">
                <span
                  class="answer-options"
                  :class="{'answer-status-1':item.answer_status=='1','answer-status-2':item.answer_status=='2'}"
                >
                  {{item.user_options}}
                  <!-- 把错误的选项输出 -->
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="anserItem.options==item.user_options">.{{anserItem.content}}</span>
                    </span>
                  </span>
                </span>
                <p class="correct-options" :class="{'answer-status-0':item.user_options===''}">
                  (正确答案：{{item.correct_options}}
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="anserItem.options==item.correct_options">.{{anserItem.content}}</span>
                    </span>
                  </span>
                  )
                </p>
              </div>
            </div>
          </template>
          <template v-if="item.topic_type==4">
            <!-- 作文 -->
            <div class="answer-box">
              <div class="sub-title">{{item.question_name}}</div>
              <!-- <div class="answer-child">{{item.user_options}}</div>
              <div class="answer-child">
                <img
                  class="composition-picture"
                  :src="img"
                  v-for="(img,imgIndex) in item.topic_details_img"
                  :key="index+imgIndex"
                />
              </div>-->
              <div>
                <div class="answer-child">范文：</div>
                <div class="essay" v-html="item.correct_options"></div>
              </div>
            </div>
          </template>
          <template v-if="item.topic_type==5">
            <!-- 听力 -->
            <div class="answer-box">
              <div class="sub-title">{{item.question_name}}</div>
              <div class="answer-child">
                <span
                  class="answer-options"
                  :class="{'answer-status-1':item.answer_status=='1','answer-status-2':item.answer_status=='2'}"
                >
                  {{item.user_options}}
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="anserItem.options==item.user_options">.{{anserItem.content}}</span>
                    </span>
                  </span>
                </span>
                <!-- 用户不答题，标红 -->
                <p :class="{'answer-status-0':item.user_options===''}">
                  (正确答案：
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="item.correct_options.indexOf(anserItem.options)>=0">{{anserItem.options}}.{{anserItem.content}} </span>
                    </span>
                  </span>
                  )
                </p>
              </div>
            </div>
          </template>

          <template v-if="item.topic_type==6">
            <!-- 选择题（多题） -->
            <div class="answer-box">
              <div class="sub-title">{{item.question_name}}</div>
              <div class="answer-child">
                <span
                  class="answer-options"
                  :class="{'answer-status-1':item.answer_status=='1','answer-status-2':item.answer_status=='2'}"
                >
                  {{item.user_options}}
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="anserItem.options==item.user_options">.{{anserItem.content}}</span>
                    </span>
                  </span>
                </span>
                <!-- 用户不答题，标红 -->
                <p class="correct-options" :class="{'answer-status-0':item.user_options===''}">
                  (正确答案：
                  <!-- {{item.correct_options}} -->
                  <span v-if="item.paper_details_topic_answer">
                    <span
                      v-for="(anserItem,anserIndex) in item.paper_details_topic_answer"
                      :key="anserIndex"
                    >
                      <span v-if="item.correct_options.indexOf(anserItem.options)>=0">{{anserItem.options}}.{{anserItem.content}}</span>
                    </span>
                  </span>
                  )
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="flex-cc">
        <div class="btn style-2" @click="$nav('back')">返回习题</div>
        <div class="btn style-1" @click="$nav('Campus.ProblemSetsDetails',{id: goods_id}, 'redirectTo')">再做一次</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        orderPaperId: '',
        goods_id: '',
        day: 0,
        hour: 0,
        min: 1,
        sec: 8,
        total_score: 0,
        user_score: 0,
        user_name: '',
        goods_name: '',
        list: [],
        items: []
      }
    },
    computed: {},
    activated () {
      this.orderPaperId = this.$route.query.order_paper_id
      this.goods_id = this.$route.query.goods_id
      this.getSubmitPaperDetails()
    },
    mounted () {},
    methods: {
      // 查成绩
      getSubmitPaperDetails () {
        this.$Post('Common.getSubmitPaperDetails', {
          order_paper_id: this.orderPaperId
        }).then(res => {
          if (res.status) {
            this.list = res.data.orderPaperDetailsRes
            this.total_score = res.data.orderPaperRes.total_score
            this.user_score = res.data.orderPaperRes.user_score
            this.goods_name = res.data.orderPaperRes.goods_name
            this.day = res.data.orderPaperRes.time_interval.day
            this.hour = res.data.orderPaperRes.time_interval.hour
            this.min = res.data.orderPaperRes.time_interval.min
            this.sec = res.data.orderPaperRes.time_interval.sec

            this.items = []

            this.list.forEach((item, index) => {
              let obj = {
                topic_name: item.topic_name,
                topic_type: parseInt(item.topic_type),
                topic_type_name: item.topic_type_name,
                question_content: item.question_content,
                question_description: item.question_description
              }
              item.order_paper_details.forEach((child, childIndex) => {
                this.items.push({
                  ...obj,
                  ...child
                })
              })
            })
          }
        }).catch(err => {
          this.$ShowToast(err.msg)
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.page {
  margin: 0 auto 90px;
  .box {
    width: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px 100px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .report-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    color: #333333;
    margin-bottom: 40px;
    text-align: center;
  }
  .time-score {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    color: #333333;
    .label {
      .c(#999);
      .mr(10px);
    }
    .time {
      .mr(auto);
      .c(#333);
    }
    .score {
      .mr(auto);
      .c(#CA0000);
    }
  }
  .answer-item {
    .mb(0.45rem);
    overflow: hidden;
    .label-title {
      .fz(0.3rem);
      .c(#333);
      .mb(0.3rem);
    }
    .answer-child {
      .fz(0.28rem);
      .c(#333);
      .mb(0.3rem);
    }
  }
  .btn {
    width: 181px;
    height: 50px;
    border-radius: 50px;
    .flex-cc();
    font-size: 18px;
    .m(0 15px);
    &.style-1 {
      background-image: linear-gradient(-90deg, #CA0000 0%, #CA0000 100%),
        linear-gradient(#c4c4c4, #c4c4c4);
      background-blend-mode: normal, normal;
      box-shadow: 0rem 0.06rem 0.14rem 0rem rgba(0, 156, 237, 0.24);
      .c(#fff);
    }
    &.style-2 {
      border: 1px solid #CA0000;
      .c(#CA0000);
    }
  }
}

.score-wrap {
  .score-left {
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    color: #333333;
    .label {
      .c(#999);
    }
    .time {
      .mr(auto);
      .c(#333);
    }
    i {
      color: #CA0000;
    }
  }
}
.score-right {
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  color: #333333;
}

.answer-box {
  .mb(0.3rem);
  // padding: 0 0.2rem;
  .sub-title {
    .mt(0.2rem);
    .mb(0.3rem);
    line-height: 1.4;
  }

  .answer-options {
    .mr(0.2rem);
    .mb(0.2rem);
    display: inline-block;
  }

  .correct-options {
    line-height: 1.6;
  }

  .answer-status-0 {
    .c(#db1a1a);
  }

  .answer-status-1 {
    .c(#368DFF);
  }

  .answer-status-2 {
    .c(#db1a1a);
  }

  .composition-picture {
    display: block;
    width: 100%;
  }

  /deep/ .essay {
    line-height:1.8;
    white-space: pre-wrap;
    // text-indent:2em;
  }
}
</style>

<template>
  <div class="mycourse">

    <div class="bg-warp">
        <img class="bg" src="/static/images/Campus/bg.png" alt="">
      <!-- <div class="center">
        <div class="text-box">
        <div class="big">
          <span>线上<span class="active">课程</span></span>
        </div>
        <div class="desc">
          坚持实施“一位学员、一种计划、一种方法”的针对性教学模式
        </div>
      </div>
      </div> -->
    </div>
    <div class="page box">

      <div class="box-item" v-if="all_list  && all_list.length">
        <!-- <TiliteNav>全套课程</TiliteNav> -->
        <div class="sub-title">全套课程</div>
        <div class="mb40">
          <CoureList :list="all_list" />
        </div>
      </div>

      <div class="box-item" v-if="goodList  && goodList.length">
        <div class="sub-title">系列课程</div>
        <div class="mb40">
          <CoureList :list="goodList" />
        </div>
      </div>

      <!--<div class="box-item" v-if="book_list  && book_list.length">
        <div class="sub-title">教材</div>
        <div class="mb40">
          <CoureList :list="book_list" />
        </div>
      </div> -->

      <div class="box-item" v-if="alonePaperList  && alonePaperList.length">
        <div class="sub-title">习题</div>
        <div class="mb40">
          <CoureList :list="alonePaperList" />
        </div>
      </div>

      <!-- <div class="box-item" v-if="tryList  && tryList.length">
         <div class="sub-title">试看课程</div>
        <div class="mb40">
          <CoureList :list="tryList" />
        </div>
      </div> -->
        


      <!-- <template v-if="side === 1 && all_list.length">
        <TiliteNav>全套课程</TiliteNav>
        <div class="mb40">
          <CoureList :list="all_list" />
        </div>
      </template>
      <TiliteNav>{{clist[side]['name']}}</TiliteNav>
      <div class="block">
        <CoureList :list="list" />
      </div>
      <template v-if="side === 1 && book_list.length">
        <TiliteNav>教材</TiliteNav>
        <div class="mb40">
          <CoureList :list="book_list" />
        </div>
      </template>

      <div class="side-box">
        <div class="side-sticky">
          <SideNav v-model="side" :list="clist" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  import CoureList from '../../components/CourseList'
  import SideNav from '../../components/SideNav'
  import { ListPage } from '@/utils/Mixin'
  export default {
    mixins: [ListPage],
    data () {
      return {
        side: 0,
        clist: [
          {
            name: '试看课程',
            url: 'Common.tryList',
            key: 'try'
          },
          {
            name: '系列课程',
            url: 'Common.goodsList',
            key: 'items'
          },
          {
            name: '线上习题',
            url: 'Common.alonePaperList',
            key: 'items'
          }
        ],
        // 全套课程
        all_list: [],
        // 教材
        book_list: [],
        // 系列课程
        goodList:[],
        // 习题
        alonePaperList:[],
        // 试看
        tryList:[]
      }
    },
    async mounted(){
      await this.getGoodsList()
      await this.getAlonePaperList()
      await this.getTryList()
    },
    methods: {
      // 系列课程(全套课程、系列课程、教材)
      async getGoodsList(){
        let { data, status } = await this.$Get('Common.goodsList', {
          page: 1,
          limit: 9999,
          group_id: 0
        })

        if(status){
          let allList = []
          let bookList = []
          let goodList = []
          data.items.forEach(item => {
            if (item.goods_type === 'book_type') {
              bookList.push(item)
            } else if (item.is_all * 1 === 1) {
              allList.push(item)
            } else {
              goodList.push(item)
            }
          })

          this.all_list = allList
          this.book_list = bookList
          this.goodList = goodList
        }
      },
      // 线上习题
      async getAlonePaperList(){
         let { data, status } = await this.$Get('Common.alonePaperList', {
          page: 1,
          limit: 9999,
          group_id: 0
        })

        if(status){
          console.log('getGoodsList-status',status)
          console.log('getGoodsList-data',data)
          this.alonePaperList = data.items
        }
      },
      // 试看
      async getTryList(){
         let { data, status } = await this.$Get('Common.alonePaperList', {
          page: 1,
          limit: 9999,
          group_id: 0
        })

        if(status){
          this.tryList = data.items
        }
      },
      async GetList () {
        let { data, status } = await this.$Get(this.clist[this.side]['url'], {
          page: this.page,
          limit: 9999,
          group_id: 0
        })
        if (status) {
          this.total_pages = data.total_pages
          this.total_items = data.total_items
          let list = data[this.clist[this.side]['key']]
          if (this.side === 1) {
            let allList = []
            let bookList = []
            let cList = []
            list.forEach(item => {
              if (item.goods_type === 'book_type') {
                bookList.push(item)
              } else if (item.is_all * 1 === 1) {
                allList.push(item)
              } else {
                cList.push(item)
              }
              this.all_list = allList
              this.book_list = bookList
              this.list = cList
            })
          } else {
            this.list = list
          }
          scrollTo(0, 0)
        }
      }
    },
    watch: {
      // side (to) {
      //   this.RefreshList()
      // }
    },
    components: {
      SideNav,
      CoureList
    }
  }
</script>

<style lang="less" scoped>
  .mycourse {
    // min-width: 1200px;
    padding: 0 0 90px 0;
    .flex-ccc();
    .mb40 {
      margin-bottom: 40px;
    }
    .block {
      padding-bottom: 40px;
      min-height: 500px;
    }

    .bg-warp {
      width: 100%;
      // background-image: url("/static/images/Campus/bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      // background-size: cover;
          .bg {
          display: block;
          width: 100%;
        }
      .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        width: 1200px;
        height: 380px;

        .bg {
          display: block;
          width: 100%;
        }
      // .text-box {
      //   .big {
      //     margin-bottom: 26px;
      //     height: 60px;
      //     line-height: 60px;
      //     font-size: 56px;
      //     font-weight: 800;
      //     color: #ffffff;
      //     .active {
      //       color: #CA0000;
      //     }
      //   }

      //   .desc {
      //     width: 480px;
      //     line-height: 36px;
      //     font-size: 24px;
      //     font-weight: 400;
      //     color: #E5E5E5;
      //   }
      // }
      }
    }

     .sub-title {
      position: relative;
      margin-bottom: 20px;
      padding-left: 12px;
      height: 30px;
      line-height: 30px;
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      &::before {
        position: absolute;
        top: 1px;
        left: 0;
        content: ' ';
        width: 4px;
        height: 28px;
        background: linear-gradient(180deg, #CA0000 0%, rgba(202, 0, 0, 0) 100%);
        opacity: 1;
        border-radius: 4px;
      }
    }

    .box {
      padding-top: 40px;
      padding-left: 170px;
      transform: translateX(-85px);
      position: relative;
      .box-item {
        margin-bottom: 40px;
      }
    }
    
    .mycourse-box {
      width: 1200px;
      .flex-cc();
      .mycourse-date-box {
        width: 1200px;
        height: 146px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        margin-bottom: 60px;
        .flex-c();
        .mycourse-date-item {
          .flex-1();
          .mycourse-date-title {
            height: 31px;
            font-size: 24px;
            font-weight: bold;
            line-height: 31px;
            color: #333333;
            text-align: center;
            margin-bottom: 15px;
          }
          .mycourse-date-block {
            .flex-1();
            .flex-cc();
            height: 37px;
            font-size: 20px;
            line-height: 26px;
            color: #333333;
            span {
              font-size: 28px;
              color: #CA0000;
            }
          }
        }
      }
    }
    .side-box {
      position: absolute;
      width: 140px;
      height: 100%;
      top: 0;
      left: 0;
      .side-sticky {
        position: sticky;
        top: 164px;
      }
    }
  }
</style>

<template>
  <div class="flex-c course">
    <CourseItem v-for="(item, index) in list" :key="index" :item="item" :animation="animation" :type="type" />
  </div>
</template>

<script>
  import CourseItem from '@/components/course-item'
  export default {
    name: 'course',
    props: {
      animation: [Boolean],
      list: [Array],
      type: [String]
    },
    components: {
      CourseItem
    }
  }
</script>

<style lang="less" scoped>
  .course {
    width: 1200px;
    .flex-wrap();
    .course-item {
      width: 270px;
      height: 302px;
      background: #F9F9F9;
      border-radius: 10px;
      margin-right: 40px;
      overflow: hidden;
      position: relative;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 40px;
      }
      .play-img {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 16px;
        top: 16px;
      }
      .course-hot {
        position: absolute;
        left: 16px;
        bottom: 30px;
        font-size: 14px;
        line-height: 24px;
        color: #CA0000;
      }
      .course-btn {
        width: 88px;
        position: absolute;
        bottom: 20px;
        right: 20px;
        height: 40px;
        background: #FFDEDE;
        border-radius: 22px;
        font-size: 16px;
        color: #CA0000;
        cursor: pointer;
        .flex-cc();
        line-height: normal;
        &.type1, &.type1:hover {
          color: #333;
          background: #F0F0F0;
        }
        &:hover {
          background: #CA0000;
          color: #fff;
        }
      }
      .course-main {
        padding: 16px 16px 25px;
        .course-title {
          height: 26px;
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          margin-bottom: 16px;
          .line-clamp-multi(1);
        }
        .course-content {
          font-size: 14px;
          line-height: 24px;
          color: #B2B2B2;
          .line-clamp-multi(3);
          span {
            color: #888;
          }
        }
      }
      img {
        width: 270px;
        height: 152px;
      }
    }
  }
</style>

<template>
  <div class="page off-apply">
    <div class="off-apply-head">
      <div class="apply-head-left">
        <img :src="$img('IconAddress')" class="address-img" />
        <span>{{mycity.city_name || '全国'}}</span>
        <img :src="$img('IconSelectOne')" class="select-img" />
        <div class="apply-address-box">
          <div class="apply-address-line">
            <div class="apply-address-text" @click="cityChage({})">全国</div>
          </div>
          <div class="flex-wrap">
            <div class="apply-address-line" v-for="(item, index) in area_list" :key="index">
              <div class="apply-address-label">{{item.name}}</div>
              <div class="apply-address-text" v-for="(i, k) in item.children" :key="k" @click="cityChage(i)">{{i.city_name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="apply-head-right">
        <input type="text" placeholder="请输入校区名称" v-model="content" @keyup.enter="RefreshList" />
        <div class="apply-head-btn" @click="RefreshList">
          <img :src="$img('IconSearch')" />
        </div>
      </div>
    </div>
    <div class="apply-block flex-bc flex-wrap">
      <ActivityItem v-for="(item, index) in list" :key="index" :item="item" />
    </div>
     <div class="flex-cc">
      <Paging v-model="page" :total="total_pages" :items="total_items" :limit="20" />
    </div>
  </div>
</template>

<script>
  import ActivityItem from '../../components/ActivityList/item'
  import { ListPage } from '@/utils/Mixin'
  export default {
    mixins: [ListPage],
    data () {
      return {
        keyword: '',
        content: '',
        show: false,
        position: {},
        mycity: {},
        area_list: []
      }
    },
    mounted () {
      // 引入js
      var element = document.createElement('script')
      element.src =
        'https://3gimg.qq.com/lightmap/components/geolocation/geolocation.min.js'
      document.body.appendChild(element)
      this.getCityList()
    },
    created () {
      setTimeout(() => {
        this.handlePosition()
      }, 2000)
    },
    methods: {
      handlePosition () {
        // eslint-disable-next-line no-undef
        var geolocation = new qq.maps.Geolocation(
          this.$Config.mapkey,
          '现代日语'
        )
        var options = { timeout: 8000 }

        geolocation.getLocation(
          this.positionCallback,
          this.positioningFailure,
          options
        )
      },
      // 定位回调函数
      positionCallback (position) {
        this.position = position
        this.$Message({
          message: '定位成功',
          type: 'success'
        })
        this.getCityList()
      },
      // 定位失败回调
      positioningFailure () {
        this.$Message.error('定位失败')
      },
      async getCityList () {
        let { status, data } = await this.$Get('Common.getCityList', {
          lng: this.position.lng,
          lat: this.position.lat,
          keyword: this.keyword
        })
        if (status) {
          this.area_list = Object.keys(data.city_all_list).map(item => ({
            name: item,
            children: data.city_all_list[item]
          }))
          this.mycity = data.my_city
          this.RefreshList()
        }
      },
      async GetList () {
        let { status, data } = await this.$Get('Common.offlineGoodsList', {
          page: this.page,
          limit: 20,
          content: this.content || '',
          city_id: this.mycity.city_id || ''
        })
        if (status) {
          this.HandleRes(data)
        }
      },
      cityChage (i) {
        this.mycity = i
        this.RefreshList()
      }
    },
    components: {
      ActivityItem
    }
  }
</script>

<style lang="less" scoped>
  .off-apply {
    margin: auto;
    padding-bottom: 90px;
    .apply-block {
      padding-bottom: 20px;
    }
    .off-apply-head {
      height: 106px;
      position: relative;
      .flex-bc();
      .apply-head-right {
        width: 377px;
        height: 34px;
        background: #F0F0F080;
        border-radius: 17px;
        box-sizing: border-box;
        padding-left: 16px;
        .flex-c();
        input {
          .flex-1();
          font-size: 12px;
          background: #F0F0F080;
        }
        .apply-head-btn {
          width: 64px;
          height: 34px;
          background: #CA0000;
          border-radius: 17px;
          cursor: pointer;
          .flex-cc();
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .apply-head-left {
        font-size: 20px;
        position: relative;
        cursor: pointer;
        height: 100%;
        .flex-c();
        .address-img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        .select-img {
          margin-left: 10px;
          width: 12px;
          height: 12px;
        }
        &:hover {
          .apply-address-box {
            display: block;
          }
        }
      }
      .apply-address-box {
        display: none;
        width: 838px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
        position: absolute;
        top: 86px;
        z-index: 99;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 30px;
        padding-bottom: 1px;
        .apply-address-line {
          margin-bottom: 20px;
          min-width: 50%;
          .flex();
        }
        .apply-address-label {
          width: 44px;
          height: 20px;
          background: #CA0000;
          border-radius: 12px;
          font-size: 14px;
          color: #fff;
          margin-right: 20px;
          .flex-cc();
        }
        .apply-address-text {
          font-size: 14px;
          line-height: 24px;
          color: #333333;
          margin-right: 20px;
          cursor: pointer;
          &:hover {
            color: #CA0000;
          }
        }
        &::before {
          content: '';
          position: absolute;
          left: 36px;
          top: -20px;
          width: 0;
          height: 0;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          border-bottom: 20px solid #fff;
        }
      }
    }
  }
</style>

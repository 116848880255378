<template>
  <div class="apply">
    <div class="apply-item" v-for="(item, index) in list" :key="index" :class="{animation: animation}">
      <img src="/static/images/entrepot/course.png" />
      <div class="apply-main">
        <div class="apply-main-left">
          <div class="apply-main-title">高考日语阅读题专项突破课程</div>
          <div class="apply-main-name"><span>姓名：</span>陈某某/13800138000</div>
          <div class="apply-main-area"><span>报名校区：</span>广州天河校区</div>
        </div>
        <div class="apply-main-right">
          <div class="apply-main-date"><span>报名日期：</span>2020.09.21</div>
          <div class="apply-main-num">报到编号：103316360660</div>
          <div class="apply-main-price">报到费用：<span>￥1000</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'apply',
    data () {
      return {
        list: [
          {},
          {},
          {}
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  .apply {
    width: 1200px;
    .apply-item {
      width: 1200px;
      height: 214px;
      background: #FFFFFF;
      margin-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      padding: 36px 40px;
      .flex-c();
      img {
        width: 250px;
        height: 141px;
        margin-right: 40px;
      }
      .apply-main {
        .flex-1();
        .flex-c();
      }
      .apply-main-left {
        .flex-1();
        .apply-main-title {
          font-size: 24px;
          line-height: 31px;
          color: #333333;
          margin-bottom: 40px;
        }
        .apply-main-name {
          font-size: 18px;
          line-height: 24px;
          color: #333333;
          margin-bottom: 16px;
          span {
            color: #888;
          }
        }
        .apply-main-area {
          font-size: 18px;
          line-height: 24px;
          color: #333333;
          span {
            color: #888;
          }
        }
      }
      .apply-main-right {
        width: 266px;
        .apply-main-date {
          font-size: 18px;
          line-height: 31px;
          color: #333333;
          margin-top: 8px;
          margin-bottom: 24px;
          span {
            color: #888;
          }
        }
        .apply-main-num {
          font-size: 18px;
          line-height: 31px;
          color: #CA0000;
          margin-bottom: 18px;
        }
        .apply-main-price {
          font-size: 18px;
          line-height: 31px;
          color: #CA0000;
          span{
            font-size: 28px;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="teacher-page">
    <div class="banner">
      <div class="banner-box flex-ccc">
        <div class="banner-title">师资<span>介绍</span></div>
        <div class="w100">
          <div class="banner-note">{{about}}</div>
        </div>
      </div>
    </div>
    <div class="teacher-block flex-ccc">
      <div class="home-list type2">
        <TeachersList :list="list" />
      </div>
      <div class="flex-cc block">
        <Paging v-model="page" :total="total_pages" :items="total_items" :limit="20" />
      </div>
    </div>
  </div>
</template>

<script>
  import TeachersList from './components/TeachersList'
  import { ListPage } from '@/utils/Mixin'
  export default {
    mixins: [ListPage],
    data () {
      return {
        about: ''
      }
    },
    methods: {
      async GetList () {
        let { data, status } = await this.$Get('Common.getArticleListV2', {
          page: this.page,
          limit: 20,
          type: 'school_teachers'
          // is_hot: 1
        })
        if (status) {
          this.HandleRes(data)
          this.about = data.about
        }
      }
    },
    components: {
      TeachersList
    }
  }
</script>

<style lang="less" scoped>
.teacher-page {
  background-color: #f9f9f9;
  .banner {
    position: relative;
    min-width: 1200px;
    width: 100%;
    height: 380px;
    background-size: cover;
    background-image: url(/static/images/entrepot/teacher.png);
    .banner-box {
      width: 1200px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      .banner-title {
        width: 100%;
        font-size: 56px;
        font-weight: 800;
        line-height: 95px;
        color: #fff;
        span {
          color: #CA0000;
        }
      }
      .w100 {
        width: 100%;
      }
      .banner-note {
        width: 480px;
        font-size: 24px;
        line-height: 36px;
        color: #E5E5E570;
      }
    }
    img {
      width: 100vw;
      min-width: 1200px;
      height: auto;
    }
  }
  .teacher-block {
    padding: 90px 0 110px;
    min-width: 1200px;
    .block {
      padding: 60px 0 0;
    }
  }
}
</style>

<template>
  <div class="activity-item" :class="cutomClass" @click="$nav('Campus.CampusDetail', {id: item.id})">
    <img :src="item.cover_url" class="activity-item-img">
    <div class="activity-item-right">
      <div class="activity-item-title">{{item.goods_name}}</div>
      <div class="activity-item-desc">{{item.goods_about}}</div>
      <div class="activity-item-phone">
        <img :src="$img('IconPhoneTwo')" alt="">
        <span>{{item.school_info.phone}}</span>
      </div>
      <div class="activity-item-address">
        <img :src="$img('IconAddressTwo')" alt="">
        <span>{{item.school_info.address}}</span>
      </div>
      <div class="activity-item-btn">立即报名</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        default: () => ({
          school_info: {}
        })
      },
      animation: [Boolean],
      type: [String]
    },
    watch: {
      animation (to) {
        if (to) {
          setTimeout(() => {
            this.isanimation = false
          }, 2100)
        }
      }
    },
    data () {
      return {
        isanimation: true
      }
    },
    computed: {
      cutomClass () {
        if (this.isanimation && this.animation) {
          return this.type
        }
        return ''
      }
    },
    mounted () {
      if (this.animation) {
        setTimeout(() => {
          this.isanimation = false
        }, 2100)
      }
    }
  }
</script>

<style lang="less" scoped>
  .activity-item {
    width: 590px;
    height: 180px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 15px 20px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    .flex-c();
    &.left {
      animation: movefromLeft 2s;
      animation-fill-mode: forwards;
    }
    &.right {
      animation: movefromRight 2s;
      animation-fill-mode: forwards;
    }
    .activity-item-img {
      width: 200px;
      height: 150px;
      margin-right: 20px;
    }
    .activity-item-right {
      .flex-1();
      height: 100%;
      position: relative;
      .activity-item-title {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.3;
        color: #333333;
        .line-clamp();
      }
      .activity-item-desc {
        font-size: 16px;
        line-height: 24px;
        color: #B2B2B2;
        .line-clamp-multi(2);
      }
      .activity-item-phone {
        position: absolute;
        bottom: 28px;
        left: 0;
        font-size: 16px;
        font-family: WeChatNumber-151125;
        font-weight: 500;
        line-height: 24px;
        color: #236FFB;
        .flex-c();
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .activity-item-address {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 16px;
        line-height: 24px;
        color: #B2B2B2;
        width: 200px;
        .line-clamp();
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .activity-item-btn {
        &:hover {
          background: #CA0000;
          color: #fff;
          box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
        }
        width: 90px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #CA0000;
        color: #CA0000;
        font-size: 16px;
        border-radius: 25px;
        position: absolute;
        bottom: 0;
        cursor: pointer;
        right: 0;
        .flex-cc();
      }
    }
    &:hover {
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    }
  }
</style>

<template>
	<div class="schoolIntroduction-wrap" v-show="show">
		<img :src="cover" alt="">
	</div>
</template>

<script>
	export default {
		props: {
			show: {
				default: true
			}
		},
		data() {
			return {
				cover: ''
			}
		},
		mounted() {
			this.getSchoolAbout()
		},
		methods: {
			getSchoolAbout() {
				this.$Get('Common.getSchoolAbout').then(res => {
					this.cover = res.data.cover
				})

			}
		}
	}
</script>

<style lang="less" scoped>
	.schoolIntroduction-wrap {
		img {
			width: 100%;
		}
	}
</style>

<template>
    <div class="VoiceofTheHeart-container" :class="{'show':list.length>0}">
        <div id="VoiceofTheHeart">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(i,k) in list" :key="k">
                        <div class="box">
                            <div class="info-warp">
                                <div class="cover-warp">
                                    <!-- <img class="cover" :src="i.cover_url+'?x-oss-process=image/resize,w_200,h_200,m_lfit'"> -->
                                    <el-image style="width: 110px; height:110px" :src="i.cover_url" fit="cover"></el-image>
                                </div>
                                <div class="text-warp">
                                    <div class="name-row">
                                        <span class="name">{{i.title||''}}</span>
                                        <span class="year" v-if="i.score">{{i.score}}届</span>
                                    </div>
                                    <div class="desc">
                                        <span>{{i.about || ''}}</span>
                                        <!--        <span>以</span>
                                        <span class="active">132</span>
                                        <span>的高分 ，总分</span>
                                        <span class="active">613</span>
                                        <span>考上重本</span> -->
                                    </div>
                                </div>
                            </div>

                            <div class="content-warp">
                                <div class="content">
                                    <p class="text">
                                        {{i.content||''}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button swiper-button-prev"></div>
                <div class="swiper-button swiper-button-next"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import Swiper from 'swiper';

    var Swiper1 = null

    export default {
        name: 'VoiceofTheHeart',
        data() {
            return {
                list: []
            }
        },
        computed: {
            prveDisabled() {
                return this.activeIndex == 0 || this.list.length < 1
            },
            nextDisabled() {
                return this.activeIndex >= this.list.length - 1 || this.list.length < 1
            }
        },
        async mounted() {
            await this.GetList()
            this.$nextTick(() => {

                var VoiceofTheHeartSwiper = new Swiper('#VoiceofTheHeart .swiper-container', {
                    watchSlidesProgress: true,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    initialSlide: 1,
                    // loop: true,
                    loopedSlides: 5,
                    autoplay: 3000,
                    autoplayDisableOnInteraction: false,
                    prevButton: '.swiper-button-prev',
                    nextButton: '.swiper-button-next',
                    onProgress: function(swiper, progress) {
                        for (var i = 0; i < swiper.slides.length; i++) {
                            var slide = swiper.slides.eq(i);
                            // console.log('slide',slide)
                            var slideProgress = swiper.slides[i].progress;

                            if (i == 0) {
                                console.log('slideProgress', slideProgress)
                            }

                            var modify = 1;
                            if (Math.abs(slideProgress) > 1) {
                                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                            }
                            // 260
                            var translate = slideProgress * modify * 490 + 'px';
                            var scale = 1 - Math.abs(slideProgress) / 5;
                            var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                            slide.transform('translateX(' + translate + ') scale(' + scale +
                                ')');
                            slide.css('zIndex', zIndex);
                            slide.css('opacity', 1);
                            if (Math.abs(slideProgress) > 3) {
                                slide.css('opacity', 0);
                            }
                        }
                    },
                    onSetTransition: function(swiper, transition) {
                        for (var i = 0; i < swiper.slides.length; i++) {
                            var slide = swiper.slides.eq(i)
                            slide.transition(transition);
                        }

                    },
                    //处理分页器bug
                    // onSlideChangeStart: function (swiper) {
                    //   if (swiper.activeIndex == 4) {
                    //     swiper.bullets.eq(9).addClass('swiper-pagination-bullet-active');
                    //     console.log(swiper.bullets.length);
                    //   }
                    // }
                });
                // 鼠标移入停止自动滚动
                $('#VoiceofTheHeart .swiper-container').mouseenter(function() {
                    VoiceofTheHeartSwiper.stopAutoplay()
                })
                // 鼠标移出开始自动滚动
                $('#VoiceofTheHeart .swiper-container').mouseleave(function() {
                    VoiceofTheHeartSwiper.startAutoplay()
                })
            })

        },
        methods: {
            async GetList() {
                let {
                    data,
                    status
                } = await this.$Get('Common.getStudentList', {
                    page: 1,
                    limit: 20
                })
                if (status && data && data.total_items > 0) {
                    this.list = data.items
                }
            }
        },
    }
</script>

<style lang="less" scoped>
    .VoiceofTheHeart-container {
        display: none;

        &.show {
            display: block;
        }

        #VoiceofTheHeart {
            position: relative;
            width: 1200px;

            .swiper-container {
                position: relative;

                &::after {
                    z-index: 5;
                    position: absolute;
                    top: 142px;
                    right: 134px;
                    content: " ";
                    width: 140px;
                    height: 62px;
                    background-image: url('/static/images/home/VoiceofTheHeart/8.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }

                .swiper-slide {
                    box-sizing: border-box;
                    padding-top: 30px;
                    width: 774px;
                    height: 534px;

                    &.swiper-slide-active:hover {
                        .box {
                            .content-warp {
                                &::before {
                                    background-image: url('/static/images/home/VoiceofTheHeart/4.png');
                                }

                                .content {
                                    height: initial;

                                    &::before {
                                        height: 28px;
                                        opacity: 1;
                                    }

                                    &::after {
                                        background-color: #CA0000;
                                    }

                                    .text {
                                        color: #ffffff;
                                        text-indent: 2em;
                                    }
                                }
                            }
                        }
                    }

                    .box {
                        position: relative;
                        box-sizing: border-box;
                        padding: 40px;
                        width: 774px;
                        height: 464px;
                        background-color: #FFFFFF;
                        box-shadow: 0px 2px 24px rgba(202, 0, 0, 0.06);
                        border-radius: 10px 10px 60px 10px;

                        &::before {
                            // z-index: 5;
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: " ";
                            width: 100%;
                            height: 100%;
                            background-image: url('/static/images/home/VoiceofTheHeart/5.png');
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                        }

                        .info-warp {
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;

                            .cover-warp {
                                margin-right: 30px;
                                width: 110px;
                                height: 110px;
                                border-radius: 55px;
                                overflow: hidden;

                                .cover {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                }
                            }

                            .text-warp {
                                .name-row {
                                    margin-bottom: 7px;

                                    .name {
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-right: 20px;
                                        height: 37px;
                                        line-height: 37px;
                                        font-size: 28px;
                                        font-weight: bold;
                                        color: #333333;
                                    }

                                    .year {
                                        display: inline-block;
                                        vertical-align: middle;
                                        width: 84px;
                                        height: 30px;
                                        line-height: 30px;
                                        background: #CA0000;
                                        border-radius: 15px;
                                        font-size: 18px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        text-align: center;
                                    }
                                }

                                .desc {
                                    height: 24px;
                                    font-size: 18px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    line-height: 22px;
                                    color: #888888;

                                    .active {
                                        color: #cb0707;
                                    }
                                }
                            }
                        }

                        .content-warp {
                            position: relative;

                            &::before {
                                display: block;
                                margin-left: 60px;
                                content: " ";
                                width: 29px;
                                height: 30px;
                                background-image: url('/static/images/home/VoiceofTheHeart/2.png');
                                background-repeat: no-repeat;
                                background-size: 29px 30px;
                            }

                            .content {
                                position: relative;
                                box-sizing: border-box;
                                padding: 10px 40px 30px;
                                height: 224px;

                                &::before {
                                    z-index: 6;
                                    position: relative;
                                    display: block;
                                    margin-bottom: 20px;
                                    content: " ";
                                    width: 28px;
                                    height: 0;
                                    background-image: url('/static/images/home/VoiceofTheHeart/3.png');
                                    background-repeat: no-repeat;
                                    background-size: 28px 28px;
                                    transition: .3s;
                                    overflow: hidden;
                                    opacity: 0;
                                }

                                &::after {
                                    z-index: 3;
                                    position: absolute;
                                    top: -1px;
                                    left: 0;
                                    content: " ";
                                    width: 100%;
                                    height: 100%;
                                    background-color: #f6f6f6;
                                    border-radius: 10px 10px 30px 10px;
                                }

                                .text {
                                    z-index: 6;
                                    position: relative;
                                    max-height: 170px;
                                    line-height: 24px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #888888;
                                    overflow-y: auto;
                                }
                            }
                        }
                    }
                }

                .swiper-button {
                    position: absolute !important;
                    top: initial !important;
                    left: initial !important;
                    bottom: 34px !important;
                    margin: 0;
                    width: 36px !important;
                    height: 32px !important;
                    background-position: 0 0;
                    background-size: 100%;
                    background-repeat: no-repeat;

                    &.swiper-button-prev {
                        right: 120px !important;
                        background: url("/static/images/home/VoiceofTheHeart/10.png");

                        &.swiper-button-disabled {
                            background: url("/static/images/home/VoiceofTheHeart/9.png") no-repeat;
                        }
                    }

                    &.swiper-button-next {
                        right: 50px !important;
                        background: url("/static/images/home/VoiceofTheHeart/6.png");

                        &.swiper-button-disabled {
                            background: url("/static/images/home/VoiceofTheHeart/7.png") no-repeat;
                        }
                    }
                }
            }
        }
    }
</style>

<template>
  <div class="top-tool">
    <!-- 三个东东 -->
    <div class="three-column">
      <!-- <div class="reckon-time">00:12</div> -->
      <div class="reckon-time">{{m|formatNumber}}:{{s|formatNumber}}</div>
      <div class="step-number">
        <span>{{ progress.completed }}</span>
        <span>/</span>
        <span class="gray-color">{{progress.count}}</span>
        <!-- <span>{{ now_index + 1 }}</span>
        <span>/</span>
        <span class="gray-color">{{total}}</span>-->
      </div>
      <div>
        <img :src="$img('IconAnswer')" class="card-icon" @click="$emit('update:card_show', true)">答题卡
      </div>
    </div>
    <!-- 题目类型 -->
    <div class="question-type" v-if="topic_type_name">{{topic_type==4?'作文题': topic_type_name}}</div>
    <div class="question-type" v-else>{{ type | AnswerType }}</div>
    <!-- 类型描述 -->
    <div class="question-desc">
      {{question_description}}
      <!-- 第一节——听下面4段录音，每段录音后有2道小题，从[A][B][C] 3个选项中选出最佳选项。 -->
    </div>
    <!-- 分数 -->
    <!-- <div class="question-desc">(共8小题：每小题2分，满分16分)</div> -->
  </div>
</template>

<script>
  export default {
    data () {
      return {}
    },
    props: {
      total: null,
      now_index: null,
      type: null,
      card_show: null,
      topic_name: null,
      topic_type_name: null,
      question_score: null,
      question_description: null,
      m: {
        type: [Number],
        default: 0
      },
      s: {
        type: [Number],
        default: 0
      },
      progress: null,
      topic_type: null
    },
    mounted () {
      // this.Timing()
    },
    methods: {
      Timing () {
        this.timer = setInterval(() => {
          this.s++
          if (this.s >= 60) {
            this.s = 0
            this.m += 1
          }
        }, 1000)
      }
    }
  }
</script>

<style lang="less" scoped>
.top-tool {
  .mb(0.6rem);
  .three-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mb(0.36rem);
    .reckon-time {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      color: #333333;
      box-sizing: border-box;
    }
    .step-number {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      color: #333333;
      box-sizing: border-box;
      .gray-color {
        color: #CA0000;
      }
    }
    .card-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .question-type {
    .fz(0.32rem);
    .c(#333);
    font-weight: bold;
    .mb(0.35rem);
  }
  .question-desc {
    .fz(0.28rem);
    .c(#333);
    .lh(1.3);
  }
}
</style>

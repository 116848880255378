<template>
    <div class="page subtitles-type-container">
        <TiliteNav>
            <template v-if="$route.meta.parent">
                <span v-for="(item, index) in $route.meta.parent" :key="index" @click="$nav(item.url)">{{item.title}}
                    >> </span>
            </template>
            课文详情
        </TiliteNav>

        <div class="subtitles-type-warp">
            <div class="head">
                <div class="item item-1"></div>
                <div class="item item-2"></div>
                <div class="item item-3"></div>
            </div>
            <div class="box-warp">
                <div class="box">
                    <!-- 封面 -->
                    <div class="cover-warp" :class="{'show':!audioPlay && showCover}">
                        <div class="cover" :class="{'filter':audioStatus==='ended'}">
                            <el-image style="width: 1160px; height: 740px" :src="thumb" fit="cover"></el-image>
                        </div>
                        <div class="control-warp">
                            <div class="good-title">{{goods.title||''}}</div>
                            <div class="btn-warp">
                                <div class="btn" :class="audioStatus" @click="handlePlay"></div>
                                <div class="text" v-if="audioStatus==='ended'">再看一遍</div>
                            </div>
                        </div>
                    </div>

                    <!-- 跟读内容 -->
                    <div class="centent-warp" :class="{'show':!showCover}">
                        <div class="good-title">{{goods.title||''}}</div>
                        <div class="html-warp">
                            <div class="html">
                                <span class="text" v-for="(i,k) in content" :key="k">
                                    <span class="currentContent" v-if="currentContent.cid==i.cid">
                                        <span class="isRead">{{currentContent.isRead}}</span>{{currentContent.noRead}}
                                    </span>
                                    <span v-else>
                                        {{i.subtitles_content}}
                                    </span>
                                </span>
                            </div>

                            <div class="html-btn-warp" :class="{'show':audioStatus==='pause'}">
                                <div class="btn" :class="audioStatus" @click="handlePlay"></div>
                            </div>
                        </div>

                        <!-- 音频控制器 -->
                        <div class="audio-control-warp">
                            <div class="audio-control">
                                <div class="play-btn" :class="audioStatus" @click="handlePlay"></div>
                                <div class="my-slider-warp">
                                    <div class="my-slider">
                                        <el-slider v-model="audioProgress" :step="0.2" height="8" :disabled="duration<=0"
                                            :show-tooltip="false" @change="sliderChange"></el-slider>
                                    </div>
                                </div>
                                <div class="text">{{audioCurrentDuration.minute}}:{{audioCurrentDuration.second}}/{{audioDuration.minute}}:{{audioDuration.second}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 插件,已经隐藏 -->
        <div class="aplayer-warp" v-if="music.src">
            <!-- @ended="onEnded" @emptied="onEmptied" @error="onError" @abort="onAbort" @pause="onPause" @playing="onPlaying" -->
            <aplayer :music="music" ref="player" @pause="onPause" @playing="onPlaying" @emptied="onEmptied" @ended="onEnded"
                @error="onError" @abort="onAbort" @loadedmetadata="onLoadedmetadata" @durationchange="onDurationchange"
                @timeupdate="onTimeupdate"></aplayer>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    import aplayer from 'vue-aplayer'
    export default {
        data() {
            return {
                goods: {},
                // 播放状态
                audioPlay: false,
                // playing:播放中 pause:暂停 ended：结束
                audioStatus: 'pause',
                // 播放进度
                audioProgress: 0,
                showCover: true,
                content: [],
                music: {
                    src: ''
                },
                audioDuration: {
                    m: 0,
                    s: 0,
                    minute: '00',
                    second: '00'
                },
                audioCurrentDuration: {
                    m: 0,
                    s: 0,
                    minute: '00',
                    second: '00'
                },
                // 总时长
                duration: 0,
                // 当前播放时长
                currentTime: 0,

            }
        },
        computed: {
            ...mapState('Common', ['isLogin']),
            thumb() {
                var src = ''
                if (this.goods && this.goods.thumb) {
                    src = this.goods && this.goods.thumb
                }
                return src
            },
            currentContent() {
                let isRead = ''
                let noRead = ''
                const currentTime = this.currentTime
                let item = this.content.find(i => {
                    return i.start_subtitles_number <= currentTime && i.end_subtitles_number >= currentTime
                })

                if (item && item.subtitles_content) {
                    let content = item.subtitles_content
                    let leng = content.length
                    // 时长
                    let duration = item.end_subtitles_number - item.start_subtitles_number || 0
                    // 进度
                    let progress = currentTime - item.start_subtitles_number || 0

                    if (leng) {
                        let count = Math.ceil(leng * (progress / duration))
                        isRead = content.substring(0, count)
                        noRead = content.substring(count)
                    } else {
                        isRead = content
                    }
                } else {
                    return false
                }

                return {
                    cid: item.cid || '',
                    isRead,
                    noRead
                }
            }
        },
        watch: {
            audioPlay(n) {
                if (n) {
                    this.audioStatus = 'playing'
                }
            },
            '$route.query.id'(to) {
                if (to) {
                    this.goodsDetails()
                }
            }
        },
        activated() {
            this.goodsDetails()
        },
        deactivated() {
            if (this.$refs.player) {
                this.$refs.player.pause()
                this.music.src = ''
            }
        },
        methods: {
            async goodsDetails() {
                let {
                    status,
                    data
                } = await this.$Get('Common.goodsDetailsV2', {
                    id: this.$route.query.id
                })
                if (status) {
                    this.goods = data.goods
                    if (data.goods && data.goods.subtites_details) {

                        if (data.goods.subtites_details.content) {
                            this.content = data.goods.subtites_details.content.map((i, k) => {
                                return {
                                    ...i,
                                    cid: k
                                }
                            }) || []
                        }

                        // 记得注释
                        this.music.src = data.goods.subtites_details.mp3_url || ''
                    }
                }
            },
            handlePlay() {

                if (this.goods.my_purchase * 1 === 0) {
                    return this.handlePay()
                }
                var player = this.$refs.player || null
                if (this.audioStatus === 'ended') {
                    this.showCover = false
                }
                if (player && !this.audioPlay) {
                    player.play()
                    this.showCover = false
                } else {
                    player.pause()
                }
            },
            sliderChange(e) {
                // 跳到指定时间
                if (this.$refs.player && this.$refs.player.audio) {
                    var currentTime = this.duration * (e / 100)
                    this.$refs.player.audio.currentTime = currentTime
                }
            },
            // 播放结束时触发。
            onEnded() {
                this.audioPlay = false
                this.audioStatus = 'ended'
                this.showCover = true
                console.log('播放结束时触发', this.audioPlay)
            },
            // 媒体被清空（初始化）时触发。
            onEmptied() {
                this.audioPlay = false
                this.audioStatus = 'pause'
                console.log('媒体被清空（初始化）时触发。', this.audioPlay)
            },
            // 在发生错误时触发
            onError() {
                this.audioPlay = false
                this.audioStatus = 'pause'
                console.log('在发生错误时触发。', this.audioPlay)
            },
            // 在播放被终止时触发
            onAbort() {
                this.audioPlay = false
                this.audioStatus = 'pause'
                console.log('在播放被终止时触发。', this.audioPlay)
            },
            // 播放暂停时触发。
            onPause() {
                this.audioPlay = false
                this.audioStatus = 'pause'
                console.log('播放暂停时触发。', this.audioPlay)
            },
            // 在媒体开始播放时触发（不论是初次播放、在暂停后恢复、或是在结束后重新开始）。
            onPlaying() {
                this.audioPlay = true
                this.audioStatus = 'playing'
                this.showCover = false
                console.log('在媒体开始播放时触发。', this.audioPlay)
            },
            onLoadedmetadata(e) {
                console.log('媒体的元数据已经加载完毕，现在所有的属性包含了它们应有的有效信息。')
            },
            onDurationchange(e) {
                // 获取音频时间长度
                this.duration = e.target && e.target.duration ? e.target.duration : 0
                // 格式化时间
                this.audioDuration = this.timeStampFormat(this.duration)
                console.log('元信息已载入或已改变，表明媒体的长度发生了改变。例如，在媒体已被加载足够的长度从而得知总长度时会触发这个事件。')
            },
            onTimeupdate(e) {
                // 播放进度
                this.currentTime = e.target && e.target.currentTime ? e.target.currentTime : 0
                // 进度条比例
                this.audioProgress = (this.currentTime / this.duration) * 100
                // 已播放时间
                this.audioCurrentDuration = this.timeStampFormat(this.currentTime)
                console.log('onTimeupdate', e.target.currentTime)
            },
            timeStampFormat(duration) {
                var second = Math.floor(duration);
                // 天数
                // var d = Math.floor(second / 3600 / 24)||0
                // 小时
                // var hr = Math.floor(second / 3600 % 24);
                // 小时数
                // var h = Math.floor(second / 3600 )||0
                // 分钟
                // var m = Math.floor(second / 60 % 60)||0
                var m = Math.floor(second / 60) || 0
                // 秒
                var s = Math.floor(second % 60) || 0

                return {
                    duration,
                    m,
                    s,
                    minute: m.toString().padStart(2, '0'),
                    second: s.toString().padStart(2, '0')
                }
            },
            handlePay() {
                if(this.$route.query.id) {
                    this.$Bus.$emit('Pay', {
                        id:this.$route.query.id
                    })
                }
            },
        },
        components: {
            aplayer
        }
    }
</script>

<style lang="less" scoped>
    .subtitles-type-container {
        margin: auto;
        padding-bottom: 90px;

        .subtitles-type-warp {
            margin: auto;
            width: 1200px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
            border-radius: 0px 0px 10px 10px;

            .head {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding-left: 20px;
                height: 48px;
                background: linear-gradient(90deg, #FFA2A2 0%, #FFCECE 100%);
                opacity: 1;
                border-radius: 10px 10px 0px 0px;

                .item {
                    margin-right: 18px;
                    width: 14px;
                    height: 14px;
                    background: #FFFFFF;
                    border-radius: 50%;

                    &.item-1 {
                        opacity: 1;
                    }

                    &.item-2 {
                        opacity: 0.7;
                    }

                    &.item-3 {
                        opacity: 0.4;
                    }
                }
            }

            .box-warp {
                position: relative;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 0 0 10px 10px;

                .box {
                    height: 740px;
                    border-radius: 10px;

                    .centent-warp {
                        display: none;

                        &.show {
                            display: block;
                        }

                        .good-title {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0 auto;
                            width: 960px;
                            height: 124px;
                            font-size: 32px;
                            font-weight: bold;
                            color: #333333;
                            text-align: center;
                        }

                        .html-warp {
                            margin: 0 auto;
                            position: relative;
                            width: 960px;
                            height: 500px;
                            background: #FFFFFF;
                            overflow-y: auto;

                            .html {
                                line-height: 36px;
                                white-space: pre-line;

                                .text {
                                    display: inline-block;
                                    position: relative;
                                    font-size: 20px;
                                    font-weight: 400;
                                    color: #888888;
                                    white-space: pre-line;

                                    .isRead {
                                        color: #CA0000;
                                    }
                                }
                            }

                            .html-btn-warp {
                                position: absolute;
                                top: 0;
                                height: 0;
                                width: 100%;
                                height: 100%;
                                display: none;
                                align-items: center;
                                justify-content: center;

                                &.show {
                                    display: flex;
                                }

                                .btn {
                                    width: 120px;
                                    height: 120px;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    border-radius: 60px;
                                    cursor: pointer;

                                    &.pause {
                                        background-image: url('/static/images/SubtitlesDetail/play-2.png');
                                    }
                                }
                            }
                        }

                        .audio-control-warp {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 1200px;
                            background: #FFFFFF;
                            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
                            opacity: 1;
                            border-radius: 0px 0px 10px 10px;

                            .audio-control {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 80px;

                                .play-btn {
                                    width: 48px;
                                    height: 48px;
                                    border-radius: 24px;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    background-image: url('/static/images/SubtitlesDetail/pause.png');
                                    cursor: pointer;

                                    &.pause {
                                        background-image: url('/static/images/SubtitlesDetail/play.png');
                                    }
                                }

                                .my-slider-warp {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0 42px;
                                    width: 600px;
                                    height: 8px;

                                    .my-slider {
                                        flex: 1;

                                        /deep/ .el-slider {
                                            .el-slider__bar {
                                                background-color: #CA0000;
                                            }

                                            .el-slider__button {
                                                box-sizing: border-box;
                                                width: 24px !important;
                                                height: 24px !important;
                                                border: 8px solid #FFFFFF !important;
                                                background: #CA0000;
                                                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);

                                                // background: #FFFFFF;
                                                // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
                                                // border-radius: 50%;
                                                &::after {
                                                    // content: ' ';
                                                    // width: 10px;
                                                    // height: 10px;
                                                    // background: #CA0000;
                                                    // border-radius: 50%;
                                                    // opacity: 1;

                                                }
                                            }
                                        }
                                    }
                                }

                                .text {
                                    height: 19px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 19px;
                                    color: #888888;
                                }
                            }
                        }
                    }

                    .cover-warp {
                        display: none;
                        position: relative;
                        height: 100%;
                        border-radius: 10px;
                        overflow: hidden;

                        &.show {
                            display: block;
                        }

                        .cover {
                            width: 100%;
                            height: 100%;

                            &.filter {
                                filter: blur(9px);
                            }
                        }

                        .control-warp {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, .5);
                            // background-color: red;

                            .good-title {
                                margin-bottom: 35px;
                                line-height: 44px;
                                font-size: 32px;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #FFFFFF;
                                text-align: center;
                            }

                            .btn-warp {
                                transform-box: 60px;

                                .btn {
                                    width: 120px;
                                    height: 120px;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    border-radius: 60px;
                                    cursor: pointer;

                                    &.pause {
                                        background-image: url('/static/images/SubtitlesDetail/play-1.png');
                                    }

                                    &.ended {
                                        background-image: url('/static/images/SubtitlesDetail/replay.png');
                                    }
                                }

                                .text {
                                    padding-top: 24px;
                                    font-size: 24px;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }

        .aplayer-warp {
            position: fixed;
            top: 0;
            left: -100px;
            width: 0;
            height: 0;
            opacity: 0;
            overflow: hidden;
        }
    }
</style>

<template>
  <div class="writing-item">
    <div class="title">
      <span>{{question_name}}</span>
      <span class="score">{{question_score}}分</span>
    </div>
    <!-- <div class="desc" v-html="requirements"></div> -->
    <!-- <pre class="desc">{{question_requirements}}</pre> -->
    <div class="desc">{{question_requirements}}</div>
    <div class="tips">提示：本题无标准答案。</div>
    <div class="input-title">
      <span>*</span>作文上传
    </div>
    <el-input
      type="textarea"
      placeholder="请输入作文内容"
      v-model="text"
      maxlength="800"
      show-word-limit
      @blur="InputChange"
    />
    <el-upload
      :action="$Config.hostUrl + '/Upload/UploadPublicApi/uploadFile'"
      list-type="picture-card"
      :on-change="handleChange"
      :on-preview="handlePictureCardPreview">
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- <ImgUpload :value="imgArr" @input="ImgChange" /> -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
//   import ImgUpload from './img-upload-copy'
  export default {
    data () {
      return {
        imgList: [],
        value: '',
        text: '',
        dialogImageUrl: '',
        dialogVisible: false
      }
    },
    methods: {
      handleChange (file, fileList) {
        let arr = []
        fileList.forEach(item => {
          if (item.response) {
            arr.push({ url: item.response.data.url })
          }
        })
        // this.imgList = arr
        this.ImgChange(arr)
      },
      handlePictureCardPreview (file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      InputChange () {
        this.$emit('onAnswer', {
          topicDetails: this.text,
          topicDetailsImg: this.imgList
        })
      },
      ImgChange (list) {
        this.imgList = list
        this.$emit('onAnswer', {
          topicDetails: this.text,
          topicDetailsImg: list
        })
      }
    },
    props: {
      index: {
        type: null,
        default: ''
      },
      imgArr: null,
      topicDetails: null,
      topicDetailsImg: null,
      topic_name: null,
      question_name: null,
      topic_type_name: null,
      question_requirements: null,
      question_score: null,
      topic_id: null
    }
  }
</script>

<style lang="less" scoped>
.writing-item {
  overflow: hidden;
  .mb(0.4rem);
  .title {
    .fz(0.34rem);
    .c(#333);
    .mb(0.3rem);
    .lh(1.4);
    .score {
      .fz(0.28rem);
      .c(#CA0000);
    }
  }
   /deep/ .el-upload--picture-card {
    width: 110px;
    height: 110px;
    position: relative;
    }
    /deep/ .el-icon-plus:before {
    content: '';
    width: 28px;
    height: 28px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('/static/images/icon/upload.png');
    }
    /deep/ .el-icon-plus:after {
    content: '上传图片';
    position: absolute;
    width: 100px;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 20px;
    color: #C4C4C4;
    }
    /deep/ .el-textarea, /deep/ .el-textarea textarea {
    width: 100%;
    height: 170px;
    background: #F9F9F9;
    opacity: 1;
    border-radius: 10px;
    margin-bottom: 20px;
    }
    /deep/ .el-upload-list li {
    width: 110px;
    height: 110px;
    position: relative;
    }
  .desc {
    .lh(1.4);
    .fz(0.28rem);
    .c(#333);
    white-space: pre-wrap
  }
  .tips {
    .lh(1.6);
    .fz(0.24rem);
    .c(#999);
    .mb(0.5rem);
  }
  .input-title {
    .fz(0.3rem);
    .c(#333);
    .mb(0.39rem);
    span {
      .c(#ff4366);
    }
  }
  .text-area {
    display: block;
    .w();
    .h(2.44rem);
    .br(0.1rem);
    box-sizing: border-box;
    .p(0.25rem 0.3rem);
    .bg(#fafafa);
    .fz(0.3rem);
    resize: none;
    .mb(0.4rem);
  }
}
</style>

<template>
  <div class="ShowOff-page">
    <img src="/static/images/showoff/1.png" alt="">
    <img src="/static/images/showoff/2.png" alt="">
    <img src="/static/images/showoff/3.png" alt="">
    <img src="/static/images/showoff/4.png" alt="">
  </div>
</template>

<script>
  export default {
  }
</script>

<style lang="less" scoped>
.ShowOff-page {
  img {
    width: 100%;
  }
}
</style>

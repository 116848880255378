<template>
  <div class="cutomnav">
    <div v-for="(item, index) in list" :key="index" :class="{active: $route.meta.side === index}" class="nav-item" @click="clickNav(item)">{{item.name}}</div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        list: [
          {
            name: '我的课程',
            url: 'Course.MyCourse'
          },
          {
            name: '优惠中心',
            url: 'Course.Discounts'
          },
          {
            name: '我的优惠券',
            url: 'Course.MyDiscounts'
          },
          {
            name: '报名订单',
            url: 'Course.ApplyOrder'
          },
          {
            name: '绑定账号',
            url: 'Course.Binding'
          },
          {
            name: '最近购买',
            url: 'Course.RecentPurchase'
          }
        ]
      }
    },
    methods: {
      clickNav ({ url }) {
        this.$nav(url)
      }
    }
  }
</script>

<style lang="less" scoped>
  .cutomnav {
    .flex-ccc();
    border-radius: 10px;
    overflow: hidden;
    .nav-item {
      .flex-cc();
      width: 140px;
      height: 94px;
      background: #FFFFFF;
      cursor: pointer;
      white-space: nowrap;
      font-size: 16px;
      position: relative;
      color: #888;
      &.active {
        color: #CA0000;
        font-weight: bold;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 4px;
          height: 24px;
          background: linear-gradient(180deg, #CA0000 0%, rgba(202, 0, 0, 0) 100%);
          border-radius: 2px;
        }
      }
    }
  }
</style>

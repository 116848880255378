<template>
  <div class="mycourse">
    <div class="page box">
      <template v-if="side === 1 && all_list.length">
        <TiliteNav>全套课程</TiliteNav>
        <div class="mb40">
          <CoureList :list="all_list" />
        </div>
      </template>
      <TiliteNav>{{clist[side]['name']}}</TiliteNav>
      <div class="block">
        <CoureList :list="list" />
      </div>
      <template v-if="side === 1 && book_list.length">
        <TiliteNav>教材</TiliteNav>
        <div class="mb40">
          <CoureList :list="book_list" />
        </div>
      </template>
      <!-- <div class="flex-cc">
        <Paging v-model="page" :total="total_pages" :items="total_items" :limit="20" />
      </div> -->
      <div class="side-box">
        <div class="side-sticky">
          <SideNav v-model="side" :list="clist" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CoureList from '../../components/CourseList'
  import SideNav from '../../components/SideNav'
  import { ListPage } from '@/utils/Mixin'
  export default {
    mixins: [ListPage],
    data () {
      return {
        side: 0,
        clist: [
          {
            name: '试看课程',
            url: 'Common.tryList',
            key: 'try'
          },
          {
            name: '系列课程',
            url: 'Common.goodsList',
            key: 'items'
          },
          {
            name: '线上习题',
            url: 'Common.alonePaperList',
            key: 'items'
          }
        ],
        all_list: [],
        book_list: []
      }
    },
    methods: {
      async GetList () {
        let { data, status } = await this.$Get(this.clist[this.side]['url'], {
          page: this.page,
          limit: 9999,
          group_id: 0
        })
        if (status) {
          this.total_pages = data.total_pages
          this.total_items = data.total_items
          let list = data[this.clist[this.side]['key']]
          if (this.side === 1) {
            let allList = []
            let bookList = []
            let cList = []
            list.forEach(item => {
              if (item.goods_type === 'book_type') {
                bookList.push(item)
              } else if (item.is_all * 1 === 1) {
                allList.push(item)
              } else {
                cList.push(item)
              }
              this.all_list = allList
              this.book_list = bookList
              this.list = cList
            })
          } else {
            this.list = list
          }
          scrollTo(0, 0)
        }
      }
    },
    watch: {
      side (to) {
        this.RefreshList()
      }
    },
    components: {
      SideNav,
      CoureList
    }
  }
</script>

<style lang="less" scoped>
  .mycourse {
    min-width: 1200px;
    padding: 0 0 90px 0;
    .flex-ccc();
    .mb40 {
      margin-bottom: 40px;
    }
    .block {
      padding-bottom: 40px;
      min-height: 500px;
    }
    .box {
      padding-left: 170px;
      transform: translateX(-85px);
      position: relative;
    }
    .mycourse-box {
      width: 1200px;
      .flex-cc();
      .mycourse-date-box {
        width: 1200px;
        height: 146px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        margin-bottom: 60px;
        .flex-c();
        .mycourse-date-item {
          .flex-1();
          .mycourse-date-title {
            height: 31px;
            font-size: 24px;
            font-weight: bold;
            line-height: 31px;
            color: #333333;
            text-align: center;
            margin-bottom: 15px;
          }
          .mycourse-date-block {
            .flex-1();
            .flex-cc();
            height: 37px;
            font-size: 20px;
            line-height: 26px;
            color: #333333;
            span {
              font-size: 28px;
              color: #CA0000;
            }
          }
        }
      }
    }
    .side-box {
      position: absolute;
      width: 140px;
      height: 100%;
      top: 0;
      left: 0;
      .side-sticky {
        position: sticky;
        top: 164px;
      }
    }
  }
</style>

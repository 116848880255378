<template>
  <div class="flex-c discounts-items">
    <div class="discounts-item" v-for="(item, index) in list" :key="index">
      <div class="discounts-info">
        <div class="discounts-price wechatNumber"><span>￥</span>1000</div>
        <div class="discounts-main">
          <div class="discounts-name line-clamp">某某优惠券名称</div>
          <div class="discounts-desc">
            <div class="discounts-label">{{ item.label }}</div>
            <div class="discounts-text line-clamp">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="discounts-date">
        有效期至：<i>2020.09.23</i><span>（还剩2天）</span>
      </div>
      <div class="discounts-rule">
        <span>使用规则：</span>某某规则内容某某规则内容某某规则内容某某规则内容某某规则内容某某规则内容某某规则内容某某规则内容
      </div>
      <template v-if="!item.draw">
        <div class="discounts-btn" @click="clickDarw(index)">立即领取</div>
      </template>
      <template v-else>
        <img :src="$img('IconDiscountsOne')" class="discounts-img">
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'course',
    data () {
      return {
        list: [
          {
            draw: false,
            label: '折扣券',
            desc: '全品类8.9折'
          },
          {
            draw: false,
            label: '折扣券',
            desc: '全品类8.9折'
          },
          {
            draw: true,
            label: '折扣券',
            desc: '全品类8.9折'
          },
          {
            draw: false,
            label: '满减券',
            desc: '满1000立减100'
          },
          {
            draw: false,
            label: '满减券',
            desc: '满1000立减100'
          },
          {
            draw: false,
            label: '满减券',
            desc: '满1000立减100'
          }
        ]
      }
    },
    methods: {
      clickDarw (i) {
        this.$set(this.list, i, { ...this.list[i], draw: true })
      }
    }
  }
</script>

<style lang="less" scoped>
  .discounts-items {
    width: 1200px;
    .flex-wrap();
    .discounts-item {
      width: 374px;
      height: 396px;
      box-sizing: border-box;
      border-radius: 10px;
      margin-right: 39px;
      overflow: hidden;
      position: relative;
      padding: 30px;
      background: url('/static/images/discountsbg.png');
      filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.06));
      .discounts-info {
        .flex();
        padding-bottom: 54px;
        .discounts-price {
          width: 124px;
          font-size: 32px;
          line-height: 44px;
          color: #CA0000;
          .line-clamp-multi(1);
          span {
            font-size: 16px;
          }
        }
        .discounts-main {
          .flex-1();
          .discounts-name {
            font-size: 18px;
            line-height: 24px;
            color: #333333;
            margin-bottom: 6px;
          }
          .discounts-desc {
            .flex-c();
            .discounts-label {
              width: 50px;
              height: 22px;
              background: #FF8D8D;
              border-radius: 2px;
              margin-right: 8px;
              font-size: 14px;
              color: #fff;
              .flex-cc();
            }
            .discounts-text {
              font-size: 14px;
              line-height: 19px;
              color: #888888;
            }
          }
        }
      }
      .discounts-date {
        color: #888;
        font-size: 14px;
        margin-bottom: 24px;
        i {
          color: #b2b2b2;
        }
        span {
          color: #CA0000;
        }
      }
      .discounts-rule {
        font-size: 14px;
        line-height: 26px;
        color: #B2B2B2;
        span {
          color: #888;
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(n+4) {
        margin-top: 40px;
      }
      .discounts-btn {
        position: absolute;
        bottom: 52px;
        left: 50%;
        transform: translateX(-50%);
        width: 120px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #CA0000;
        border-radius: 20px;
        font-size: 18px;
        color: #CA0000;
        cursor: pointer;
        .flex-cc();
        line-height: normal;
        &:hover {
          background: #CA0000;
          color: #fff;
        }
      }
      .discounts-img {
        width: 80px;
        height: 80px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 32px;
      }
    }
  }
</style>

<template>
	<div class="Precipitate-container">
		<div class="item type1">
			<div class="cover-warp">
				<img class="cover" src="/static/images/home/Precipitate/1.png" alt="">
			</div>
			<div class="data-warp" v-if="teaching_background">
				<img class="cover" :src="teaching_background" alt="">
			</div>
		</div>
		
		<div class="item type2">
			<div class="data-warp">
				<div class="table-warp">
					<div class="row th">
						<div class="col">
							姓名
						</div>
						<div class="col">
							原英语成绩
						</div>
						<div class="col">
							日语
						</div>
						<div class="col">
							总分
						</div>
						<div class="col">
							文/理
						</div>
						<div class="col">
							学习时长
						</div>
					</div>

					<div class="row" v-for="(i,k) in list" :key="k">
						<div class="col">
							{{i.name || ''}}
						</div>

						<div class="col">
							{{i.english_score || ''}}
						</div>

						<div class="col active">
							{{i.japanese_score || ''}}
						</div>

						<div class="col">
							{{i.total_score || ''}}
						</div>

						<div class="col">
							{{i.types || ''}}
						</div>

						<div class="col">
							{{i.term || ''}}
						</div>
					</div>
				</div>

				<div class="el-pagination-warp">
					<el-pagination layout="prev, pager, next" :page-size="limit" :total="total_items"
						@current-change="currentChange"></el-pagination>
				</div>

			</div>
			<div class="cover-warp">
				<img class="cover" src="/static/images/home/Precipitate/3.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Precipitate',
		props: {
			teaching_background: {
				default: ""
			}
		},
		watch: {

		},
		mounted() {
			this.GetList()
		},
		data() {
			return {
				list: [],
				page: 1,
				limit: 10,
				total_items: 0
			}
		},
		methods: {
			currentChange(e) {
				this.page = e
				this.GetList()
			},
			async GetList() {
				let {
					data,
					status
				} = await this.$Get('Common.getAchievementList', {
					page: this.page,
					limit: this.limit
				})
				if (status && data) {
					this.list = data.items
					this.total_items = data.total_items || 0
				}
			}
		},
		computed: {

		}
	}
</script>

<style lang="less" scoped>
	.Precipitate-container {
		position: relative;
		box-sizing: border-box;
		padding: 0 50px;
		width: 1300px;
		overflow: hidden;
		
		.teaching_background {
			display: block;
			width: 100%;
			margin-bottom: 60px;
		}

		.item {
			position: relative;
			display: flex;
			align-items: center;
			margin-bottom: 60px;

			&.type1 {
				.cover-warp {
					margin-right: 40px;
				}
			}

			&.type2 {
				.data-warp {
					flex: 1;
					margin-right: 40px;
				}
			}

			.cover-warp {
				width: 581px;
				height: 569px;

				.cover {
					display: block;
					width: 581px;
					height: 569px;
				}
			}

			.data-warp {
				.cover {
					position: relative;
					top: 40px;
					width: 600px;
					height: 365px;
				}

				.table-warp {
					margin: 0 auto;

					.row {
						display: flex;

						&:nth-child(odd) {
							background-color: #f8f8f8;
						}

						&.th {
							height: 37px;
							line-height: 37px;
							background: linear-gradient(270deg, #FCE4D6 0%, #FFD9D9 100%);
							border-radius: 10px;

							.col {
								font-size: 12px;
								font-family: Microsoft YaHei;
								font-weight: bold;
								line-height: 35px;
								color: #333333;
								opacity: 1;

							}
						}

						.col {
							flex: 1;
							height: 34px;
							line-height: 34px;
							text-align: center;
							font-size: 12px;
							font-weight: 400;
							color: #888888;

							&.active {
								color: #E16D2D;
							}
						}
					}
				}

				.el-pagination-warp {
					margin: 20px auto 0;
					width: 315px;

					& /deep/ .el-pagination {
						display: flex;
						align-items: center;
						justify-content: space-between;
					}

					& /deep/ button,
					& /deep/ .el-pager li {
						background-color: transparent !important;
						color: #B2B2B2 !important;
						font-size: 12px !important;

						&.active:not(.disabled) {
							color: #ffffff !important;
							background-color: #CA0000 !important;
							border-radius: 24px;
						}
					}

					& /deep/ .el-pagination .btn-prev,
					& /deep/.el-pagination .btn-next {
						padding: 0 !important;

						&:disabled {
							opacity: .5;
						}

						i {
							display: block;
							width: 30px !important;
							height: 30px !important;
							line-height: 30px !important;
							border-radius: 20px;
							font-size: 18px;
							background-color: #ffffff;
							box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
						}
					}
				}
			}
		}
	}
</style>

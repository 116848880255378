export default {
  data () {
    return {
      // 列表
      list: [],
      // 页数
      page: 1,
      // 总页数
      total_pages: 0,
      // 总条数
      total_items: 0,
      load_status: false,
      refresh_status: false
    }
  },
  watch: {
    page (to, pev) {
      if (to) {
        this.ListFun()
      }
    }
  },
  methods: {
    ListFun () {
      if (typeof this.GetList === 'function') {
        this.GetList()
      }
    },
    // 刷新数据
    RefreshList () {
      this.page = 1
      this.list = []
      this.ListFun()
    },
    // 处理回调数据
    HandleRes (data) {
      this.total_pages = data.total_pages
      this.total_items = data.total_items
      this.list = data.items
      // if (this.page === 1) {
      //   this.list = data.items
      // } else {
      //   this.list = [...this.list, ...data.items]
      // }
    }
  },
  mounted () {
    if (!this.close_mounted) {
      this.page = 1
      this.ListFun()
    }
  }
}

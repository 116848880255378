<template>
  <div class="pages">
    <div class="page box">
      <TiliteNav />
      <div class="look-detail">
        <div class="look-detail-head">
          <div class="look-detail-head-left">
            <img :src="goods.thumb" class="bigimg">
          </div>
        </div>
        <div class="look-detail-head-two">
          <div class="flex-1">
            <div class="look-detail-head-two-title">
              {{goods.title}}
            </div>
          </div>
          <template v-if="goods.my_purchase * 1 === 1">
            <div class="look-detail-head-two-btn" @click="$nav('Campus.ProblemSetsDetails', {id: $route.query.id})">开始答题</div>
          </template>
          <template v-else>
            <div class="look-detail-head-two-btn" @click="handlePay">购买</div>
          </template>
        </div>
        <TiliteNav>
          <div class="title">习题简介</div>
        </TiliteNav>
        <div class="look-detail-content" v-html="goods.content"></div>
      </div>
      <div class="side-box">
        <div class="side-sticky">
          <SideNav />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SideNav from '../../components/SideNav'
  export default {
    data () {
      return {
        goods: {},
        isNeedShipping: null,
        recommend: []
      }
    },
    mounted () {
      this.goodsDetails()
    },
    watch: {
      '$route.query.id' (to) {
        if (to) {
          this.goodsDetails()
        }
      }
    },
    methods: {
      async goodsDetails () {
        let { status, data } = await this.$Get('Common.goodsDetails', {
          id: this.$route.query.id
        })
        if (status) {
          this.goods = data.goods
          this.isNeedShipping = data.isNeedShipping
          this.recommend = data.recommend
        }
      },
      handlePay () {
        if (this.isPay) {
          this.$nav('Campus.ProblemSetsDetails', { id: this.$route.query.id })
        } else {
          this.$Bus.$emit('Pay', { id: this.$route.query.id })
        }
      }
    },
    components: {
      SideNav
    }
  }
</script>

<style lang="less" scoped>
.pages {
  min-width: 1200px;
  padding: 0 0 90px 0;
  .flex-ccc();
  .box {
    padding-left: 170px;
    transform: translateX(-85px);
    position: relative;
  }
  .side-box {
    position: absolute;
    width: 140px;
    height: 100%;
    top: 0;
    left: 0;
    .side-sticky {
      position: sticky;
      top: 164px;
    }
  }
}
  .page {
    margin: auto;
    .popup-box {
      width: 680px;
      height: 680px;
    }
    .look-detail {
      width: 1200px;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      margin-bottom: 90px;
      box-sizing: border-box;
      padding: 40px;
      .look-detail-head {
        .flex-c();
        margin-bottom: 30px;
        .look-detail-head-left {
          width: 100%;
          height: 462px;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          .bigimg {
            width: 100%;
            height: 100%;
          }
          .play-img {
            width: 72px;
            height: 72px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }
      .look-detail-head-two {
        margin-bottom: 10px;
        .flex-c();
        .look-detail-head-two-title {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          color: #333333;
          .line-clamp();
        }
        .look-detail-head-two-btn {
          width: 180px;
          height: 50px;
          background: #CA0000;
          box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
          border-radius: 25px;
          font-size: 18px;
          color: #fff;
          .flex-cc();
          margin-left: 30px;
        }
      }
      /deep/ .title-nav {
        &::before {
          height: 28px;
        }
      }
      .title {
        height: 32px;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: #333333;
      }
      .look-detail-content {
        font-size: 16px;
        line-height: 32px;
        color: #888888;
        margin-bottom: 20px;
        margin-top: -20px;
      }
    }
  }
</style>

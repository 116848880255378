<template>
	<div class="AboutUs-page">
		<!-- 导航 -->
		<div class="tab-warp">
			<div class="btn" :class="{'active':i.id === navActive}" v-for="(i,k) in navList" :key="k"
				@click="handleNavItem(i)">{{i.name}}</div>
		</div>
		<schoolIntroduction :show="navActive===1" />

		<div class="teachers-wrap" v-show="navActive===2">
			<teacherIntroduction />
			<div class="list-wrap">
				<TeachersList :list="list" />
				<div class="pageing-wrap">
					<Paging v-model="page" :total="total_pages" :items="total_items" :limit="20" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import schoolIntroduction from './components/schoolIntroduction.vue'
	import teacherIntroduction from './components/teacherIntroduction.vue'
	import TeachersList from './components/TeachersList'
	import {
		ListPage
	} from '@/utils/Mixin'
	export default {
		mixins: [ListPage],
		components: {
			schoolIntroduction,
			teacherIntroduction,
			TeachersList
		},
		data() {
			return {
				navActive: 1,
				navList: [{
						id: 1,
						name: '学校介绍'
					},
					{
						id: 2,
						name: '师资介绍'
					}
				]
			}
		},
		watch: {
			$route(to) {
				if (typeof(this.$route.query.navActive) !== 'undefined') {
					this.navActive = parseInt(this.$route.query.navActive)
				}
			}
		},
		methods: {
			handleNavItem(i) {
				this.navActive = i.id
			},
			async GetList() {
				let {
					data,
					status
				} = await this.$Get('Common.getArticleListV2', {
					page: this.page,
					limit: 20,
					type: 'school_teachers'
					// is_hot: 1
				})
				if (status) {
					this.HandleRes(data)
					this.about = data.about
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.AboutUs-page {
		.tab-warp {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 37px;
			background: #ffffff;

			.btn {
				margin: 0 50px;
				width: 204px;
				height: 52px;
				cursor: pointer;
				border-radius: 25px;
				color: #b2b2b2;
				font-size: 18px;
				line-height: normal;
				.flex-cc();

				&.active {
					background: #ca0000;
					box-shadow: 0px 16px 20px rgba(202, 0, 0, 0.2);
					color: #ffffff;
				}
			}
		}

		.teachers-wrap {
			background: #ca0000;

			.list-wrap {
				margin: 40px auto 0;
				width: 1200px;

				.pageing-wrap {
					display: flex;
					justify-content: center;
					padding: 30px 0;
				}
			}
		}
	}
</style>

<template>
    <div class="Broadcast-page">
        <TiliteNav>
            <div class="title">五十音图</div>
        </TiliteNav>

        <!-- 导航 -->
        <div class="tab-warp" v-if="data">
            <div class="btn" :class="{'active':i.id === screening_id}" v-for="(i,k) in screeningList" :key="k" @click="handleScreeningItem(i)">{{i.name}}</div>
        </div>

        <!-- 五十音列表 -->
        <div class="broadcast-box-warp" v-if="data">
            <div class="broadcast-box">
                <div class="margin-list left-list">
                    <div v-if="left_list && showLeftList">
                        <div class="item" v-for="(i,k) in left_list" :key="k">
                            <div class="name">
                                {{i.name}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="center-box" v-if="data">
                    <div class="margin-list top-list" v-if="top_list && showTopList">
                        <div class="item" v-for="(i,k) in top_list" :key="k">
                            {{i.name}}
                        </div>
                    </div>
                    <div class="broadcast-list" :class="{'hide-top-list':!showTopList,'v2':navStyle==='v2'}">
                        <div class="col" v-for="(i,k) in top_list" :key="k">
                            <div class="child" :class="{'disable':c.isRead,'active':c.cid===activeChild.cid,'bg':c.top_id}"
                                v-for="(c,ck) in i.broadcast" :key="ck" @click="handleContentListItem(i,k,c,ck)">
                                {{ck}}
                                <div class="name">
                                    {{c.flat_name}}
                                </div>
                                <div class="text">
                                    {{c.of_name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 弹出层 -->
        <div class="my-dialog-warp" v-if="activeChild">
            <el-dialog width="390px" top="30vh" :show-close="false" :visible.sync="myDialogVisible">
                <div class="dialog-warp" v-if="activeChild.cid">
                    <div class="title">
                        {{activeChild.flat_name}}
                    </div>
                    <div class="list">
                        <div class="item">
                            <div class="value">{{activeChild.flat_name||''}}</div>
                            <div class="key">平假名</div>
                        </div>

                        <div class="item">
                            <div class="value">{{activeChild.of_name||''}}</div>
                            <div class="key">片假名</div>
                        </div>
                        <div class="item">
                            <div class="value">{{activeChild.name||''}}</div>
                            <div class="key">罗马字</div>
                        </div>
                    </div>

                    <!-- 播放按钮 -->
                    <div class="play-btn" :class="{'active':audioPlay && !activeSubChild.sid}" @click="handlePlay"></div>

                    <div class="bottom" v-if="activeChild.content&&activeChild.content.length">
                        <div class="content-warp">
                            <div class="content-item" v-for="(i,k) in activeChild.content" :key="k" @click="handleSubChild(i)">
                                <div class="row">
                                    <div class="play-btn" :class="{'active':audioPlay && activeSubChild.sid===i.sid}"></div>
                                </div>
                                <div class="row">
                                    <div class="value">{{i.flat_name||''}}</div>
                                </div>

                                <div class="row">
                                    <div class="value">{{i.of_name||''}}</div>
                                </div>
                                <div class="row">
                                    <div class="value">{{i.name||''}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="close-btn" @click="handleCloseMyDialog"></div>
                </div>
            </el-dialog>
        </div>

        <!-- 插件,已经隐藏 -->
        <div class="aplayer-warp" v-if="music.src">
            <aplayer :music="music" ref="player" @pause="onPause" @playing="onPlaying" @emptied="onEmptied" @ended="onEnded"
                @error="onError" @abort="onAbort"></aplayer>
        </div>

    </div>
</template>

<script>
  import aplayer from 'vue-aplayer'
  export default {
    data () {
      return {
        screening_id: '',
        screeningList: [],
        data: null,
        // 子
        activeChild: {
          cid: ''
        },
        // 孙
        activeSubChild: {
          sid: ''
        },
        top_list: [],
        left_list: [],
        // 显示 dialog
        myDialogVisible: false,
        // 播放状态
        audioPlay: false,
        music: {
          src: ''
        },
        // 非播放器参数
        autoPlay: false,
        timeStamp: '',
        showLeftList: false,
        showTopList: false,

        style: 'v1'
      }
    },
    computed: {
      navStyle () {
        // 默认v1 拗音 v2
        if (this.screening_id) {
          let item = this.screeningList.find(i => { return i.id == this.screening_id })
          return item.style
        }
        return 'v1'
      }
    },
    watch: {
      myDialogVisible (n) {
        if (!n) {
          // 清空音频
          this.music.src = ''
          this.activeChild = {
            cid: ''
          }
          this.activeSubChild = {
            sid: ''
          }
          this.autoPlay = false
        }
      },
      screening_id (n) {
        if (n) {
          this.broadcast()
        }
      }
    },
    methods: {
      // 点击播放孙子
      handleSubChild (i) {
        if (!this.activeSubChild.sid || this.activeSubChild.sid !== i.sid) {
          this.activeSubChild = i
          this.music.src = i.mp3_url || ''
          if (this.$refs.player) {
            if (this.audioPlay) {
              this.$refs.player.pause()
            }
            this.$refs.player.audio.setAttribute('src', this.music.src)
            this.autoPlay = true
          }
        } else {
          this.play()
        }
      },
      async broadcastGroup () {
        let {
          data,
          status
        } = await this.$Get('Common.broadcastGroup')
        if (status) {
          this.screeningList = data || []
          if (!this.screening_id && data[0]) {
            this.screening_id = data[0].id
            this.broadcast()
          }
        }
      },
      async broadcast () {
        this.top_list = []
        this.left_list = []
        this.timeStamp = new Date().getTime()
        const timeStamp = this.timeStamp
        const screening_id = this.screening_id

        let {
          data,
          status
        } = await this.$Get('Common.broadcast', {
          screening_id: this.screening_id
        })

        if (status && data && timeStamp === this.timeStamp && screening_id === this.screening_id) {
          this.data = data

          let showLeftList = false
          let showTopList = false

          if (data.left_list && data.left_list.length > 0) {
            this.left_list = data.left_list || []
            showLeftList = data.top_list.some(i => i.is_text == 1)
          }

          if (data.top_list && data.top_list.length > 0) {
            let list = []
            let k = 0
            for (let i of data.top_list) {
              // 一个 is_text == 1 或以上，则显示top_list
              if (!showTopList && i.is_text == 1) {
                showTopList = true
              }

              let item = {
                id: i.id,
                name: i.name
              }
              let broadcast = []
              if (i.broadcast && i.broadcast.length) {
                let ck = 0
                for (let c of i.broadcast) {
                  // 此处判断会忽略占位，此处应该由后台处理，而不是前端判断
                  if (c && c.top_id) {
                    let content = []
                    if (c.content && c.content.length) {
                      let sk = 0
                      for (let s of c.content) {
                        content.push({
                          ...s,
                          sid: `${k}_${ck}_${sk}`
                        })
                        sk++
                      }
                    }

                    broadcast.push({
                      ...c,
                      // 是否已读
                      isRead: false,
                      // id
                      cid: `${k}_${ck}`,
                      content
                    })
                    ck++
                  }
                }
              }
              item.broadcast = broadcast
              list.push(item)
              k++
            }

            this.top_list = list
            console.log('this.top_list ', this.top_list)
          }

          this.showLeftList = showLeftList
          this.showTopList = showTopList
        }
      },
      handleScreeningItem (i) {
        this.screening_id = i.id
      },
      // 点击音元素
      handleContentListItem (i, k, c, ck) {
        if (!c.top_id) return false

        // 选中元素
        this.activeChild = c
        // 显示dialog
        this.myDialogVisible = true

        // 设置音频
        if (c.mp3_url) {
          this.music.src = c.mp3_url || ''
        }
        this.audioPlay = false
      },
      // 点击播放子
      handlePlay () {
        let cid = this.activeChild.cid || null

        if (cid) {
          let [k, ck] = cid.split('_')
          // 设置已读
          this.top_list[k].broadcast[ck].isRead = true
        }

        if (this.activeSubChild.sid) {
          if (this.$refs.player) {
            this.$refs.player.pause()
          }

          this.activeSubChild = {
            sid: ''
          }
          this.music.src = this.activeChild.mp3_url || ''
          this.$refs.player.audio.setAttribute('src', this.music.src)
          this.autoPlay = true
        } else {
          this.play()
        }
      },
      play () {
        var player = this.$refs.player || null
        if (player && !this.audioPlay) {
          player.play()
        } else {
          player.pause()
        }
      },
      handleCloseMyDialog () {
        this.myDialogVisible = false
      },
      // 播放结束时触发。
      onEnded () {
        this.audioPlay = false
        console.log('播放结束时触发', this.audioPlay)
      },
      // 媒体被清空（初始化）时触发。
      onEmptied () {
        this.audioPlay = false
        if (this.autoPlay) {
          this.play()
          this.autoPlay = false
        }
        console.log('媒体被清空（初始化）时触发。', this.audioPlay)
      },
      // 在发生错误时触发
      onError () {
        this.audioPlay = false
        console.log('在发生错误时触发。', this.audioPlay)
      },
      // 在播放被终止时触发
      onAbort () {
        this.audioPlay = false
        console.log('在播放被终止时触发。', this.audioPlay)
      },
      // 播放暂停时触发。
      onPause () {
        this.audioPlay = false
        console.log('播放暂停时触发。', this.audioPlay)
      },
      // 在媒体开始播放时触发（不论是初次播放、在暂停后恢复、或是在结束后重新开始）。
      onPlaying () {
        this.audioPlay = true
        console.log('在媒体开始播放时触发。', this.audioPlay)
      }
    },
    mounted () {
      this.broadcastGroup()

      // 测试
      // setTimeout(() => {
      //     // this.screening_id = 11
      //     // let c = this.top_list[1].broadcast[2]
      //     // this.handleContentListItem(0, 0, c, 2)
      // }, 500)
    },
    components: {
      aplayer
    }
  }
</script>

<style lang="less" scoped>
    .Broadcast-page {
        margin: auto;
        width: 1200px;
        padding-bottom: 90px;

        .btn {
            width: 240px;
            height: 50px;
            cursor: pointer;
            border-radius: 25px;
            color: #b2b2b2;
            font-size: 18px;
            line-height: normal;
            .flex-cc();

            &.active {
                background: #ca0000;
                box-shadow: 0px 16px 20px rgba(202, 0, 0, 0.2);
                color: #ffffff;
            }
        }

        .tab-warp {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
        }

        .broadcast-box-warp {
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);

            .broadcast-box {
                position: relative;
                left: -40px;
                display: flex;
                justify-content: center;

                .center-box {
                    position: relative;
                    padding-bottom: 30px;
                    min-width: 846px;
                    overflow-x: auto;

                    .broadcast-list {
                        display: flex;
                        justify-content: space-around;

                        &.hide-top-list {
                          margin-top: 30px
                        }

                        .col {
                            margin-right: 24px;
                            width: 150px;

                            // &:nth-child(5n) {
                            //     margin-right: 0;
                            // }
                            &:last-child {
                                margin-right: 0;
                            }

                            .child {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                box-sizing: border-box;
                                margin-bottom: 24px;
                                height: 80px;
                                border-radius: 10px;
                                color: #333333;

                                &.bg {
                                    background-color: #f9f9f9;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &.disable {
                                    background-color: #f9f9f9;
                                    color: #b2b2b2;
                                }

                                &.active {
                                    background: #CA0000;
                                    color: #ffffff;
                                }

                                .name {
                                    padding-bottom: 5px;
                                    font-size: 20px;
                                    font-weight: bold;
                                }

                                .text {
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }

                .margin-list {
                    &.top-list {
                        display: flex;

                        .item {
                            margin-right: 24px;
                            width: 150px;
                            height: 80px;

                            // &:nth-child(5n) {
                            //     margin-right: 0;
                            // }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    &.left-list {
                        position: relative;
                        padding-top: 80px;
                        width: 80px;

                        .item {
                            margin-bottom: 24px;
                            width: 80px;
                            height: 80px;
                            text-align: center;
                        }
                    }

                    .item {
                        flex-direction: column;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #888888;
                    }
                }
            }
        }

        .my-dialog-warp {
            /deep/ .el-dialog {
                border-radius: 10px !important;

                .el-dialog__body {
                    padding: 0 30px 40px !important;
                }
            }

            .dialog-warp {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title {
                    margin-bottom: 18px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 24px;
                    font-weight: bold;
                    color: #333333;
                }

                .list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    margin-bottom: 26px;
                    padding: 0 30px;
                    width: 100%;
                    height: 74px;
                    background: #F6F6F6;
                    border-radius: 10px;

                    .item {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;

                        .value {
                            margin-bottom: 12px;
                            font-size: 20px;
                            font-weight: 400;
                            color: #333333;
                        }

                        .key {
                            font-size: 16px;
                            font-weight: 400;
                            color: #888888;
                        }

                    }
                }

                .play-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    margin-bottom: 26px;
                    width: 72px;
                    height: 72px;
                    background: #FFFFFF;
                    border: 2px solid #E5E5E5;
                    border-radius: 36px;
                    cursor: pointer;

                    &::before {
                        content: " ";
                        display: block;
                        width: 36px;
                        height: 36px;
                        background-image: url('/static/images/Broadcast/play-1.png');
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    &.active::before {
                        background-image: url('/static/images/Broadcast/play-2.png');
                    }
                }

                .bottom {
                    box-sizing: border-box;
                    padding: 20px;
                    width: 100%;
                    min-height: 160px;
                    background: #F6F6F6;
                    border-radius: 10px;

                    .content-warp {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .content-item {
                            margin-bottom: 20px;
                            width: 33%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            box-sizing: border-box;

                            .row {
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                justify-content: center;
                                margin-bottom: 10px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                .play-btn {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    box-sizing: border-box;
                                    margin-bottom: 0px;
                                    width: 40px;
                                    height: 40px;
                                    background: #FFFFFF;
                                    border: 2px solid #E5E5E5;
                                    border-radius: 25px;
                                    cursor: pointer;

                                    &::before {
                                        content: " ";
                                        display: block;
                                        width: 20px;
                                        height: 20px;
                                        background-image: url('/static/images/Broadcast/play-1.png');
                                        background-size: 100%;
                                        background-repeat: no-repeat;
                                    }

                                    &.active::before {
                                        background-image: url('/static/images/Broadcast/play-2.png');
                                    }
                                }

                                .value {
                                    // margin-bottom: 8px;
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #333333;
                                }

                                .key {
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #888888;
                                }
                            }
                        }
                    }

                    // .value {
                    //     margin-bottom: 14px;
                    //     font-size: 20px;
                    //     font-weight: 400;
                    //     color: #333333;
                    //     font-weight: bold;
                    // }

                    // .key {
                    //     font-size: 18px;
                    //     font-weight: 400;
                    //     color: #333333;
                    // }
                }

                .close-btn {
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    width: 50px;
                    height: 50px;
                    background-image: url('/static/images/Broadcast/close.png');
                    background-size: 14px 14px;
                    background-repeat: no-repeat;
                    background-position: 16px 20px;
                    cursor: pointer;
                }
            }
        }

        .aplayer-warp {
            position: fixed;
            top: 0;
            left: -100px;
            width: 0;
            height: 0;
            opacity: 0;
            overflow: hidden;
        }
    }
</style>

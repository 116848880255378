<template>
  <div class="flex-c course">
    <div class="course-item" v-for="(item, index) in list" :key="index" :class="{animation: animation}" @click="goNav(item)">
      <img src="/static/images/entrepot/course.png" />
      <div class="course-main">
        <div class="course-title">{{item.title}}</div>
        <div class="course-content">{{item.desc}}</div>
        <div class="course-btn" :class="{type1: item.type === 'video_type'}">{{getBtnText(item)}}</div>
        <div class="course-hot">{{item.num}}</div>
      </div>
      <img :src="$img('IconPlay')" class="play-img" v-if="item.type === 'video_type'">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'course',
    props: {
      animation: [Boolean],
      list: [Array]
    },
    methods: {
      goNav  (item) {
        let URL = ''
        let query = { id: item.id }
        switch (item.goods_type) {
        case 'video_type':
          URL = 'Campus.CheckCourseDetails'
          break
        case 'package_type':
          URL = 'Campus.SeriesCourses'
          break
        case 'alone_paper_type':
          URL = 'Campus.OnlineProblemSets'
          break
        default:
          query = { id: item.goods_id }
          URL = 'Campus.SeriesCoursesDetails'
          break
        }
        this.$nav(URL, query)
      },
      getBtnText (item) {
        let text = ''
        if (item.my_purchase * 1) {
          switch (item.goods_type) {
          case 'video_type':
            text = '观看'
            break
          case 'alone_paper_type':
            text = '练习'
            break
          default:
            text = '学习'
            break
          }
        } else {
          switch (item.goods_type) {
          case 'video_type':
            text = '试看'
            break
          default:
            text = '购买'
            break
          }
        }
        return text
      }
    }
  }
</script>

<style lang="less" scoped>
  .course {
    width: 1200px;
    .flex-wrap();
    .course-item {
      width: 270px;
      height: 302px;
      background: #F9F9F9;
      border-radius: 10px;
      margin-right: 40px;
      overflow: hidden;
      position: relative;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 40px;
      }
      .play-img {
        position: absolute;
        width: 40px;
        height: 40px;
        left: 16px;
        top: 16px;
      }
      .course-hot {
        position: absolute;
        left: 16px;
        bottom: 30px;
        font-size: 14px;
        line-height: 24px;
        color: #CA0000;
      }
      .course-btn {
        width: 88px;
        position: absolute;
        bottom: 20px;
        right: 20px;
        height: 40px;
        background: #FFDEDE;
        border-radius: 22px;
        font-size: 16px;
        color: #CA0000;
        cursor: pointer;
        .flex-cc();
        line-height: normal;
        &.type1, &.type1:hover {
          color: #333;
          background: #F0F0F0;
        }
        &:hover {
          background: #CA0000;
          color: #fff;
        }
      }
      .course-main {
        padding: 16px 16px 25px;
        .course-title {
          height: 26px;
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          margin-bottom: 16px;
          .line-clamp-multi(1);
        }
        .course-content {
          font-size: 14px;
          line-height: 24px;
          color: #B2B2B2;
          .line-clamp-multi(3);
          span {
            color: #888;
          }
        }
      }
      img {
        width: 270px;
        height: 152px;
      }
    }
  }
</style>

<template>
  <div class="binding">
    <div class="page box">
      <TiliteNav />
      <div class="block">
        <img src="/static/images/entrepot/bind.png" alt="">
        <div class="block-item">
          <template v-if="binding === 0">
            <div class="block-item-line">
              <div class="block-item-label">手机号码</div>
              <input type="text" class="block-item-input input" placeholder="请输入绑定账号" v-model="phone" />
            </div>
            <div class="block-item-line">
              <div class="block-item-label">验证码</div>
              <input type="text" class="block-item-input input2" v-model="verification" placeholder="请输入验证码" />
              <template>
                <div class="block-item-btn" :class="{ disabled: !CountDownStatus }"  @click="StartCountDown">{{CountDownTitle}}</div>
              </template>
            </div>
            <div class="block-btn" @click="bindingPhone">绑定</div>
          </template>
          <template v-if="binding === 1">
            <div class="block-toast">已绑定手机号码</div>
            <div class="block-phone">{{ cphone }}</div>
            <div class="block-btns">
              <div class="block-typebtn type1" @click="binding = 2">更换绑定</div>
              <div class="block-typebtn type2" @click="binding = 3">解除绑定</div>
            </div>
          </template>
          <template v-if="binding === 2 || binding === 3">
            <div class="block-toast toast1">已绑定手机号码</div>
            <div class="block-phone">{{ cphone }}</div>
            <div class="block-item-line" v-if="binding === 2">
              <input type="text" class="block-item-input input" placeholder="请输入绑定账号" v-model="phone" />
            </div>
            <div class="block-item-line mb60">
              <input type="text" class="block-item-input input2" placeholder="请输入验证码" v-model="verification" />
              <template>
                <div class="block-item-btn" :class="{ disabled: !CountDownStatus }"  @click="StartCountDown">{{CountDownTitle}}</div>
              </template>
            </div>
            <template v-if="binding === 3">
              <div class="block-btn typebtn" @click="unbindPhone">提交解除</div>
            </template>
            <template v-if="binding === 2">
              <div class="block-btn typebtn" @click="bindingPhone">提交更换</div>
            </template>
          </template>
        </div>
      </div>
      <div class="side-box">
        <div class="side-sticky">
          <SideNav />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SideNav from '../../components/SideNav'
  import { CountDown } from '@/utils/Mixin'
  export default {
    mixins: [CountDown],
    data () {
      return {
        CountDownTitle: '发送验证码',
        CountDownDefaultTitle: '重新获取',
        // 0：未绑定 1：绑定 2.更换绑定 3:解除绑定
        binding: 0,
        phone: '',
        cphone: '',
        verification: ''
      }
    },
    activated () {
      this.getBindingPhone()
    },
    methods: {
      // 获取手机号的绑定
      async getBindingPhone () {
        let { status, data } = await this.$Get('Course.getBindingPhone')
        if (status) {
          this.phone = data.phone
          this.cphone = data.phone
          if (this.phone) {
            this.binding = 1
          } else {
            this.phone = ''
            this.binding = 0
          }
        }
      },
      // 绑定手机号
      async bindingPhone () {
        let { status, msg } = await this.$Post('Course.bindingPhone', {
          phone: this.phone,
          verification: this.verification
        })
        if (status) {
          this.$ShowToast(msg, { icon: 'success' })
          this.verification = ''
          this.getBindingPhone()
        }
      },
      // 解绑用户手机号
      async unbindPhone () {
        let { status, msg } = await this.$Post('Course.unbindPhone', {
          verification: this.verification
        })
        if (status) {
          this.$ShowToast(msg, { icon: 'success' })
          this.verification = ''
          this.getBindingPhone()
        }
      },
      // 发送短信验证码
      async addition () {
        let { status, msg } = await this.$Post('Course.sendVerification', {
          phone: this.phone,
          action: 'register'
        })
        if (status) {
          this.$ShowToast(msg, { icon: 'success' })
        } else {
          this.FinishCountDown()
        }
      }
    },
    components: {
      SideNav
    }
  }
</script>

<style lang="less" scoped>
  .binding {
    min-width: 1200px;
    padding: 0 0 90px 0;
    .flex-ccc();
    .mb40 {
      margin-bottom: 40px;
    }
    .mb60 {
      margin-bottom: 60px;
    }
    .block {
      width: 1200px;
      min-height: 658px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      box-sizing: border-box;
      padding: 30px;
      .flex-ccc();
      img {
        width: 280px;
        height: 243px;
        margin-bottom: 38px;
      }
      .block-item {
        min-height: 320px;
        position: relative;
        .block-toast {
          width: 180px;
          height: 44px;
          background: #F0F0F0;
          border-radius: 22px;
          margin: auto;
          margin-bottom: 24px;
          .flex-cc();
          &.toast1 {
            background-color: transparent;
            margin-bottom: 14px;
            color: #888;
          }
        }
        .block-phone {
          width: 154px;
          height: 37px;
          font-size: 28px;
          font-family: WeChatNumber-151125;
          font-weight: 500;
          color: #333333;
          text-align: center;
          margin: auto;
          margin-bottom: 60px;
        }
        .block-btns {
          position: absolute;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
          .flex-c();
          .block-typebtn {
            width: 230px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 25px;
            font-size: 18px;
            line-height: normal;
            margin: 0 15px;
            cursor: pointer;
            .flex-cc();
            &.type1 {
              border: 1px solid #368DFF;
              color: #368DFF;
            }
            &.type2 {
              border: 1px solid #CA0000;
              color: #CA0000;
            }
          }
        }
        .block-item-line {
          margin-bottom: 50px;
          .flex-c();
          .block-item-label {
            width: 100px;
            text-align: right;
            margin-right: 64px;
          }
          .block-item-input {
            width: 360px;
            height: 44px;
            background: #F9F9F9;
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            box-sizing: border-box;
            &.input {
              padding: 0 28px;
            }
            &.input2 {
              padding: 0 28px;
              width: 212px;
              margin-right: 16px;
            }
          }
          .block-item-btn {
            width: 132px;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #CA0000;
            opacity: 1;
            border-radius: 10px;
            color: #CA0000;
            cursor: pointer;
            .flex-cc();
            &.disabled {
              border-color: #e5e5e5;
              color: #b2b2b2;
            }
          }
        }
      }
      .block-btn {
        width: 230px;
        height: 50px;
        background: #CA0000;
        box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
        color: #fff;
        border-radius: 25px;
        margin: 80px auto 0;
        .flex-cc();
        &.typebtn {
          margin-bottom: 50px;
        }
      }
    }
    .box {
      padding-left: 170px;
      transform: translateX(-85px);
      position: relative;
    }
    .side-box {
      position: absolute;
      width: 140px;
      height: 100%;
      top: 0;
      left: 0;
      .side-sticky {
        position: sticky;
        top: 164px;
      }
    }
  }
</style>

<template>
  <div class="hearing-item">
    <audio :src="url" id="musicMp3"></audio>
    <!-- 播放进度条 -->
    <div class="progress">
      <img :src="icon_url" @click="IconClick" class="icon" />
      <div class="progress-line">
        <div class="line-content" :style="{ width: progress }"></div>
        <div class="round" :style="{ left: progress }"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        play_status: false,
        time_tool: null,
        progress: '0%',
        perNumber: 0
      }
    },
    methods: {
      IconClick () {
        const musicMp3 = document.getElementById('musicMp3')
        if (!this.play_status) {
          musicMp3.play()
          this.time_tool = setInterval(() => {
            const numbers = musicMp3.currentTime / musicMp3.duration
            let perNumber = (numbers * 100).toFixed(2)
            if (perNumber >= 100) {
              this.play_status = false
              this.progress = 0
              clearInterval(this.time_tool)
            }
            this.perNumber = perNumber
            perNumber += '%'
            this.progress = perNumber
          }, 30)
        } else {
          musicMp3.pause()
          clearInterval(this.time_tool)
        }
        this.play_status = !this.play_status
      }
    },
    props: {
      url: null
    },
    computed: {
      icon_url () {
        if (this.perNumber > 0 && this.play_status) {
          return this.$img('IconPause')
        } else if (this.perNumber === 0) {
          return this.$img('IconPlayTwo')
        } else if (this.perNumber >= 100) {
          return this.$img('IconRebroadcast')
        } else if (this.perNumber > 0 && !this.play_status) {
          return this.$img('IconPlayThree')
        } else {
          return this.$img('IconPlayThree')
        }
        // return this.play_status ? '/static/images/icon/pause.png' : '/static/images/icon/play.png'
      }
    }
  }
</script>

<style lang="less" scoped>
.hearing-item {
  .mb(0.6rem);
  .progress {
    display: flex;
    align-items: center;
    .p(0 0.15rem);
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      z-index: 10;
    }
    .progress-line {
      width: 600px;
      height: 8px;
      background: #F0F0F0;
      border-radius: 5px;
      position: relative;
      margin-right: 30px;
      .line-content {
        position: absolute;
        left: 0;
        top: 0;
        height: 8px;
        z-index: 9;
        border-radius: 5px;
        background: #CA0000;
      }
    }
  }
}
</style>

<template>
  <div class="flex-c academy" ref="academy">
    <div class="academy-item" @click="goNav(item)" :style="[cutomStyle]" v-for="(item, index) in list" :key="index" :class="{animation: animation && isanimation}">
      <img :src="item.cover_url" />
      <div class="academy-main">
        <div class="academy-title">{{ item.title }}</div>
        <div class="academy-content">{{ item.about }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'academy',
    props: {
      cutomStyle: [Object],
      animation: [Boolean],
      scrollToTop: {
        type: Function,
        default: () => {}
      },
      list: [Array]
    },
    watch: {
      animation (to) {
        if (to) {
          setTimeout(() => {
            this.isanimation = false
          }, 2100)
        }
      }
    },
    data () {
      return {
        isanimation: true
      }
    },
    methods: {
      goNav (item) {
        if (item.is_link && Number(item.is_link) === 1 && item.link_url) {
          window.location.href = item.link_url
          return
        }
        this.$nav('Common.IntroduceDetail', { id: item.id })
      }
    },
    mounted () {
      window.addEventListener('scroll', () => { this.scrollToTop(this, 'academy', 'academyAnimation') }, true)
      if (this.animation) {
        setTimeout(() => {
          this.isanimation = false
        }, 2100)
      }
    }
  }
</script>

<style lang="less" scoped>
  .academy {
    width: 1200px;
    .flex-wrap();
    .academy-item {
      width: 270px;
      height: 302px;
      background: #fff;
      border-radius: 10px;
      margin-right: 40px;
      overflow: hidden;
      transition: all .2s;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 30px;
      }
      .academy-main {
        padding: 16px 16px 25px;
        .academy-title {
          height: 26px;
          font-size: 20px;
          line-height: 25px;
          color: #333333;
          margin-bottom: 8px;
          text-align: center;
          .line-clamp-multi(1);
        }
        .academy-content {
          font-size: 14px;
          line-height: 24px;
          color: #B2B2B2;
          text-align: center;
          .line-clamp-multi(2);
          span {
            color: #888;
          }
        }
      }
      img {
        width: 270px;
        height: 203px;
      }
      &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
      }
    }
  }
</style>

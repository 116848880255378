<template>
  <div class="teacher-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide teacher-item" v-for="(item, index) in list" :key="index" @click="goNav(item)" :class="{'teacher-animation': animation && isanimation}">
        <img :src="getImageBg(index)" />
        <div class="teacher-main">
          <div class="teacher-content">{{ item.about }}</div>
          <div class="teacher-star flex-c">
            <img v-for="i in item.score * 1" :src="$img('IconStar')" :key="i" />
          </div>
          <img :src="$img('Masking')" class="masking">
        </div>
        <div class="cover-warp">
          
          <img style="width: 200px; height: 274px;" :src="item[coverKey]+'?x-oss-process=image/resize,w_200,h_274,m_lfit'" v-if="coverKey&&item[coverKey]">
          <img style="width: 200px; height: 274px;" :src="item.cover_url+'?x-oss-process=image/resize,w_200,h_274,m_lfit'" v-else>
          <!-- <el-image style="width: 200px; height: 274px;" :src="item.cover_url+'?x-oss-process=image/resize,w_200,h_274,m_lfit'" fit="cover"></el-image> -->
        </div>
        <div class="teacher-name">{{ item.title }}</div>
        <div class="teacher-identity">老师</div>
      </div>
    </div>
    <div id="teacher-button-prev" class="teacher-button-prev teacher-button-box" slot="button-prev">
      <div class="teacher-button reverse"></div>
    </div>
    <div id="teacher-button-next" class="teacher-button-next teacher-button-box" slot="button-next">
      <div class="teacher-button"></div>
    </div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  export default {
    name: 'teacher-swiper',
    props: {
      coverKey:{
        default:''
      },
      list: [Array],
      animation: [Boolean],
      scrollToTop: {
        type: Function,
        default: () => {}
      }
    },
    watch: {
      list () {
        this.$nextTick(() => {
          this.teacherSwiper = new Swiper('.teacher-container', this.swiperOptions)
        })
      },
      animation (to) {
        if (to) {
          setTimeout(() => {
            this.isanimation = false
          }, 2100)
        }
      }
    },
    mounted () {
      window.addEventListener('scroll', () => { this.scrollToTop(this, 'teacherSwiper', 'teacherSwiperAnimation') }, true)
      if (this.animation) {
        setTimeout(() => {
          this.isanimation = false
        }, 2100)
      }
    },
    data () {
      return {
        isanimation: true,
        swiper: null,
        activeIndex: 0,
        teacherSwiper: null,
        swiperOptions: {
          loop: true,
          speed: 1500,
          // 如果需要前进后退按钮
          nextButton: '#teacher-button-next',
          prevButton: '#teacher-button-prev',
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          effect: 'silde',
          // 如果需要自动切换海报
          autoplay: 3000
        }
      }
    },
    methods: {
      getImageBg (i) {
        let text = ''
        i = i % 5
        switch (i) {
        case 0:
          text = '/static/images/entrepot/figurebg1.png'
          break
        case 1:
          text = '/static/images/entrepot/figurebg2.png'
          break
        case 2:
          text = '/static/images/entrepot/figurebg3.png'
          break
        case 3:
          text = '/static/images/entrepot/figurebg4.png'
          break
        case 4:
          text = '/static/images/entrepot/figurebg5.png'
          break
        default:
          text = '/static/images/entrepot/figurebg3.png'
          break
        }
        return text
      },
      goNav (item) {
        this.$nav('Common.IntroduceDetail', { id: item.id })
      }
    },
    computed: {
      computList () {
        let arr = []
        let list = this.list || []
        let ceil = Math.ceil(list.length / 3)
        for (let i = 0; i <= ceil; i++) {
          if (list[i * 3]) {
            arr.push(list.slice(i * 3, i * 3 + 3))
          }
        }
        return arr
      }
    }
  }
</script>

<style lang="less" scoped>
.teacher-container {
  overflow: hidden;
  width: 1300px;
  padding: 0 50px;
  box-sizing: border-box;
  position: relative;
  .flex-cc();
}
/deep/ .teacher-pagination {
    .flex-cc();
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    z-index: 999;
    .swiper-pagination-bullet {
      width: 38px;
      height: 6px;
      background: #f0f0f0;
      margin: 0 4px;
      opacity: 0.5;
      border-radius: 0;
      cursor: pointer;
      &.swiper-pagination-bullet-active {
        background-color: #CA0000;
        opacity: 1;
      }
    }
  }
.swiper-slide {
  // padding: 0 50px;
  // overflow: hidden;
  box-sizing: border-box;
}
/deep/ .swiper-wrapper {
  width: 1100px;
  margin: 82px auto 0;
}
.teacher-button {
  position: absolute;
  top: 50%;
  width: 48px;
  height: 90px;
  border-radius: 24px;
  color: #888;
  cursor: pointer;
  font-size: 24px;
  .flex-cc();
  background: url('/static/images/icon/btn1.png');
  span {
    transform: scale(1, 2);
  }
  &:hover {
    color: #fff;
    background: #CA0000;
    background: url('/static/images/icon/btn2.png');
  }
}
.teacher-button-next {
  position: absolute;
  background-color: #fff;
  width: 98px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 99;
  .teacher-button {
    right: 0;
  }
}
.teacher-button-prev {
  position: absolute;
  background-color: #fff;
  width: 98px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.teacher {
  padding-top: 83px;
  width: 1100px;
  overflow: hidden;
}
.teacher-item {
    width: 340px !important;
    height: 345px;
    background: #fff;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 3;
    margin: 0 40px 20px 0;
    transition: all 2s;
    // transform: translateX(20px);
    // margin-right: 40px;
    // &:nth-child(3n) {
    //   margin-right: 0;
    // }
    &::before {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      width: 304px;
      height: 346px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      opacity: 1;
      border-radius: 6px;
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 322px;
      height: 346px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      opacity: 1;
      z-index: -1;
      border-radius: 6px;
    }
    .teacher-main {
      padding: 20px 18px 18px;
      position: relative;
      z-index: 1;
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      border-radius: 0 0 6px 6px;
      overflow: hidden;
      border-bottom: 5px solid transparent;
      box-sizing: border-box;
      height: 150px;
      &:hover {
        border-bottom-color: #CA0000;
      }
      .teacher-title {
        font-size: 20px;
        line-height: 25px;
        color: #333333;
        margin-bottom: 12px;
        .line-clamp-multi(2);
      }
      .teacher-content {
        font-size: 14px;
        line-height: 24px;
        color: #B2B2B2;
        .line-clamp-multi(2);
        margin-bottom: 42px;
        min-height: 48px;
        span {
          color: #888;
        }
      }
    }
    .teacher-star {
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    .teacher-img {
      position: absolute;
      top: -83px;
      left: 7px;
      width: 200px;
      height: 274px;
    }

    .cover-warp {
      position: absolute;
      top: -83px;
      left: 7px;
      width: 200px;
      height: 274px;
    }

    .teacher-name {
      position: absolute;
      width: 130px;
      right: 0;
      top: 67px;
      font-size: 24px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
      line-height: 26px;
      .line-clamp();
    }
    .teacher-identity {
      position: absolute;
      width: 120px;
      top: 104px;
      right: 6px;
      font-size: 24px;
      text-align: center;
      line-height: 22px;
      color: #FFFFFF;
      line-height: 26px;
      .line-clamp();
    }
    img {
      width: 340px;
      height: 190px;
    }
    .masking {
      width: 340px;
      height: 345px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      z-index: -1;
    }
  }
@keyframes movefromTop1 {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes movefromTop2 {
  from {
    opacity: 0;
    bottom: 20%;
  }
  to {
    opacity: 1;
    bottom: -7px;
  }
}
@keyframes movefromTop3 {
  from {
    opacity: 0;
    bottom: 20%;
  }
  to {
    opacity: 1;
    bottom: -16px;
  }
}
</style>

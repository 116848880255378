<template>
  <div class="introduce">
    <div class="introduce-box">
      <div class="introduce-title">{{info.title}}</div>
      <div v-html="info.content" class="introduce-text" v-if="info.content"></div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        info: {}
      }
    },
    activated () {
      this.getArticleDetails()
    },
    methods: {
      async getArticleDetails () {
        let { data, status } = await this.$Get('Common.getArticleDetailsV2', {
          id: this.$route.query.id
        })
        if (status) {
          this.info = data
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .introduce{
    background: #f9f9f9;
    padding: 1px 0;
    .introduce-box {
      width: 1200px;
      margin: 40px auto 98px;
      background-color: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 40px 206px 81px 210px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
      .introduce-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 31px;
        color: #333333;
        margin-bottom: 26px;
        text-align: center;
      }
      .introduce-text {
        font-size: 16px;
        line-height: 26px;
        color: #888888;
        margin-bottom: 30px;
      }
      img {
        max-width: 100%;
      }
    }
  }
</style>

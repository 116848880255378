<template>
    <div class="campusNews-cntainer">
        <div class="btn-warp">
            <div class="btn" :class="{'active':active===1}" @click="active=1">校企合作</div>
            <div class="btn" :class="{'active':active===2}" @click="active=2">校园资讯</div>
        </div>
        <div class="flex-c campusNews" v-if="list&&list.length>0">
            <div class="campusNews-item" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
                <!-- <img :src="item.cover_url" /> -->
                <el-image style="width: 270px; height:160px" :src="item.cover_url" fit="cover"></el-image>
                <div class="campusNews-main">
                    <div class="campusNews-title">
                        {{item.title||""}}
                    </div>
                    <div class="campusNews-content">
                        <span class="bold">资讯介绍：</span>{{item.about||''}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CampusNews',
        data() {
            return {
                active: 1,
                list: []
                // isanimation: true
            }
        },
        props: {
            animation: [Boolean],
            scrollToTop: {
                type: Function,
                default: () => {}
            }
        },
        methods: {
            goDetail(item) {
                // 校园资讯
                this.$nav('Common.IntroduceDetail', {
                    id: item.id
                })
            },
            // school_enterprise：校企合作，school_news：校园资讯
            async GetList() {

                let type2 = {
                    '1': 'school_enterprise',
                    '2': 'school_news'
                }

                let {
                    data,
                    status
                } = await this.$Get('Common.getArticleListV2', {
                    page: 1,
                    limit: 4,
                    type: type2[this.active],
                    // is_hot: 1
                })
                if (status) {
                    this.list = data.items || []
                }
            },


        },
        watch: {
            active(n) {
                this.$emit('input', n)
                this.GetList()
            }
            // animation (to) {
            //   if (to) {
            //     setTimeout(() => {
            //       this.isanimation = false
            //     }, 2100)
            //   }
            // }
        },
        mounted() {

            this.GetList()

            // window.addEventListener('scroll', () => { this.scrollToTop(this, 'campusNews', 'campusNewsAnimation') }, true)
            // if (this.animation) {
            //   setTimeout(() => {
            //     this.isanimation = false
            //   }, 2100)
            // }
        }
    }
</script>

<style lang="less" scoped>
    .campusNews-cntainer {
        .btn-warp {
            margin-bottom: 60px;
            text-align: center;

            .btn {
                vertical-align: middle;
                display: inline-block;
                margin: 0 20px;
                width: 204px;
                height: 52px;
                line-height: 52px;
                background-color: #f6f6f6;
                border-radius: 26px;
                text-align: center;
                color: #333333;
                font-size: 20px;
                cursor: pointer;

                &.active {
                    background: #CA0000;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }

        .campusNews {
            flex-wrap: wrap;
            margin-bottom: 50px;
            width: 1200px;

            .campusNews-item {
                width: 270px;
                height: 330px;
                background: #ffffff;
                border-radius: 10px;
                margin-right: 40px;
                overflow: hidden;
                position: relative;
                transition: all .2s;
                box-shadow: 0px 2px 24px rgba(202, 0, 0, 0.06);
                cursor: pointer;

                &:nth-child(4n) {
                    margin-right: 0;
                }

                &:nth-child(n+5) {
                    margin-top: 48px;
                }

                .campusNews-main {
                    padding: 20px 18px 18px;

                    .campusNews-title {
                        font-size: 20px;
                        line-height: 25px;
                        color: #333333;
                        margin-bottom: 12px;
                        .line-clamp-multi(2);
                    }

                    .campusNews-content {
                        font-size: 14px;
                        line-height: 24px;
                        color: #B2B2B2;
                        .line-clamp-multi(3);
                        margin-bottom: 42px;

                        .bold {
                            font-weight: bold;
                        }

                        span {
                            color: #888;
                        }
                    }
                }

                .look {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 19px;
                    color: #CA0000;
                    font-size: 14px;

                    img {
                        width: 14px;
                        height: 14px;
                        margin-left: 5px;
                    }
                }

                img {
                    width: 270px;
                    height: 160px;
                }

                &:hover {
                    transform: translateY(-20px);
                    background: #fff;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
                }
            }
        }
    }
</style>

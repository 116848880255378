<template>
  <div class="page">
    <div class="title" v-if="name">{{ name }}</div>
    <div v-html="content"></div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        content: '',
        name: ''
      }
    },
    methods: {
      async getAgreement () {
        let { status, data } = await this.$Get('Common.getAgreement', {
          type: 'after_sale'
        })
        if (status) {
          this.content = data.content
          this.name = data.name
        }
      }
    },
    activated () {
      // this.$Bus.$emit('hideOfflinePay')
      this.getAgreement()
    }
  }
</script>

<style lang="less" scoped>
  .page {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    margin: 42px auto 90px;
    .title {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
</style>
